import {useState} from "react";
import TextBox from "components/shared/text-box/text-box";
import {useSpecs} from "api";
import {ruleTypesToComponents} from "../rule-components/rule-types-to-components";
import "./value-rules-table.css";

const UnknownType = ({title, ruleValue, ruleName}) => (
   <tr key={ruleName}>
      <th>{title}</th>
      <td>{ruleValue}</td>
   </tr>
)

const SimpleType = ({rules, list, updateValue, updateList, showExclusions, exclusions, setExclusions, paths, options, default: defaultValue}) => {
   const rulePaths = getPaths(rules);
   const [localExclusions, setLocalExclusions] = useState(exclusions ?? '');
   const [length, setLength] = useState(list.length || 1);
   const [isSet, setIsSet] = useState(list.isSet ?? false);
   const {data: views} = useSpecs('views') ?? {data: []};
   const {data: generators} = useSpecs('generators') ?? {data: []};

   const handleToggleIsSet = () => {
      setIsSet(!isSet);
      updateList({isSet: !isSet});
   };

   const handleUpdateLength = () => {
      updateList({length});
   };

   const handleSetExclusions = () => {
      setExclusions(localExclusions);
   };

   return (
      <>
         {Object.entries(paths).map(([ruleName, ruleType]) => {
            const ruleNameBits = ruleName.split('.');
            const ruleTitle = ruleNameBits.length > 1
               && ['expression', 'text'].includes(ruleNameBits[ruleNameBits.length - 1])
               ? ruleNameBits.slice(0, -1).join('.')
               : ruleName;
            const RuleComponent = ruleTypesToComponents[ruleType] ?? UnknownType;

            return <RuleComponent
               key={ruleName}
               title={ruleTitle}
               ruleName={ruleName}
               ruleValue={rulePaths[ruleName] ?? ''}
               views={views}
               generators={generators}
               updateValue={updateValue}
               defaultValue={defaultValue}
               options={options}
            />
         })}
         {list && list?.length !== undefined && (
            <>
               <tr key="_list_length">
                  <th>List Length</th>
                  <td>
                     <TextBox
                        value={length}
                        onChange={(e) => setLength(e.target.value)}
                        onBlur={handleUpdateLength}
                     />
                  </td>
               </tr>
               <tr key="_list_is_set">
                  <th>Unique Values</th>
                  <td>
                     <input
                        type="checkbox"
                        checked={isSet}
                        onChange={handleToggleIsSet}
                     />
                  </td>
               </tr>
            </>
         )}
         {showExclusions && (
            <tr key="_exclusions">
               <th>Exclusions</th>
               <td>
                  <TextBox
                     value={localExclusions}
                     onChange={(e) => setLocalExclusions(e.target.value)}
                     onBlur={handleSetExclusions}
                  />
               </td>
            </tr>
         )}
      </>
   );
};

function getPaths(obj = {}, path = '') {
   if (Array.isArray(obj)) return { [path]: obj };
   return Object.keys(obj).reduce((paths, key) => {
      return typeof obj[key] === 'object'
         ? Object.assign(paths, getPaths(obj[key], `${path ? `${path}.` : ''}${key}`))
         : {...paths, [`${path ? `${path}.` : ''}${key}`]: obj[key]};
   }, path ? { [path]: obj } : {});
}

function getPathsForValueType(valueType, valueTypes, valueFunctions, path = '') {
   if (valueType?.functionId) {
      const valueRulesSpec = valueFunctions.find((f) => f._id === valueType.functionId)?.ruleTypes ?? {};
      return Object.fromEntries(
         Object.entries(valueRulesSpec).map(([key, expressionType]) => {
            return [`${path ? `${path}.` : ''}${key}`, expressionType];
         })
      );
   }

   return Object.entries(valueType?.fields ?? {}).reduce((paths, [key, nestedValueTypeId]) => {
      return Object.assign(
         paths,
         getPathsForValueType(
            valueTypes.find((vt) => vt._id === nestedValueTypeId),
            valueTypes,
            valueFunctions,
            `${path ? `${path}.` : ''}${key}`
         )
      );
   }, {});
}

const ValueRulesTable = ({
   valueType,
   rules,
   list,
   exclusions,
   showExclusions,
   level,
   valueTypes,
   updateValue,
   updateList,
   setExclusions,
}) => {
   const {data: valueFunctions} = useSpecs('value-functions') ?? {data: {}};
   if (!valueType || !valueFunctions.length) return null;
   const paths = getPathsForValueType(valueType, valueTypes, valueFunctions);

   return Object.keys(paths).length ? (
      <table className="rules-table">
         <tbody>
         <SimpleType
            paths={paths}
            default={valueType.default}
            options={valueType.options}
            updateValue={(propName, propValue) => updateValue(propName, propValue, level)}
            updateList={updateList}
            rules={rules[level]}
            list={list}
            exclusions={exclusions}
            showExclusions={showExclusions}
            setExclusions={setExclusions}
            key={`${level}_${rules.length}`}
         />
         </tbody>
      </table>
   ) : (
      <p>This value type has no rules to set.</p>
   );
};

export default ValueRulesTable;
