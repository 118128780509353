import { useState, useRef } from 'react';
import { EditableMathField } from 'react-mathquill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import TextBox from 'components/shared/text-box/text-box';
import { AddButton } from 'components/shared/buttons/buttons';

const RuleLatexWithPlaceholders = ({ title, ruleName, ruleValue, updateValue }) => {
  const [latex, setLatex] = useState(ruleValue);
  const [placeholder, setPlaceholder] = useState('');

  const mathField = useRef();

  const handleBlur = () => {
    updateValue(ruleName, latex);
  };

  const handleAddPlaceholder = () => {
    if (mathField.current) {
      mathField.current.write(`\\var{}{${placeholder}}`);
      updateValue(ruleName, mathField.current.latex());
      mathField.current.focus()
    }
  };

  const mathquillDidMount = (mf) => {
    mathField.current = mf;
  };

  return (
    <tr>
      <th>{title}</th>
      <td>
        <div className='latex-rule-mathquill'>
          <EditableMathField
            latex={latex}
            onChange={(mathField) => setLatex(mathField.latex())}
            onBlur={handleBlur}
            mathquillDidMount={mathquillDidMount}
          />
        </div>
        <div className="latex-rule-placeholder">
          <TextBox
            name="Placeholder"
            value={placeholder}
            onChange={(e) => setPlaceholder(e.target.value)}
          />
          <AddButton onClick={handleAddPlaceholder}>
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;Placeholder
          </AddButton>
        </div>
      </td>
    </tr>
  );
};

export default RuleLatexWithPlaceholders;
