import Merge from "./exchange/merge";
import MultiSelect from "./multi-select/multi-select";
import Play from "./play/play";
import Remove from "./remove/remove";
import SelectAndAdd from "./select-and-add/select-and-add";
import Slider from "./slider/slider";
import Split from "./exchange/split";
import TriggerButton from "./trigger-button/trigger-button";

const controls = {
   Merge,
   MultiSelect,
   Play,
   Remove,
   SelectAndAdd,
   Slider,
   Split,
   TriggerButton,
};

export default controls;
