export const mainSpecs = [
  'question-types'
];

export const otherSpecs = [
  'controls',
  'generators',
  'manipulatives',
  'question-sets',
  'type-renderers',
  'value-functions',
  'value-types',
  'views',
];

export const typeIds = {
  FOLDER_TYPE_ID: '651af9bcb87fa681ffb2b34e',
  DEFAULT_VALUE_TYPE_ID: '64bfdc0f08478d98d72db4c2',
  QUESTION_STEPS_TYPE_ID: '64c0034c53dbf906ec5792a4',
  TEXT_EXPRESSION_TYPE_ID: '64bfdf1208478d98d72db4c4',
  EMPTY_LIST_TYPE_ID: '66224394b51a2654effe9efb',
  VALUE_TYPE_ID: 'anyValuePassThrough',
  TOKEN_ARRAY_TYPE_ID: '662283774b19c140e13f68f3',
  EQUAL_JSON_TYPE_ID: '64c91a98f5ed95295f400601',
};

export const coreValueFunctions = [
  {
    "_id": "66224348b51a2654effe9efa",
    "functionCodeKey": "emptyList",
    "ruleTypes": {},
    "name": "emptyList",
  },
  {
    "_id": "651e807e55a79c281c9634d6",
    "functionCodeKey": "--No Code Key--",
    "ruleTypes": {},
    "name": "Folder",
  },
  {
    "_id": "64bfde96da92b8f5262334a2",
    "functionCodeKey": "textExpression",
    "ruleTypes": {
      "text": "textWithPlaceholders"
    },
    "name": "Text Expression",
  },
  {
    "_id": "6622819685d5a82f34645dea",
    "functionCodeKey": "textExpression",
    "ruleTypes": {
      "text": "tokenArray"
    },
    "name": "tokenExpression",
  },
  {
    "_id": "anyValuePassThrough",
    "functionCodeKey": "anyValue",
    "ruleTypes": {
      "value": "anyValue"
    },
    "name": "anyValue",
  },
  {
    "_id": "64c91a5fc2f249a929b294f2",
    "functionCodeKey": "jsonIsEqual",
    "ruleTypes": {
      "list": "expressionList"
    },
    "name": "jsonIsEqual",
  },
];

export const coreValueTypes = [
  {
    "_id": "66224394b51a2654effe9efb",
    "functionId": "66224348b51a2654effe9efa",
    "fields": {},
    "name": "Empty List",
  },
  {
    "_id": "651af9bcb87fa681ffb2b34e",
    "functionId": "651e807e55a79c281c9634d6",
    "fields": {},
    "name": "Folder",
  },
  {
    "_id": "64bfdf1208478d98d72db4c4",
    "functionId": "64bfde96da92b8f5262334a2",
    "fields": {},
    "name": "Text Expression",
  },
  {
    "_id": "662283774b19c140e13f68f3",
    "functionId": "6622819685d5a82f34645dea",
    "fields": {},
    "name": "Token List",
  },
  {
    "_id": "anyValuePassThrough",
    "functionId": "anyValuePassThrough",
    "fields": {},
    "name": "Any Value",
  },
  {
    "_id": "64c91a98f5ed95295f400601",
    "functionId": "64c91a5fc2f249a929b294f2",
    "fields": {},
    "name": "Equal Objects",
  }
];
