import "./select.css";

const Select = ({ className = '', children, ...restProps }) => {
   const classes = ['manipulatives-select'].concat(className.split(' '));
   return (
      <select className={classes.join(' ')} {...restProps}>
         {children}
      </select>
   );
};

export default Select;
