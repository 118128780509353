import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faFolderClosed, faChartSimple } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'components/shared/buttons/buttons';
import {NavLink} from "react-router-dom";

const SortableFolderItem = (props) => {
   const { value, className, isLocked, to } = props;
   const { handleClickFolder, handleAddNestedValue, handleAddNestedFolder } = props;
   const { id, depth, name, isOpen, isSelected } = value;
   const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      isDragging,
      over,
      active,
   } = useSortable({
      id,
      data: value,
      disabled: isLocked,
   });

   const style = {
      transform: CSS.Transform.toString({
         x: 0,
         y: transform?.y,
         scaleX: 1,
         scaleY: 1
      }),
   };
   const depthStyle = { '--folder-depth': depth };

   if (active && over && isDragging) {
      depthStyle['--folder-depth'] = active.newDepth ?? active.data.current.depth;
   }

   const classNames = ['value-item', 'sortable-nav-item', 'sortable-folder-item'];
   if (className && typeof className === 'string') classNames.push(className);
   if (isSelected) classNames.push('selected');

   return (
     <li
       ref={setNodeRef}
       style={style}
       className={classNames.join(' ')}
     >
        <div
          className="sortable-nav-item-wrapper sortable-nav-folder-wrapper"
          style={depthStyle}
        >
          <span>
            <Button title="Drag to Move Folder" {...attributes} {...listeners}>
               <FontAwesomeIcon icon={isOpen ? faFolderOpen : faFolderClosed}/>
            </Button>
            <NavLink to={to} onClick={() => handleClickFolder(id)}>{name}</NavLink>
          </span>
           <span className="group-controls">
               <Button
                 onClick={(e) => handleAddNestedValue(value, e)}
                 title="New Value"
                 disabled={isLocked}
               >
                  <FontAwesomeIcon icon={faChartSimple}/>
               </Button>
               <Button
                 onClick={(e) => handleAddNestedFolder(value, e)}
                 title="New Folder"
                 disabled={isLocked}
               >
                  <FontAwesomeIcon icon={faFolderClosed}/>
               </Button>
             </span>
        </div>
     </li>
   );
};

export default SortableFolderItem;
