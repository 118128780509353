import TokenViewer from 'components/views/outputs/token-viewer';

const TextItem = ({ item, specs }) => {
  const className = item?.isKeyItem ? 'keyItem' : '';

  return (
    <TokenViewer tokens={item.text} renderers={specs} className={className} />
  );
};

export default TextItem;
