import {cloneElement} from "react";
import {getObjectProperty} from "@lasalle/rich-content-utils/objects/object-properties";
import {useSnapshotInfo} from "../snapshots/snapshots";

const ShareStateProps = ({ expectedProps = [], children, ...props }) => {
    const {snapshot: state} = useSnapshotInfo();
    const transformedProps = Object.fromEntries(expectedProps.map((prop) => {
        const [expectedKey, currentKey] = Object.entries(prop)[0];
        const value = getObjectProperty(state, currentKey);
        return [expectedKey, value];
    }));
    return cloneElement(children, {...state, ...props, ...transformedProps });
};

export default ShareStateProps;
