import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import icons from "app-constants/icons";

const AssignmentsHeader = ({ hasCreateLink }) => {
  const {goalId} = useParams();
  return (
    <header className="spec-header">
      <div className="icon-title">
        <FontAwesomeIcon icon={icons.assignments}/>
        <div>
          <h2>Assignments</h2>
          <h3>for user</h3>
        </div>
      </div>
      {hasCreateLink && goalId && (
        <Link to={`/assignments/${goalId}/new`}>
          <FontAwesomeIcon icon={icons['new-spec']} />{' '}
          Create Question Type
        </Link>
      )}
    </header>
  );
};

export default AssignmentsHeader;
