import {Suspense} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {ErrorBoundary} from "react-error-boundary";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import { useSpec } from 'api';
import { useUpdateQuestionType } from 'api/question-types';
import {useUser} from "store";
import {Button} from "components/shared/buttons/buttons";
import Nav from "components/nav/nav";
import LogoBox from "components/shared/logo-box/logo-box";
import EditorMenu from "components/icon-menus/editor-menu";
import TutorFreePracticePreview from "components/tutor-free-practice-preview/tutor-free-practice-preview";
import GeneratorPicker from './generator-picker/generator-picker';
import StepsEditor from './steps-editor';
import ItemEditor from './step-editors/item-editor';
import StepEditor from "./step-editor";
import GeneratorDetails from "./generator-details";
import ValuesList from "../generators/values-list/values-list";
import ValueDetails from "../generators/value-details/value-details";
import SpecHeader from "../../spec-header/spec-header";
import "./question-type-editor.css";

const STEP_TYPES = ['intro', 'question', 'solution'];
const ACTIVE_STEPS = ['question'];
const STEP_SECTIONS = STEP_TYPES.filter((s) => ACTIVE_STEPS.includes(s));

const QuestionTypeSkeleton = () => {
   return (
      <>
         <EditorMenu />
         <Nav className="multi-tree">
            <LogoBox />
            <div className="multi-tree-section">
               <h3 className="values-list-controls">
                  <span>Generator</span>
               </h3>
               <p>Loading Generator Data...</p>
            </div>
            <div className="multi-tree-section">
               <div>
                  <h3 className="values-list-controls">
                     <span>Question Steps</span>
                  </h3>
                  <div>
                     <p>Loading Steps Data...</p>
                  </div>
               </div>
            </div>
         </Nav>
         <main>
            <SpecHeader hasCreateLink />
            <div>
               <div className="details-panel"></div>
               <div className="preview-panel"></div>
            </div>
         </main>
      </>
   );
};

const QuestionTypeEditorInner = () => {
   const {specId, specType, valueId, mode, stepIndex, itemIndex} = useParams();
   const questionType = useSpec('question-types', specId) ?? {};
   const generator = useSpec('generators', questionType?.generatorId);
   const {setGeneratorId, updateStep, removeStep} = useUpdateQuestionType(specId);

   const selectedStepIndex = parseInt(stepIndex, 10) - 1;
   const selectedItemIndex = parseInt(itemIndex, 10) - 1;
   const selectedStep = questionType[`${mode}Steps`]?.[selectedStepIndex];
   const selectedItem = selectedStep?.items[selectedItemIndex];

   const {isAdmin, user} = useUser();
   const isDraft = questionType._status === 'draft';
   const isOwner = questionType._createdBy.userId === user._id;
   const canEdit = isDraft && (isOwner || isAdmin);

   const navigate = useNavigate();

   const handleShowGenerator = () => {
      navigate(`/specs/${specType}/${specId}/generator`, {replace: true});
   };

   const handleUpdateItem = (itemPatch) => {
      const items = selectedStep.items ?? [];
      items[selectedItemIndex] = { ...selectedItem, ...itemPatch };
      updateStep(mode, { ...selectedStep, items });
   };

   const handleDeleteItem = () => {
      if (window.confirm('Are you sure you want to delete the item?')) {
         const items = (selectedStep.items ?? []).toSpliced(selectedItemIndex, 1);
         updateStep(mode, { ...selectedStep, items });
         navigate(`/specs/${specType}/${specId}`, {replace: true});
      }
   };

   const handleDeleteStep = () => {
      if (window.confirm('Are you sure you want to delete the step and all its items?')) {
         removeStep(mode, selectedStep.id);
         navigate(`/specs/${specType}/${specId}`, {replace: true});
      }
   };

   const setViewId = (viewId) => {
      handleUpdateItem({ viewId });
   };

   const setViewPropValue = (viewPropId, viewPropValue) => {
      const viewPropValues = selectedItem.viewPropValues ?? {};
      viewPropValues[viewPropId] = viewPropValue;
      handleUpdateItem({ viewPropValues });
   };

   const setText = (text) => {
      handleUpdateItem({ text });
   };

   const setIsKeyItem = (isKeyItem) => {
      handleUpdateItem({ isKeyItem });
   };

   return (
      <>
         <EditorMenu />
         <Nav className="multi-tree">
            <LogoBox />
            <div className="multi-tree-section">
            {!questionType.hasLinkedGenerator && isAdmin && (
                 <h3 className="values-list-controls">
                    <span>Generator</span>
                    {!selectedStep && (
                      <Button onClick={handleShowGenerator}>
                         <FontAwesomeIcon icon={faEdit}/>
                      </Button>
                    )}
                 </h3>
               )}
               {generator._id && (
                 <ValuesList
                   generatorId={generator._id}
                 />
               )}
            </div>

            {generator && STEP_SECTIONS.map((stepType) => (
               <div className="multi-tree-section" key={stepType}>
                  <StepsEditor
                     stepType={stepType}
                     steps={questionType[`${stepType}Steps`]}
                     questionTypeId={specId}
                     isLocked={!canEdit}
                  />
               </div>
            ))}
         </Nav>

         <main className="details-and-preview">
            <SpecHeader hasCreateLink />
            <div>
               <div className="details-panel">
                  {valueId && (
                    <Suspense fallback={null}>
                       <ValueDetails
                         generatorId={generator._id}
                         valueId={valueId}
                         key={valueId}
                       />
                    </Suspense>
                  )}
                  {mode === 'generator' && (
                    <>
                       <h3>{generator ? 'Chosen Generator' : 'Choose a Generator'}</h3>
                       {generator ? (
                         <GeneratorDetails generator={generator} isLocked={!canEdit}/>
                       ) : (
                         <>
                            <p><em>Question Types get their Values from a Generator,
                               but no Generator has been set.</em></p>
                            <p>Please choose a Generator below.</p>
                         </>
                       )}

                       {canEdit && (
                         <GeneratorPicker
                           spec={questionType}
                           handleSetGeneratorId={setGeneratorId}
                         />
                       )}
                    </>
                  )}
                  {selectedStep !== undefined && selectedItem === undefined && (
                    <StepEditor
                      stepIndex={stepIndex}
                      deleteStep={handleDeleteStep}
                      isLocked={!canEdit}
                    />
                  )}
                  {selectedStep !== undefined && selectedItem !== undefined && (
                    <ItemEditor
                      item={selectedItem}
                      questionType={questionType}
                      specId={specId}
                      stepIndex={selectedStepIndex}
                      itemIndex={selectedItemIndex}
                      isLocked={!canEdit}
                      setViewId={setViewId}
                      setViewPropValue={setViewPropValue}
                      setText={setText}
                      setIsKeyItem={setIsKeyItem}
                      updateItem={handleUpdateItem}
                      deleteItem={handleDeleteItem}
                    />
                  )}
               </div>
               <div className="preview-panel">
                  {questionType._id && questionType.questionSteps.length > 0 && (
                    <ErrorBoundary fallback={<p>There was a problem generating the preview</p>}>
                       <TutorFreePracticePreview
                         questionType={questionType}
                         key={JSON.stringify({generator, questionType})}
                       />
                    </ErrorBoundary>
                  )}
               </div>
            </div>
         </main>
      </>
   );
};

const QuestionTypeEditor = () => {
   return (
     <Suspense fallback={<QuestionTypeSkeleton/>}>
        <QuestionTypeEditorInner/>
     </Suspense>
   );
};

export default QuestionTypeEditor;
