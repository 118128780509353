import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faListOl, faSitemap, faCircleDot, faCircleExclamation} from '@fortawesome/free-solid-svg-icons';
import ShareStateProps from 'components/share-state/share-state-props';
import {useSnapshotInfo, useSnapshotState} from 'components/snapshots/snapshots';
import "./generated-values.css";
import {ToggleBoxContents} from "../../../../../components/shared/toggle-box/toggle-box";
import {setObjectProperty} from "@lasalle/rich-content-utils/objects/object-properties";
import {useSearchParams} from "react-router-dom";

const ToggleBox = ({ title, className, children }) => {
   const [isOpen, setIsOpen] = useState(false);
   const classes = ['toggle-box', 'generated-value-compound'];
   if (className) classes.push(...className.split(' '));
   if (!isOpen) classes.push('collapsed');

   return (
      <div className={classes.join(' ')}>
         <h4
           onClick={() => setIsOpen((v) => !v)}
           className="generated-value"
         >
           {title}
         </h4>
         {isOpen && children}
      </div>
   );
};

const GeneratedValuesInner = ({ state, errors = [], path }) => {

    const stateWithErrors = errors.reduce((soFar, error) => {
      if (soFar[error.name.split('.')[0]]) {
        soFar = setObjectProperty(
          soFar,
          error.name,
          { _isError: true, _messages: error.messages }
        );
      }
      return soFar;
    }, state);

    if (!Object.keys(stateWithErrors).length) return null;

    return Object.entries(stateWithErrors).map(([key, value]) => {
      const compoundPath = path ? [path, key].join('.') : key;
      const hasError = errors.some((error) => error.name.startsWith(compoundPath));
      if (value._isError) {
        return (
          <ToggleBox
            key={key}
            title={(
              <>
                <span>
                  <FontAwesomeIcon icon={faCircleExclamation} />&nbsp;{key}
                </span>
                <span>Errors ({value._messages.length})</span>
              </>
            )}
            className="toggle-error"
          >
            <ToggleBoxContents className="toggle-content">
              <ul>
                {value._messages.map((v, i) => (
                  <li key={i}>{v}</li>
                ))}
              </ul>
            </ToggleBoxContents>
          </ToggleBox>
        );
      }
      if (Array.isArray(value)) {
         return (
            <ToggleBox
               key={key}
               title={(
                  <>
                     <span>
                        <FontAwesomeIcon icon={faListOl} />&nbsp;{key}
                     </span>
                     <span>
                        List ({value.length})
                     </span>
                  </>
               )}
            >

               <div className="toggle-content">
                  {value.length ? (
                     <GeneratedValuesInner state={value} errors={errors} path={compoundPath} />
                  ) : (
                     <p>The list is empty.</p>
                  )}
               </div>
            </ToggleBox>
         )
      }
      if (typeof value !== 'object') {
         return (
            <div key={key} className="generated-value">
              <span><FontAwesomeIcon icon={faCircleDot} />&nbsp;{key}</span>
              <span>{JSON.stringify(value)}</span>
            </div>
         );
      }
      return (
         <ToggleBox
            key={key}
            title={(
               <>
                  <span>
                     <FontAwesomeIcon icon={faSitemap} />&nbsp;{key}
                  </span>{' '}
                  <span>
                     Object ({Object.keys(value).length})
                  </span>
               </>
            )}
            className={hasError ? "toggle-error" : ""}
         >
            <div className="toggle-content">
               <GeneratedValuesInner state={value} errors={errors} path={compoundPath} />
            </div>
         </ToggleBox>
      )
   });
};

export const GeneratedValues = () => {
  const [state] = useSnapshotState();
  const {snapshotErrors} = useSnapshotInfo();
  const [searchParams] = useSearchParams();
  const isDev = searchParams.get('dev') !== null;

  const stateToShow = isDev
    ? state
    : Object.fromEntries(Object.entries(state).filter(([key]) => !key.startsWith('_')));

  return state && (
    <GeneratedValuesInner state={stateToShow} errors={snapshotErrors} />
  );
};

const WrappedGeneratedValues = () => (
    <ShareStateProps><GeneratedValues /></ShareStateProps>
);

export default WrappedGeneratedValues;
