import {NavLink, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import icons from "app-constants/icons";
import {useUser} from "../../store";

const SpecMenu = () => {
  const {specType, specId} = useParams();
  const {isAdmin} = useUser();
  return (
    <ul className="icon-menu">
      {isAdmin && (
        <li>
          <NavLink to={`/specs/${specType}`} end title="Specs List">
            <FontAwesomeIcon icon={icons['specs-list']}/>
          </NavLink>
        </li>
      )}
      <li>
        <NavLink to={`/specs/${specType}/${specId}`} end title="Spec Editor">
          <FontAwesomeIcon icon={icons['spec-editor']}/>
        </NavLink>
      </li>
      <li>
        <NavLink to={`/specs/${specType}/${specId}/info`} title="Spec Info">
          <FontAwesomeIcon icon={icons['spec-info']}/>
        </NavLink>
      </li>
    </ul>
  );
};

export default SpecMenu;
