import {Suspense} from "react";
import {Route, Routes} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {useUser} from "store";
import Nav from "components/nav/nav";
import Assignments from "pages/assignments/assignments";
import AssignmentsAdmin from "pages/assignments/admin/assignments-admin";
import SpecList from "pages/specs/spec-list/spec-list";
import SpecInfo from "pages/specs/spec-info/spec-info";
import SpecNew from "pages/specs/spec-new/spec-new";
import SpecEditor from "pages/specs/spec-editor";
import Home from "pages/home/home";
import HomeAdmin from "pages/home/home-admin";

const client = new QueryClient({
   defaultOptions: {
      queries: {
         suspense: true
      }
   }
});

const LayoutSkeleton = () => (
  <>
    <Nav></Nav>
    <main></main>
  </>
);

const UserAwareRoutes = () => {
  const {isAdmin} = useUser();
  return (
    <Routes>
      {isAdmin ? (
        <>
          <Route path="/" element={<HomeAdmin />} />
          <Route path="/assignments/admin/*" element={<AssignmentsAdmin />} />
        </>
      ) : (
        <Route path="/" element={<Home />} />
      )}
      <Route path="/assignments/*" element={<Assignments />} />
      <Route path="/specs/:specType" element={<SpecList />} />
      <Route path="/specs/:specType/new" element={<SpecNew />} />
      <Route path="/specs/:specType/:specId" element={<SpecEditor />} />
      <Route path="/specs/:specType/:specId/value/:valueId" element={<SpecEditor />} />
      <Route path="/specs/:specType/:specId/:mode" element={<SpecEditor />} />
      <Route path="/specs/:specType/:specId/:mode/:stepIndex" element={<SpecEditor />} />
      <Route path="/specs/:specType/:specId/:mode/:stepIndex/:itemIndex" element={<SpecEditor />} />
      <Route path="/specs/:specType/:specId/info/*" element={<SpecInfo />} />
    </Routes>
  );
};

const AppRoutes = () => {
   return (
      <QueryClientProvider client={client}>
        <section>
          <Suspense fallback={<LayoutSkeleton />}>
            <UserAwareRoutes />
          </Suspense>
        </section>
        <ReactQueryDevtools/>
      </QueryClientProvider>
   );
};

export default AppRoutes;
