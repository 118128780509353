import {forwardRef} from 'react';
import "./text-box.css";

const variants = ['longer', 'longest'];
const types = ['text', 'search'];

const TextBox = ({ className = '', type, variant, children, ...restProps }, ref) => {
   const classes = ['manipulatives-text-box'].concat(className.split(' '));
   if (variants.includes(variant)) classes.push(`manipulatives-text-box-${variant}`);
   return (
      <input
         type={types.includes(type) ? type : 'text'}
         className={classes.join(' ')}
         ref={ref}
         {...restProps}
      />
   );
};

export default forwardRef(TextBox);

export const getVariant = (length) => {
   if (length < 18) return '';
   if (length < 25) return 'longer';
   return 'longest';
};
