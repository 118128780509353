import {
  faBinoculars, faChartSimple, faCircleInfo, faClipboardUser, faCode, faCodeFork, faCubes, faDisplay,
  faFileCircleQuestion,
  faFileSignature,
  faGears, faHouse, faLaptopCode, faLink, faList, faPenToSquare, faPlus,
  faShapes, faSliders, faTags, faToolbox, faTrafficLight, faUserPen, faUsersGear
} from "@fortawesome/free-solid-svg-icons";

const icons = {
  home: faHouse,
  "specs-list": faList,
  "spec-editor": faPenToSquare,
  "spec-info": faCircleInfo,
  assignments: faClipboardUser,
  "assignments-admin": faUsersGear,
  generators: faGears,
  "question-types": faFileCircleQuestion,
  "question-sets": faFileCircleQuestion,
  overview: faBinoculars,
  "name-and-description": faFileSignature,
  status: faTrafficLight,
  curriculum: faLink,
  tags: faTags,
  "used-by": faToolbox,
  json: faCode,
  "generator-value": faChartSimple,
  default: faFileCircleQuestion,
  editor: faUserPen,
  controls: faSliders,
  manipulatives: faCubes,
  "type-renderers": faLaptopCode,
  "value-functions": faCodeFork,
  "value-types": faShapes,
  views: faDisplay,
  "new-spec": faPlus,
};

export default icons;