import { useState } from "react";
import TextBox, {getVariant} from "components/shared/text-box/text-box";

const RuleTextWithPlaceholders = ({ title, ruleName, ruleValue, updateValue }) => {
    const [text, setText] = useState(ruleValue);

    const handleBlur = () => {
        updateValue(ruleName, text);
    };

    return (
        <tr>
            <th>{title}</th>
            <td>
                <TextBox
                    variant={getVariant(text.length)}
                    name={ruleName}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    onBlur={handleBlur}
                />
            </td>
          <td>" "</td>
        </tr>
    );
};

export default RuleTextWithPlaceholders;
