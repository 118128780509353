import { useCallback } from "react";
import viewComponents from "../views";
import { useSharableState } from "./share-state";
import getViewPropsFromConfig from "./get-view-props-from-config";

const InputItem = (props) => {
  const { item, specs, state, result, disabled } = props;
  const viewSpec = specs.find((spec) => spec._id === item.viewId) ?? {};
  const Component = viewComponents[viewSpec.componentCodeKey]?.component;

  const viewPropObj = getViewPropsFromConfig(viewSpec.viewProps, item, state);

  const { setState: setSharedState } = useSharableState();
  const _viewId = `${props.stepNumber + 1}_${props.itemNumber + 1}`;
  const inputKey = item.inputField || `_input_${_viewId}`;

  const setState = useCallback((inputValue, patch = {}) => {
    setSharedState({
      ...patch,
      [inputKey]: inputValue,
      [`${inputKey}_submitted`]: inputValue,
    });
  }, [setSharedState, inputKey]);

  return Component ? (
    <Component
      value={state[inputKey]}
      viewProps={viewPropObj}
      result={result}
      disabled={disabled}
      setState={setState}
    />
  ) : null;
};

export default InputItem;
