import {doFetch} from "./index";
import {useMutation, useQueryClient, useSuspenseQuery} from "@tanstack/react-query";

const baseUrl = process.env.REACT_APP_MANIPULATIVES_AWS_APIGATEWAY_URL;
const assignmentsBase = new URL('assignments', baseUrl).href;
const questionTypesBase = new URL('question-types', baseUrl).href;

const getAssignmentsForEditor = (editorId) => () => {
  if (!editorId) return Promise.resolve([]);
  return doFetch(`${assignmentsBase}?editorId=${editorId}`);
}

const assignGoalToEditor = () => ({ goalId, goalName, editorId }) =>
  doFetch(assignmentsBase, 'POST', { goalId, goalName, editorId });

const deleteAssignment = () => ({ assignmentId }) =>
  doFetch(`${assignmentsBase}/${assignmentId}`, 'DELETE');

const getQuestionTypesForEditorGoal = (editorId, goalId) => () => {
  if (!editorId || !goalId) return Promise.resolve([]);
  return doFetch(`${questionTypesBase}/editor/${editorId}/goal/${goalId}`);
}

export const useAssignGoalToEditor = (editorId) => {
  const queryClient = useQueryClient();
  const { mutate: assign } = useMutation({
    mutationFn: assignGoalToEditor(),
    onSettled: () => {
      queryClient.invalidateQueries(['assignments', editorId]);
    }
  });
  return (goalId, goalName, editorId) => assign({ goalId, goalName, editorId });
};

export const useAssignmentsForEditor = (editorId) => {
  return useSuspenseQuery({
    queryKey: ['assignments', editorId],
    queryFn: getAssignmentsForEditor(editorId),
  }).data;
};

export const useDeleteAssignment = () => {
  const queryClient = useQueryClient();
  const { mutate: remove } = useMutation({
    mutationFn: deleteAssignment(),
    onSettled: () => {
      queryClient.invalidateQueries(['assignments']);
    }
  });
  return (assignmentId) => remove({ assignmentId });
};

export const useGetQuestionTypes = (editorId, goalId) => {
  return useSuspenseQuery({
    queryKey: ['question-types', editorId, goalId],
    queryFn: getQuestionTypesForEditorGoal(editorId, goalId),
  }).data;
};
