import {typeIds} from "app-constants/specs";

const questionTypeGenerator = (questionType, baseGenerator = {}) =>
   ['introSteps', 'questionSteps', 'solutionSteps'].reduce((generator, type) => ({
      ...generator,
      values: (generator.values ?? [])
         .concat(...getSteps(type, questionType?.[type] ?? []))
         .concat(...getResults(questionType?.[type] ?? [])),
   }), {...baseGenerator});

export default questionTypeGenerator;

const getResults = (steps) => {
  if (!steps.length) return [];

  return [
    {
      id: '_results',
      name: '_results',
      type: typeIds.FOLDER_TYPE_ID,
      rules: [{}],
    },
     ...steps.reduce((soFar, step, stepNumber) => {
       const inputs = step.items.reduce((inputsSoFar, item, itemNumber) => {
         if (item.type === 'input') {
           const inputKey = item.inputField || `_input_${stepNumber + 1}_${itemNumber + 1}`;
           const itemKey = `step_${stepNumber + 1}_item_${itemNumber + 1}`;
           inputsSoFar.push({
             id: `_results.${itemKey}`,
             parentId: '_results',
             name: itemKey,
             type: typeIds.FOLDER_TYPE_ID,
             rules: [{}]
           },{
             id: `_results.${itemKey}.itemKey`,
             parentId: `_results.${itemKey}`,
             name: 'itemKey',
             type: typeIds.TEXT_EXPRESSION_TYPE_ID,
             rules: [{ text: itemKey }]
           },{
             id: `_results.${itemKey}.submitted`,
             parentId: `_results.${itemKey}`,
             name: 'submitted',
             type: typeIds.DEFAULT_VALUE_TYPE_ID,
             rules: [{ expression: `${inputKey}_submitted` }]
           },{
             id: `_results.${itemKey}.isCorrect`,
             parentId: `_results.${itemKey}`,
             name: 'isCorrect',
             type: item.answerCheckField
              ? typeIds.DEFAULT_VALUE_TYPE_ID
              : typeIds.EQUAL_JSON_TYPE_ID,
             rules: item.answerCheckField
              ? [{ expression: item.answerCheckField }]
              : [{ list: [`${item.answerField}`,`${inputKey}`]}]
           },{
             id: `_results.${itemKey}.isSubmissionCorrect`,
             parentId: `_results.${itemKey}`,
             name: 'isSubmissionCorrect',
             type: typeIds.EQUAL_JSON_TYPE_ID,
             rules: [{ list: [`${item.answerField}`,`${inputKey}_submitted`]}]
           },{
             id: `_results.${itemKey}.status`,
             parentId: `_results.${itemKey}`,
             name: 'status',
             type: typeIds.DEFAULT_VALUE_TYPE_ID,
             rules: [{ expression: `_results.${itemKey}.isCorrect ? "correct" : "incorrect"` }]
           },{
             id: `_results.${itemKey}.answer`,
             parentId: `_results.${itemKey}`,
             name: 'answer',
             type: typeIds.DEFAULT_VALUE_TYPE_ID,
             rules: [{ expression: `${item.answerField}` }]
           },{
             id: `_results.${itemKey}.answerKey`,
             parentId: `_results.${itemKey}`,
             name: 'answerKey',
             type: typeIds.TEXT_EXPRESSION_TYPE_ID,
             rules: [{ text: inputKey }]
           });
         }
         return inputsSoFar;
       }, []);
       return soFar.concat(...inputs);
     }, [])
  ];
}

const getSteps = (name, steps) => {
  return steps.length ? [
    {
      id: name,
      name,
      type: typeIds.EMPTY_LIST_TYPE_ID,
      rules: [{}],
    },
    ...steps.map((step, stepNumber) => getStep(step, stepNumber, name)),
  ] : [];
};

const getStep = (step, stepNumber, parentName) => {
  return [
    {
      id: `${parentName}.${stepNumber}`,
      parentId: parentName,
      name: `${stepNumber}`,
      type: typeIds.FOLDER_TYPE_ID,
      rules: [{}],
    },
    ...getItems(step.items, `${parentName}.${stepNumber}`),
  ];
};

const getItems = (items, parentName) => {
  const itemArray = items.flatMap((item, itemNumber) => getItem(item, itemNumber, `${parentName}.items`));

  return [
    {
      id: `${parentName}.items`,
      parentId: parentName,
      name: 'items',
      type: typeIds.EMPTY_LIST_TYPE_ID,
      rules: [{}],
    },
    ...itemArray,
  ]
};

const getItem = (item, itemNumber, parentId) => {
  return [
    {
      id: `${parentId}.${itemNumber}`,
      parentId: parentId,
      name: `${itemNumber}`,
      type: typeIds.FOLDER_TYPE_ID,
      rules: [{}],
    },
    ...Object.entries(item).map(([prop, propValue]) => {
      const isText = item.type === 'text' && prop === 'text';
      return ({
        id: `${parentId}.${itemNumber}.${prop}`,
        parentId: `${parentId}.${itemNumber}`,
        name: prop,
        type: isText
          ? typeIds.TOKEN_ARRAY_TYPE_ID
          : typeIds.VALUE_TYPE_ID,
        rules: [isText ? {text: propValue ?? ''} : {value: propValue ?? ''}],
      });
    })
  ];
};
