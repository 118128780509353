import {faMessage, faRightFromBracket, faRightToBracket, faSliders} from "@fortawesome/free-solid-svg-icons";
import {upperFirst} from "@lasalle/rich-content-utils/text/text";
import TextEditor from "./text-editor";
import OutputEditor from "./output-editor";
import InputEditor from "./input-editor";
import ControlEditor from "./control-editor";
import ItemHeading from "../item-heading";

const itemComponents = {
   text: {
      item: (item) => item.text ? item.text : 'Text: Click Edit to update.',
      editor: TextEditor,
      icon: faMessage,
   },
   output: {
      item: (item, views) => `Output: ${(views.find((v) => v._id === item.viewId) ?? {}).name}`,
      editor: OutputEditor,
      icon: faRightFromBracket,
   },
   input: {
      item: (item, views) => `Input: ${(views.find((v) => v._id === item.viewId) ?? {}).name}`,
      editor: InputEditor,
      icon: faRightToBracket,
   },
   controls: {
      item: (item, controls) => `Controls: ${(item.controls ?? []).map((c) => (controls.find((con) => con._id === c.controlSpecId) ?? {}).name).join(', ')}`,
      editor: ControlEditor,
      icon: faSliders,
   },
};

const ItemEditor = (props) => {
   const { item, stepIndex, itemIndex, questionType, isLocked } = props;
   const { setViewId, setText, setIsKeyItem, updateItem, deleteItem } = props;
   const ItemEditorComponent = itemComponents[item?.type]?.editor;

   return ItemEditorComponent ? (
     <fieldset disabled={isLocked}>
        <ItemHeading
          itemType={upperFirst(item.type)}
          stepIndex={stepIndex}
          itemIndex={itemIndex}
          isLocked={isLocked}
          deleteItem={deleteItem}
        />
        <ItemEditorComponent
          key={item.id}
          item={item}
          questionType={questionType}
          stepIndex={stepIndex}
          itemIndex={itemIndex}
          setViewId={setViewId}
          setText={setText}
          setIsKeyItem={setIsKeyItem}
          updateItem={updateItem}
          deleteItem={deleteItem}
        />
     </fieldset>
   ) : null;
};

export default ItemEditor;
