import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus} from "@fortawesome/free-solid-svg-icons";
import {DeleteButton} from "../../shared/buttons/buttons";
import {useSnapshotDispatch} from "../../snapshots/snapshots";

const Remove = ({ itemsField, filterField, _snapshotId, ...state }) => {
   const dispatch = useSnapshotDispatch();
   const [prop1, prop2] = itemsField.split('.');
   let items = state[prop1];
   if (items && prop2) items = state[prop1][prop2] ?? [];
   const filter2 = filterField.split('.')[1];
   const selected = items?.filter((s) => s[filter2 ?? filterField]);

   const handleRemove = () => {
      if (selected) {
         const itemsToUpdate = [...items].filter((s) => !s[filter2 ?? filterField]);

         if (prop2) {
            dispatch('update', _snapshotId, {
               [prop1]: {
                  ...state[prop1],
                  [prop2]: itemsToUpdate,
               },
            }, true);
         } else {
            dispatch('update', _snapshotId, {
               [prop1]: itemsToUpdate,
            }, true);
         }
      }
   };

   return (
      <DeleteButton onClick={handleRemove}>
         <FontAwesomeIcon icon={faMinus} />
      </DeleteButton>
   );
};

export default Remove;
