import controlComponents from 'components/manipulative-controls';

const ControlsItem = ({item, specs, state}) => {
   return specs.length > 0 ? (
      <div className="question-viewer-item-controls">
         {(item.controls ?? []).map((control) => {
            const ControlComponent = controlComponents[specs.find((c) => c._id === control.controlSpecId).componentCodeKey];
            const props = Object.fromEntries(control.fields.map((f) => [f.name, f.value]));
            return <ControlComponent key={control.id} {...props} {...state} />
         })}
      </div>
   ): null;
};

export default ControlsItem;
