import {NavLink, useNavigate, useParams} from "react-router-dom";
import {DndContext} from '@dnd-kit/core';
import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
   faFolderClosed, faFolderOpen,
   faMessage,
   faRightFromBracket,
   faRightToBracket,
   faSliders,
} from "@fortawesome/free-solid-svg-icons";
import {upperFirst} from "@lasalle/rich-content-utils/text/text";
import {useSpecs} from "api";
import {useUpdateQuestionType} from "api/question-types";
import {Button} from "components/shared/buttons/buttons";
import NavSection from "components/nav/nav-section";
import TextEditor from "./step-editors/text-editor";
import OutputEditor from "./step-editors/output-editor";
import InputEditor from "./step-editors/input-editor";
import ControlEditor from "./step-editors/control-editor";
import ManipulativeEditor from "./step-editors/manipulative-editor";
import SortableItem from "./sortable-item";
import "./steps-editor.css";

const StepsEditor = (props) => {
   const {specType, specId} = useParams();
   const {questionTypeId, stepType, steps = [], isLocked} = props;
   const {data: views} = useSpecs('views') ?? {data: []};
   const {data: controls} = useSpecs('controls') ?? {data: []};
   const {data: manipulatives} = useSpecs('manipulatives') ?? {data: []};
   const {addStep, updateStep} = useUpdateQuestionType(questionTypeId);

   const navigate = useNavigate();
   const urlStub = `/specs/${specType}/${specId}/${stepType}`;

   const handleAddStep = () => {
      addStep(stepType);
      navigate(`${urlStub}/${steps.length + 1}`, {replace: true});
   };

   const handleAddItem = (step, stepIndex, itemType) => {
      updateStep(stepType, {...step, items: [...(step.items ?? []), { type: itemType }]});
      navigate(`${urlStub}/${stepIndex}/${step.items.length + 1}`, {replace: true});
   };

   const handleMoveItem = (stepIndex, fromItemIndex, toItemIndex) => {
      const step = {...steps[stepIndex]};
      const items = [...step.items];
      if (fromItemIndex >= 0 && fromItemIndex < items.length && toItemIndex >= 0 && toItemIndex < items.length) {
         const movingItem = items.splice(fromItemIndex, 1)[0];
         items.splice(toItemIndex, 0, movingItem);
         updateStep(stepType, {...step, items: [...items]});
         navigate(`${urlStub}/${stepIndex + 1}/${toItemIndex + 1}`, {replace: true});
      }
   };

   const itemComponents = {
      text: {
         item: (item) => item.text ? item.text : 'Text',
         editor: TextEditor,
         icon: faMessage,
      },
      output: {
         item: (item) => item.viewId
            ? `${(views.find((v) => v._id === item.viewId) ?? {}).name}`
            : 'Output',
         editor: OutputEditor,
         icon: faRightFromBracket,
      },
      input: {
         item: (item) => item.viewId
            ? `${(views.find((v) => v._id === item.viewId) ?? {}).name}`
            : 'Input',
         editor: InputEditor,
         icon: faRightToBracket,
      },
      manipulative: {
         item: (item) => `${(manipulatives.find((m) => m._id === item.manipulativeId) ?? {}).name}`,
         editor: ManipulativeEditor,
      },
      controls: {
         item: (item) => item.controls && item.controls.length > 0
            ? `${(item.controls ?? []).map((c) => (controls.find((con) => con._id === c.controlSpecId) ?? {}).name).join(', ')}`
            : 'Controls',
         editor: ControlEditor,
         icon: faSliders,
      },
   };

   itemComponents.view = itemComponents.input;

   const handleDragEnd = ({ active = {}, over = {} }) => {
      const { sortable: { index: fromIndex} } = active.data.current;
      const { sortable: { index: toIndex, containerId } } = over?.data.current;
      const stepIndex = parseInt(containerId.split('_')[1], 10);
      if (toIndex !== undefined) {
         handleMoveItem(stepIndex, fromIndex, toIndex);
      }
   };

   return (
      <div>
         <h3 className="values-list-controls">
            <span>{upperFirst(stepType)} Steps</span>
            <span>
               <Button onClick={handleAddStep} title="New Value" disabled={isLocked}>
                  <FontAwesomeIcon icon={faFolderClosed} />
               </Button>
            </span>
         </h3>
         <DndContext onDragEnd={handleDragEnd}>
            <SortableContext items={steps} id="steps" strategy={verticalListSortingStrategy}>
               <NavSection>
                  {steps.map((step, i) => {
                     return (
                       <li
                         key={step.id}
                         className="value-item sortable-nav-item sortable-folder-item step-folder"
                         style={{'--folder-depth': 0}}
                       >
                          <div className="sortable-nav-item-wrapper sortable-nav-folder-wrapper">
                           <span>
                              <FontAwesomeIcon icon={faFolderOpen}/>&nbsp;
                              <NavLink
                                to={`/specs/${specType}/${specId}/${stepType}/${i+1}`}
                                replace
                              >
                                 Step {i + 1}
                              </NavLink>
                           </span>
                           <fieldset disabled={isLocked} className="group-controls">
                             <Button onClick={() => handleAddItem(step, i+1, 'controls')}>
                                <FontAwesomeIcon icon={faSliders} title="Add controls item"/>
                             </Button>
                             <Button onClick={() => handleAddItem(step, i+1, 'text')}>
                                <FontAwesomeIcon icon={faMessage} title="Add text item"/>
                             </Button>
                             <Button onClick={() => handleAddItem(step, i+1, 'input')}>
                                <FontAwesomeIcon icon={faRightToBracket} title="Add input item"/>
                             </Button>
                             <Button onClick={() => handleAddItem(step, i+1, 'output')}>
                                <FontAwesomeIcon icon={faRightFromBracket} title={"Add output item"}/>
                             </Button>
                           </fieldset>
                          </div>
                          <SortableContext
                            id={`step_${i}`}
                            items={(step.items ?? []).map((item, j) => ({ ...item, id: `step_${i}_item_${j}` }))}
                            strategy={verticalListSortingStrategy}
                          >
                             <ol className="step-items">
                                {(step.items ?? []).map((item, j) => {
                                   item.id ??= crypto.randomUUID();
                                   const itemTitle = itemComponents[item.type]?.item(item);
                                   return (
                                     <SortableItem
                                       key={item.id}
                                       id={`step_${i}_item_${j}`}
                                       icon={itemComponents[item.type].icon}
                                       itemTitle={itemTitle}
                                       specId={specId}
                                       stepType={stepType}
                                       stepIndex={i}
                                       itemIndex={j}
                                       isLocked={isLocked}
                                     />
                                   );
                                })}
                             </ol>
                          </SortableContext>
                       </li>
                     );
                  })}
               </NavSection>
            </SortableContext>
         </DndContext>
      </div>
   );
};

export default StepsEditor;
