import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useUsersByRole} from "api";
import NavSection from "components/nav/nav-section";
import NavItem from "components/nav/nav-item";
import icons from "app-constants/icons";

const ListOfEditors = () => {
  const editors = useUsersByRole('rich_content_editor');

  return (
    <NavSection>
      {editors.map((editor) => (
        <NavItem to={`/assignments/admin/${editor._id}`} key={editor._id}>
          <span>
            <FontAwesomeIcon icon={icons.editor}/>
            {editor.firstName} {editor.lastName}
          </span>
        </NavItem>
      ))}
    </NavSection>
  );
};

export default ListOfEditors;
