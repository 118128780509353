import {Suspense} from "react";
import {useParams} from "react-router-dom";
import SectionNavBasic from "./section-nav-basic";
import SectionNavLoader from "./section-nav-loader";
import ActionNavBasic from "./action-nav-basic";

const SpecInfoNav = () => {
  const {specType, specId} = useParams();
  return (
    <>
      <Suspense fallback={<SectionNavBasic specType={specType} specId={specId} />}>
        <SectionNavLoader specType={specType} specId={specId} />
      </Suspense>
      <div className="nav-section">
        <ActionNavBasic specType={specType} />
      </div>
    </>
  );
};

export default SpecInfoNav;
