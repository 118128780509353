import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import Select from "components/shared/select/select";
import TextBox from "components/shared/text-box/text-box";
import {AddButton, DeleteButton} from "components/shared/buttons/buttons";
import Label from "components/shared/label/label";
import {ToggleBox, ToggleBoxContents, ToggleBoxHeader} from "components/shared/toggle-box/toggle-box";
import Card from "components/shared/card/card";
import Controls from "components/shared/controls/controls";
import StepIndexPicker from "./step-index-picker";

const nameSort = (a, b) => a.name.localeCompare(b.name);

const OutputViewStep = ({ index, step, numSteps, views, setStepValue, moveStep, removeStep }) => {
   const [transformKey, setTransformKey] = useState('');
   const [transformValue, setTransformValue] = useState('');

   const handleOutputViewChange = (e) => {
      const { value } = e.target;
      setStepValue({
         ...step,
         outputView: value,
      });
   };
   const handleOutputFieldChange = (e) => {
      const { value } = e.target;
      setStepValue({
         ...step,
         outputField: value,
      });
   };

   const handleAddTransform = () => {
      if (transformKey.trim() && transformValue.trim()) {
         const transforms = [...(step.transforms ?? [])];
         transforms.push({ [transformKey]: transformValue });
         setStepValue({ ...step, transforms });
      }
   };

   const handleRemoveTransform = (i) => {
      const transforms = [...(step.transforms ?? [])];
      transforms.splice(i, 1);
      setStepValue({ ...step, transforms });
   };

   const handleTransformFieldChange = (i, from, to) => {
      const transform = { [from]: to };
      step.transforms.splice(i, 1, transform);
      setStepValue({
         ...step,
         transforms: [...step.transforms],
      });
   };

   const sortedViews = Object.values(views).sort(nameSort);

   return views ? (
      <ToggleBox className="question-step view-step">
         <ToggleBoxHeader>
            <span>
               <FontAwesomeIcon icon={faList} />&nbsp;
               Step&nbsp;
               <StepIndexPicker
                  numSteps={numSteps}
                  stepIndex={index - 1}
                  handleMoveStep={moveStep}
               />&nbsp;
               - Output View
            </span>
            <DeleteButton onClick={removeStep}>
               <FontAwesomeIcon icon={faTrash} />
            </DeleteButton>
         </ToggleBoxHeader>
         <ToggleBoxContents>
            <ToggleBox>
               <ToggleBoxHeader>Output View:</ToggleBoxHeader>
               <ToggleBoxContents>
                  <Label>View:</Label>
                  <Select value={step.outputView} onChange={handleOutputViewChange}>
                     {Object.values(sortedViews).map((view) => (
                        <option key={view.id} value={view.id}>{view.name}</option>
                     ))}
                  </Select>
                  <Label>Output Field:</Label>
                  <TextBox value={step.outputField} onChange={handleOutputFieldChange} />

                  <Label>Transform Field</Label>
                  <TextBox
                     value={(step.transforms ?? [])[0]?.[step.outputField] ?? ''}
                     onChange={handleTransformFieldChange}
                  />
               </ToggleBoxContents>
            </ToggleBox>

            <ToggleBox>
               <ToggleBoxHeader>Transforms</ToggleBoxHeader>
               <ToggleBoxContents>
                  {(step.transforms ?? []).map((transform, i) => {
                     const [from, to] = Object.entries(transform)[0];
                     return (
                        <Card key={from}>
                           <Label>From</Label>
                           <TextBox
                              value={from}
                              onChange={(e) => handleTransformFieldChange(i, e.target.value, to)}
                           />
                           <Label>To</Label>
                           <TextBox
                              value={to}
                              onChange={(e) => handleTransformFieldChange(i, from, e.target.value)}
                           />
                           <DeleteButton onClick={() => handleRemoveTransform(i)}>
                              <FontAwesomeIcon icon={faTrash} />
                           </DeleteButton>
                        </Card>
                     );
                  })}
                  <Controls>
                     <label>From:</label>&nbsp;
                     <TextBox value={transformKey} onChange={(e) => setTransformKey(e.target.value)} />
                     <label>To:</label>&nbsp;
                     <TextBox value={transformValue} onChange={(e) => setTransformValue(e.target.value)} />
                     <AddButton onClick={handleAddTransform}>
                        <FontAwesomeIcon icon={faPlus} />&nbsp;Add Transform
                     </AddButton>
                  </Controls>
               </ToggleBoxContents>
            </ToggleBox>
         </ToggleBoxContents>
      </ToggleBox>
   ) : null;
};

export default OutputViewStep;

