import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderClosed, faFolderOpen} from "@fortawesome/free-solid-svg-icons";
import {Button} from "components/shared/buttons/buttons";
import "./type-picker.css";

const TypePicker = ({ typeGroups, selectedType, onPick }) => {
   const [selectedGroup, setSelectedGroup] = useState('Numbers');

   const groupNames = Object.keys(typeGroups).sort((a, b) => a.localeCompare(b));
   const typesToShow = (typeGroups[selectedGroup] ?? []).sort((a, b) => a.name.localeCompare(b.name));

   return (
      <div className="type-picker">
         <div className="type-picker-groups">
            <ul>
               {groupNames.map((group) => (
                  <li key={group} className={group === selectedGroup ? 'selected' : ''}>
                     <Button onClick={() => setSelectedGroup(group)}>
                        {group === selectedGroup ? (
                           <FontAwesomeIcon icon={faFolderOpen} />
                        ) : (
                           <FontAwesomeIcon icon={faFolderClosed} />
                        )}
                        {group}
                     </Button>
                  </li>
               ))}
            </ul>
         </div>
         <div className="type-picker-types">
            <ul>
               {typesToShow.map((type) => (
                  <li key={type._id} className={type._id === selectedType ? 'selected' : ''}>
                     <Button onClick={() => onPick(type._id)}>
                        <h3>{type.name}</h3>
                        <p>{type.description}</p>
                     </Button>
                  </li>
               ))}
            </ul>
         </div>
      </div>
   );
};

export default TypePicker;
