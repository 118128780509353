import {useState} from "react";
import {useSpecs} from "api";
import useDebounce from "lib/hooks/use-debounce";
import {AddButton, DeleteButton} from "components/shared/buttons/buttons";
import Select from "components/shared/select/select";
import TextBox from "components/shared/text-box/text-box";

const ControlEditor = ({item, updateItem}) => {
   const {data: controls} = useSpecs('controls') ?? {data: []};
   const [controlId, setControlId] = useState('');
   const [itemControls, setItemControls] = useDebounce(
      (updatedControls) => updateItem({ controls: updatedControls }),
      item.controls ?? []
   );

   const handleAddControl = (e) => {
      e.preventDefault();
      if (controlId === '') return;
      const newControl = controls.find((c) => c._id === controlId);
      const id = crypto.randomUUID();
      setItemControls([
         ...itemControls,
         {
            id,
            controlSpecId: controlId,
            fields: Object.keys(newControl.fields).map((name) => ({
               name,
               value: ''
            })),
         }
      ]);
   };

   const handleRemoveControl = (e, id) => {
      e.preventDefault();
      const controlIndex = itemControls.findIndex((c) => c.id === id);
      itemControls.splice(controlIndex, 1);
      setItemControls([...itemControls]);
   };

   const handleUpdateControl = (e, id) => {
      e.preventDefault();
      const {name, value} = e.target;
      const controlIndex = itemControls.findIndex((c) => c.id === id);
      const control = itemControls[controlIndex];
      const fieldIndex = control.fields.findIndex((f) => f.name === name);
      control.fields[fieldIndex] = {name, value};
      setItemControls([...itemControls]);
   };

   return (
      <>
         <div>
            <table className="editor-table">
               <tbody>
               {itemControls.map((control) => {
                  return (
                     <tr key={control.id}>
                        <td>{controls.find((c) => c._id === control.controlSpecId).name}</td>
                        <td>
                           {control.fields.map((field) => (
                              <div className="item-control-field" key={field.name}>
                                 <label title={controls.find((c) => c._id === control.controlSpecId).fields[field.name]}>
                                    {field.name}:{' '}
                                    <TextBox
                                       name={field.name}
                                       value={field.value}
                                       onChange={(e) => handleUpdateControl(e, control.id)}
                                    />
                                 </label>
                              </div>
                           ))}
                        </td>
                        <td>
                           <DeleteButton withIcon onClick={(e) => handleRemoveControl(e, control.id)} />
                        </td>
                     </tr>
                  );
               })}
               </tbody>
            </table>
         </div>
         <div>
            <Select value={controlId} onChange={(e) => setControlId(e.target.value)}>
               {controlId === '' && <option value="">-- SELECT --</option>}
               {controls.sort((a, b) => a.name.localeCompare(b.name)).map((control) => (
                  <option key={control._id} value={control._id}>{control.name}</option>
               ))}
            </Select>{' '}
            <AddButton withIcon onClick={handleAddControl} />
         </div>
      </>
   );
};

export default ControlEditor;
