import {setObjectProperty} from "@lasalle/rich-content-utils/objects/object-properties";
import {useUpdateSpec} from "./index";
import getNextName from "../lib/utils/names";

const patchValue = (values, valueId, patch) => {
   const [id, ...nestedIds] = valueId.split('.');
   const valueIndex = values.findIndex((v) => v.id === id);
   const valuesToUpdate = [...values];
   if (valueIndex !== -1) {
      if (nestedIds.length) {
         const nestedValue = valuesToUpdate[valueIndex].rules[0];
         valuesToUpdate[valueIndex].rules[0] = setObjectProperty(nestedValue, nestedIds.join('.'), patch);
      } else {
         valuesToUpdate[valueIndex] = {
            ...valuesToUpdate[valueIndex],
            ...patch,
         }
      }
   }

   return valuesToUpdate;
};

const getParentIds = (allValues, childId, soFar = []) => {
   const parentId = allValues.find((v) => v.id === childId)?.parentId;
   return parentId ? getParentIds(allValues, parentId, [parentId, ...soFar]) : soFar;
};

const getChildCount = (allValues, itemId) => allValues.filter(
   (v) => getParentIds(allValues, v.id).includes(itemId)
).length;

export const useUpdateGenerator = (specId) => {
   const api = useUpdateSpec('generators', specId);
   const setValues = api.setField('values');

   const addValue = (name, valueTypeId, parentId) => {
      const spec = api.getSpec();
      const values = [...spec.values ?? []];
      const value = {
         id: crypto.randomUUID(),
         name,
         parentId,
         type: valueTypeId,
         rules: [{}],
      };
      values.push(value);
      return setValues(values).then((resp) => ({data: resp, id: value.id}));
   };

   const copyValue = (value) => {
      const spec = api.getSpec();
      let values = [...spec.values ?? []];

      const valueCopy = {
         ...value,
         name: getNextName(value.name, values),
         id: crypto.randomUUID(),
      };

      let idLookup = { [value.id]: valueCopy.id };

      const descendants = values.filter((v) => {
         const parentIds = getParentIds(values, v.id);
         return parentIds.includes(value.id);
      }).map((v) => {
         const id = crypto.randomUUID();
         idLookup[v.id] = id;
         return {
            ...v,
            id,
            parentId: idLookup[v.parentId],
         };
      });

      const toIndex = values.findLastIndex((v) => v.parentId === valueCopy.parentId) + 1;
      values = values.toSpliced(toIndex, 0, valueCopy, ...descendants);
      return setValues(values).then((resp) => ({data: resp, id: valueCopy.id}));
   };

   const removeValue = (valueId) => {
      const spec = api.getSpec();
      let values = [...spec.values ?? []];

      values = values.filter((v) => {
         const parentIds = getParentIds(values, v.id);
         return !(parentIds.includes(valueId) || v.id === valueId);
      });

      return setValues(values);
   };

   const updateValue = (valueId, valuePatch) => {
      const spec = api.getSpec();
      const values = [...spec.values ?? []];
      const patchedValues = patchValue(values, valueId, valuePatch);

      return setValues(patchedValues);
   };

   const addNestedValue = (parentId, fieldName, fieldType) => {
      const spec = api.getSpec();
      const values = [...spec.values ?? []];
      const valueIndex = values.findIndex((v) => v.id === parentId);
      const lastChildIndex = values.findLastIndex((v) => v.parentId === parentId);

      // If the last child is a folder, we need to set the index after its children.
      const lastChildCount = getChildCount(values, values[lastChildIndex]?.id);
      const spliceIndex = (lastChildIndex === -1 ? valueIndex : lastChildIndex + lastChildCount) + 1;

      const value = {
         id: crypto.randomUUID(),
         name: fieldName,
         parentId,
         type: fieldType,
         rules: [{}],
      };

      values.splice(spliceIndex, 0, value);

      return setValues(values).then((resp) => ({data: resp, id: value.id}));
   };

   const updateValueName = (valueId, name) => {
      const [id, ...nestedIds] = valueId.split('.');
      if (nestedIds.length) {
         const spec = api.getSpec();
         const values = [...spec.values ?? []];
         const value = values.find((v) => v.id === id);
         const fields = [...value.type.fields];
         const field = fields.find((f) => f.id === nestedIds.join('.'));
         field.name = name;
         const type = { ...value.type, fields };
         return updateValue(id, { type });
      } else {
         return updateValue(id, { name });
      }
   };

   const updateValueType = (valueId, type) => {
      let typePatch = { type, rules: [{}] };
      const [id, ...nestedIds] = valueId.split('.');
      if (nestedIds.length) {
         const spec = api.getSpec();
         const values = [...spec.values ?? []];
         const value = values.find((v) => v.id === id);
         const fields = [...value.type.fields];
         const field = fields.find((f) => f.id === nestedIds.join('.'));
         field.type = type;
         typePatch = { type: { ...value.type, fields }, rules: [{}] };
      }
      return updateValue(id, typePatch);
   };

   const moveValue = (sourceId, destinationId, parentFolderId) => {
      const spec = api.getSpec();
      const values = [...spec.values ?? []];

      const sourceIndex = values.findIndex((v) => v.id === sourceId);
      const destIndex = values.findIndex((v) => v.id === destinationId);

      // We'll move the source item and its children.
      const sourceCount = getChildCount(values, sourceId) + 1;

      // If moving past a folder, we need to move past its children too.
      const destCount = parentFolderId !== destinationId
        ? getChildCount(values, destinationId) + 1
        : 1;

      values[sourceIndex].parentId = parentFolderId;

      const spliced = values.splice(sourceIndex, sourceCount);
      values.splice(destIndex > sourceIndex ? destIndex - sourceCount + destCount : destIndex, 0, ...spliced);

      return setValues(values);
   };

   const setRule = (valueId, rulePath, ruleValue, level = 0) => {
      const spec = api.getSpec();
      const values = [...spec.values ?? []];
      const [parentId] = valueId.split('.');
      const parentIndex = values.findIndex((v) => v.id === parentId);

      if (parentIndex === -1) return;

      // Recursively assign nested properties to the rules object.
      function assignProp(obj, propPath, value) {
         const [first, ...rest] = propPath.split('.');
         return rest.length
            ? { ...(obj ?? {}), [first]: assignProp(obj?.[first] ?? {}, rest.join('.'), value) }
            : { ...obj, [first]: value };
      }

      const value = values[parentIndex];
      const parentRules = [...(value.rules ?? [])];

      parentRules[level] = assignProp(parentRules[level] ?? {}, rulePath, ruleValue);

      value.rules = parentRules;

      return setValues(values);
   };

   const addRuleLevel = (valueId) => {
      const spec = api.getSpec();
      const values = [...spec.values ?? []];
      const valueIndex = values.findIndex((v) => v.id === valueId);

      if (valueIndex === -1) return;

      const value = values[valueIndex];
      const rules = [...(value.rules ?? [])];
      rules.push({...(rules[rules.length - 1] ?? {})});
      value.rules = rules;

      return setValues(values);
   };

   const removeRuleLevel = (valueId, level) => {
      const spec = api.getSpec();
      const values = [...spec.values ?? []];
      const valueIndex = values.findIndex((v) => v.id === valueId);

      if (valueIndex === -1) return;

      const rules = values[valueIndex].rules;

      if (!rules || rules.length < 2) return;

      rules.splice(level, 1);

      return setValues(values);
   };

   return {
      setNameAndDescription: (name, description) => api.patch({name, description}),
      addValue,
      copyValue,
      updateValue,
      moveValue,
      removeValue,
      addNestedValue,
      updateValueName,
      updateValueType,
      setRule,
      addRuleLevel,
      removeRuleLevel,
   };
};
