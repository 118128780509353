import {Suspense, useTransition} from "react";
import {Navigate, useParams} from "react-router-dom";
import Nav from "components/nav/nav";
import LogoBox from "components/shared/logo-box/logo-box";
import EditorMenu from "components/icon-menus/editor-menu";
import {useUser} from "store";
import SpecsListTable from "./specs-list-table/specs-list-table";
import SpecListNav from "./spec-list-nav";
import SpecHeader from "../spec-header/spec-header";
import "./spec-list.css";

const SpecList = () => {
  const [isLoadingData, startLoadingData] = useTransition();
  const { specType } = useParams();
  const { isAdmin } = useUser();
  const canView = isAdmin || specType === 'question-types';

  return canView ? (
    <>
      <EditorMenu />
      <Nav className="spec-list-nav">
        <LogoBox />
        <SpecListNav startLoadingData={startLoadingData}/>
      </Nav>
      <main>
        <SpecHeader hasCreateLink={true} />
        <div>
          <Suspense fallback={<div><p>Loading specs...</p></div>}>
            <SpecsListTable
              specType={specType}
              isLoadingData={isLoadingData}
              startLoadingData={startLoadingData}
            />
          </Suspense>
        </div>
      </main>
    </>
  ) : <Navigate to="/" replace={true} />;
};

export default SpecList;
