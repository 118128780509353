import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import icons from "app-constants/icons";

const SpecsMenu = () => {
  return (
    <ul className="icon-menu">
      <li>
        <NavLink to="/specs/question-types" end title="Question Types Specs List">
          <FontAwesomeIcon icon={icons['question-types']}/>
        </NavLink>
      </li>
    </ul>
  );
};

export default SpecsMenu;
