import {Suspense} from "react";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import icons from "app-constants/icons";
import {useUser} from "store";

const AssignmentsMenuAdmin = () => {
  const {isAdmin} = useUser();
  return isAdmin ? (
    <li>
      <NavLink to="/assignments/admin" end title="Assignments Admin">
        <FontAwesomeIcon icon={icons['assignments-admin']}/>
      </NavLink>
    </li>
  ) : null;
};

const AssignmentsMenu = () => {
  return (
    <ul className="icon-menu">
      <li>
        <NavLink to="/assignments" end title="My Assigned Goals">
          <FontAwesomeIcon icon={icons.assignments}/>
        </NavLink>
      </li>
      <Suspense fallback={null}>
        <AssignmentsMenuAdmin />
      </Suspense>
    </ul>
  );
};

export default AssignmentsMenu;
