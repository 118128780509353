import {Suspense} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import icons from "app-constants/icons";
import Nav from "components/nav/nav";
import LogoBox from "components/shared/logo-box/logo-box";
import EditorMenu from "components/icon-menus/editor-menu";
import AssignmentsNavSection from "pages/assignments/assignments-nav-section";
import ListOfEditors from "./list-of-editors";

const AssignmentsAdminHome = () => {
  return (
    <>
      <EditorMenu />
      <Nav>
        <LogoBox />
        <AssignmentsNavSection />
        <Suspense fallback={null}>
          <h3>Editors</h3>
          <ListOfEditors />
        </Suspense>
      </Nav>
      <main>
        <header className="spec-header">
          <div className="icon-title">
            <FontAwesomeIcon icon={icons['assignments-admin']}/>
            <div>
              <h2>Assignments Admin</h2>
              <p>Assign goals to editors</p>
            </div>
          </div>
        </header>
        <div>
          <div>
            <h2>Assignments Admin Home</h2>
          </div>
        </div>
      </main>
    </>
  );
};

export default AssignmentsAdminHome;
