import {Suspense} from "react";
import Nav from "components/nav/nav";
import LogoBox from "components/shared/logo-box/logo-box";
import EditorMenu from "components/icon-menus/editor-menu";
import {useUser} from "store";
import SpecInfoNav from "./spec-info-nav/spec-info-nav";
import SpecInfoRoutes from "./spec-info-routes";
import SpecHeader from "../spec-header/spec-header";
import {Navigate, useParams} from "react-router-dom";

const SpecInfo = () => {
  const { specType } = useParams();
  const { isAdmin } = useUser();
  const canView = isAdmin || specType === 'question-types';

  return canView ? (
    <>
      <EditorMenu />
      <Nav>
        <LogoBox />
        <SpecInfoNav />
      </Nav>
      <main>
        <SpecHeader hasCreateLink />
        <div>
          <Suspense fallback={<div><p>Loading spec info...</p></div>}>
            <SpecInfoRoutes />
          </Suspense>
        </div>
      </main>
    </>
  ) : <Navigate to="/" replace={true} />;
};

export default SpecInfo;
