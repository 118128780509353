import {useEffect, useRef} from "react";
import 'katex/dist/katex.min.css';
import renderMathInElement from 'katex/dist/contrib/auto-render';
import {useSpecs} from "api";
import GenericType from "components/type-renderers/generic-type";
import typeRenderers from "components/type-renderers";

const latexOptions = {delimiters: [
    {left: "$$", right: "$$", display: true},
    // {left: "$", right: "$", display: false},
    {left: "\\(", right: "\\)", display: false},
    {left: "\\[", right: "\\]", display: true}
  ]};

const Token = ({token, rendererArray}) => {
  const rendererName = rendererArray.find(
    (r) => r.valueTypeId === token.valueTypeId
  )?.rendererCodeKey;
  const Renderer = typeRenderers[rendererName] ?? GenericType;
  return <Renderer value={token.value} />;
};

const TokenViewer = ({tokens, className}) => {
  const {data: renderers} = useSpecs('type-renderers') ?? {data: []};
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      renderMathInElement(ref.current, latexOptions);
    }
  });

  if (Array.isArray(tokens)) {
    return renderers.length ? (
      <span className={className} ref={ref} key={tokens.map((t) => JSON.stringify(t.value)).join('')}>
            {tokens.map((token, i) => (
              <Token token={token} rendererArray={renderers} key={i} />
            ))}
         </span>
    ) : null;
  }

  return <div className={className}>{tokens}</div>;
};

export default TokenViewer;
