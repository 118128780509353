import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {DeleteButton} from "components/shared/buttons/buttons";

const StepEditor = ({stepIndex, deleteStep, isLocked}) => {
   return (
     <h3 className="value-details-heading">
        <span>Step {stepIndex}</span>
        <span className="value-buttons">
           {isLocked ? (
             <FontAwesomeIcon icon={faLock} />
           ) : (
             <DeleteButton withIcon onClick={deleteStep}> Delete Step</DeleteButton>
           )}
        </span>
     </h3>
   );
};

export default StepEditor;
