import Select from "components/shared/select/select";

const StepIndexPicker = ({stepIndex, numSteps, handleMoveStep}) => {
   const nums = Array(numSteps).fill(0).map((_, i) => i);

   const handleChange = (e) => {
      e.stopPropagation();
      handleMoveStep(e.target.value);
   };

   return (
      <Select value={stepIndex} onChange={handleChange} onClick={(e) => e.stopPropagation()}>
         {nums.map((n) => (
            <option key={n} value={n}>{n + 1}</option>
         ))}
      </Select>
   );
};

export default StepIndexPicker;
