import {lazy} from "react";
import {Navigate, useParams} from "react-router-dom";
import {upperFirst} from "@lasalle/rich-content-utils/text/text";
import QuestionTypeEditor from "./spec-editors/question-types/question-type-editor";
import GeneratorEditor from "./spec-editors/generators/generator-editor";
import {useUser} from "../../store";

const editorComponents = {
  ControlEditor: lazy(() => import('./spec-editors/controls/control-editor')),
  GeneratorEditor,
  ManipulativeEditor: lazy(() => import('./spec-editors/manipulatives/manipulative-editor')),
  QuestionSetEditor: lazy(() => import('./spec-editors/question-sets/question-set-editor')),
  QuestionTypeEditor: QuestionTypeEditor,
  ValueTypeEditor: lazy(() => import('./spec-editors/value-types/value-type-editor')),
  TypeRendererEditor: lazy(() => import('./spec-editors/type-renderers/type-renderer-editor')),
  ValueFunctionEditor: lazy(() => import('./spec-editors/value-functions/value-function-editor')),
  ViewEditor: lazy(() => import('./spec-editors/views/view-editor')),
};

const getSpecComponents = (specType) => {
  const stub = specType.split('-').map((t) => upperFirst(t)).join('').slice(0, -1);
  return editorComponents[`${stub}Editor`];
};

const SpecEditor = () => {
  const { specType } = useParams();
  const { isAdmin } = useUser();
  const canView = isAdmin || specType === 'question-types';
  const Editor = getSpecComponents(specType);
  return canView ? <Editor /> : <Navigate to="/" replace={true} />;
};

export default SpecEditor;
