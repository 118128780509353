import {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useIsMutating} from "@tanstack/react-query";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useAddSpec} from "api";
import TextBox from "components/shared/text-box/text-box";
import {AddButton} from "components/shared/buttons/buttons";

const CopySpec = ({ spec }) => {
   const {specType} = useParams();
   const addSpec = useAddSpec(specType);
   const [copyName, setCopyName] = useState('');
   const navigate = useNavigate();
   const isMutating = useIsMutating();

   const handleCopy = async () => {
      const trimmed = copyName.trim();
      if (trimmed && trimmed !== spec.name) {
        const newSpec = await addSpec(trimmed, spec);
        navigate(`/specs/${specType}/${newSpec._id}`);
      }
   };

   return (
      <div className="info-section">
         <h3>Copy Spec</h3>
         <p>Create a copy of this spec with the same links, tags and metadata.</p>
         <p>
            <label>
               Name: {' '}
               <TextBox
                  variant="longer"
                  value={copyName}
                  onChange={(e) => setCopyName(e.target.value)}
               />
            </label>
         </p>
         <div className="controls">
            <AddButton onClick={handleCopy} withIcon disabled={isMutating}>
              {' '}Copy
            </AddButton>
           {isMutating ? <>{' '}<FontAwesomeIcon icon={faSpinner} /></> : null}
         </div>
      </div>
   );
};

export default CopySpec;
