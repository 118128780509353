import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faTrash} from "@fortawesome/free-solid-svg-icons";
import {DeleteButton} from "components/shared/buttons/buttons";
import {ToggleBox, ToggleBoxContents, ToggleBoxHeader} from "components/shared/toggle-box/toggle-box";
import TextArea from "components/shared/text-area/text-area";
import Card from "components/shared/card/card";
import Label from "components/shared/label/label";
import TextBox from "components/shared/text-box/text-box";
import StepIndexPicker from "./step-index-picker";

const TextStep = ({ index, step, numSteps, setStepValue, moveStep, removeStep }) => {
   const [localState, setLocalSate] = useState({
      value: step.value ?? '',
      title: step.title ?? '',
      isClosed: step.isClosed ?? false,
   });

   const handleChange = (e) => {
      const { name, value, checked } = e.target;
      setLocalSate((s) => ({
         ...s,
         [name]: checked ?? value,
      }));
      setStepValue({
         ...step,
         [name]: checked ?? value,
      });
   };

   const handleBlur = () => {
      setStepValue({
         ...step,
         ...localState,
      });
   };

   return (
      <ToggleBox className="question-step text-step">
         <ToggleBoxHeader>
            <span>
               <FontAwesomeIcon icon={faList} />&nbsp;
               Step&nbsp;
               <StepIndexPicker
                  numSteps={numSteps}
                  stepIndex={index - 1}
                  handleMoveStep={moveStep}
               />&nbsp;
               - Text
            </span>
            <DeleteButton onClick={removeStep}>
               <FontAwesomeIcon icon={faTrash} />
            </DeleteButton>
         </ToggleBoxHeader>
         <ToggleBoxContents>
            <Card>
               <Label>Title:</Label>
               <TextBox
                  name="title"
                  value={localState.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
               />
               <Label>Text:</Label>
               <TextArea
                  name="value"
                  rows={4}
                  value={localState.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
               />
               <Label>
                  Is Closed:
                  <input
                     name="isClosed"
                     type="checkbox"
                     checked={localState.isClosed}
                     onChange={handleChange}
                     onBlur={handleBlur}
                  />
               </Label>
            </Card>
         </ToggleBoxContents>
      </ToggleBox>
   );
};

export default TextStep;
