import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {upperFirst} from "@lasalle/rich-content-utils/text/text";
import NavSection from "components/nav/nav-section";
import NavItem from "components/nav/nav-item";
import icons from "app-constants/icons";

const SectionNavBasic = ({ specType, specId, status, numGoals, numTags, numDeps }) => (
  <NavSection>
    <NavItem to={`/specs/${specType}/${specId}/info`} end>
      <span>
        <FontAwesomeIcon icon={icons.overview}/>
        Overview
      </span>
    </NavItem>
    <NavItem to="./name-and-description">
      <span>
        <FontAwesomeIcon icon={icons["name-and-description"]}/>
        Name and Description
      </span>
    </NavItem>
    <NavItem to="./status">
      <span>
        <FontAwesomeIcon icon={icons.status}/>
        Status
      </span>
      {status && <span>{upperFirst(status)}</span>}
    </NavItem>
    {specType === 'question-types' && (
      <NavItem to="./curriculum">
        <span>
          <FontAwesomeIcon icon={icons.curriculum}/>
          Curriculum
        </span>
        <span>{numGoals}</span>
      </NavItem>
    )}
    <NavItem to="./tags">
      <span>
        <FontAwesomeIcon icon={icons.tags}/>
        Tags
      </span>
      <span>{numTags}</span>
    </NavItem>
    {specType !== 'question-sets' && (
      <NavItem to="./used-by">
        <span>
          <FontAwesomeIcon icon={icons["used-by"]}/>
          Used By
        </span>
        <span>{numDeps}</span>
      </NavItem>
    )}
    <NavItem to="./json">
      <span>
        <FontAwesomeIcon icon={icons.json}/>
        JSON
      </span>
    </NavItem>
  </NavSection>
);

export default SectionNavBasic;
