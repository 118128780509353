import { useState } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import TextBox, {getVariant} from "components/shared/text-box/text-box";
import {AddButton, DeleteButton} from "components/shared/buttons/buttons";

const RuleTextWithPlaceholdersList = ({ title, ruleName, ruleValue, updateValue }) => {
    const [textList, setTextList] = useState(ruleValue || []);

    const handleBlur = () => {
        updateValue(ruleName, textList);
    };

    const handleAddStep = () => {
       textList.push('');
       setTextList([...textList]);
    };

    const handleRemoveStep = (i) => {
       textList.splice(i, 1);
       setTextList([...textList]);
    };

    const handleUpdateStep = (i, value) => {
       textList[i] = value;
       setTextList([...textList]);
    };

    return (
        <tr>
            <th>{title}</th>
            <td>
               <ol>
                  {textList.map((text, i) => (
                     <li key={i}>
                        <TextBox
                           variant={getVariant(text)}
                           value={text}
                           onChange={(e) => handleUpdateStep(i, e.target.value)}
                           onBlur={handleBlur}
                        />
                        <DeleteButton onClick={() => handleRemoveStep(i)}>
                           <FontAwesomeIcon icon={faTrash} />
                        </DeleteButton>
                     </li>
                  ))}
               </ol>
               <AddButton onClick={handleAddStep}>
                  <FontAwesomeIcon icon={faPlus} />&nbsp;New Item
               </AddButton>
            </td>
        </tr>
    );
};

export default RuleTextWithPlaceholdersList;
