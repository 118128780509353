import "./nav.css";

const Nav = ({ className, children }) => {
  const  classes = ['nav-filters'];
  if (className && typeof className === 'string') classes.push(className);

  return (
    <nav className={classes.join(' ')}>
      {children}
    </nav>
  );
};

export default Nav;
