import {getObjectProperty} from "@lasalle/rich-content-utils/objects/object-properties";
import {useSnapshotDispatch} from "../../snapshots/snapshots";

const Slider = (sliderProps) => {
   const { valueField, title = '', min, max, step, _snapshotId, ...state } = sliderProps;
   const dispatch = useSnapshotDispatch();

   let value = getObjectProperty(state, valueField);

   const handleChange = (e) => {
      e.stopPropagation();
      dispatch('update', _snapshotId, {
         [valueField]: parseFloat(e.target.value),
      });
   };

   return (
      <div className="slider">
         <label>{title}</label>
         <input
            type="range"
            value={value}
            onChange={handleChange}
            min={min}
            max={max}
            step={step}
         />
      </div>
   );
};

export default Slider;
