import {Suspense, useCallback, useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {ErrorBoundary} from "react-error-boundary";
import {useSpec} from "api";
import {useQuestionType} from "api/question-types";
import {coreValueTypes, coreValueFunctions} from 'app-constants/specs';
import hydrateNestedGenerators from "lib/specs/hydrate-nested-generators";
import questionTypeGenerator from "lib/specs/question-type-generator";
import Snapshots, {useSnapshotDispatch} from "components/snapshots/snapshots";
import ShareStateProps from "components/share-state/share-state-props";
import {SnapshotsButtonRefresh} from "components/snapshots/snapshot-buttons";
import Select from "components/shared/select/select";
import {GeneratedValues} from "pages/specs/spec-editors/generators/preview/generated-values";
import {useSharableState} from "./share-state";
import FreePracticeSteps from "./free-practice-steps";
import FreePracticeButtons from "./free-practice-buttons";
import "./tutor-free-practice-preview.css";

const goalUrlBase = 'https://tutor.completemaths.com';

const hasValue = (v) => {
   if (v === '' || v === undefined) return false;
   if (typeof v === 'string' || typeof v === 'number' || typeof v === 'boolean')
      return true;
   if (Object.keys(v ?? {}).length) return Object.values(v).every(hasValue);
   return Array.isArray(v);
};

const FreePracticeInner = ({ questionSteps = [], _results, views, controls, typeRenderers, ...state }) => {
   const snapshotDispatch = useSnapshotDispatch();
   const [mode, setMode] = useState('incomplete');

   const isMode = useCallback((...modes) => modes.includes(mode), [mode]);
   const hasResults = isMode('correct', 'incorrect', 'revealed');

   const initialShared = { _generatorConfig: [], _generatorIsRandom: true };
   const sharedState = useSharableState(initialShared, state._snapshotId);

   // _results has one entry for each Input View.
   const inputs = Object.values(_results);
   const isComplete = inputs.every((r) => hasValue(r.submitted));

   // When a user interacts with an Input View,
   // they update state in the enclosing snapshot directly,
   // not via an event handler in this component.
   // We need to check if 'isComplete' has changed.
   useEffect(() => {
      if (mode === 'incomplete' && isComplete) setMode('complete');
   }, [mode, isComplete]);

   const handleShowAnswers = () => {
      // Put the correct answers into the state fields that hold the user's answers.
      // But, make a note of whether the user already had the correct answer.
      const patch = inputs.reduce(
         (soFar, { answerKey, answer, itemKey, isCorrect }) => ({
            ...soFar,
            [answerKey]: answer,
            [`_results.${itemKey}.wasCorrect`]: isCorrect,
         }),
         {}
      );
      sharedState.setState(patch);
      setMode(isMode('correct', 'incorrect') ? 'revealed_checked' : 'revealed');
   };

   const handleRefresh = () => {
      snapshotDispatch('refresh', state._snapshotId);
      setMode('incomplete');
   };

   const handleCheckAnswer = () => {
      const isCorrect = inputs.every((r) => r.isCorrect);
      setMode(isCorrect ? 'correct' : 'incorrect');
   };

   const handleTryAgain = () => {
      setMode('complete');
   };

   const hasItems = questionSteps.filter((qs) => qs.items?.length).length > 0;

   return !hasItems ? null : (
      <div className="tutor-free-practice-preview">
         {questionSteps.length > 0 && (
            <FreePracticeSteps
               steps={questionSteps}
               state={state}
               results={_results}
               revealed={isMode('revealed', 'revealed_checked')}
               checked={isMode('correct', 'incorrect', 'revealed_checked')}
               views={views}
               controls={controls}
               typeRenderers={typeRenderers}
               disabled={hasResults}
            />
         )}

        <FreePracticeButtons
          mode={mode}
          handleRefresh={handleRefresh}
          handleCheckAnswer={handleCheckAnswer}
          handleShowAnswers={handleShowAnswers}
          handleTryAgain={handleTryAgain}
        />
      </div>
   );
};

const TutorFreePracticePreview = ({questionType}) => {
   const questionTypeData = useQuestionType(questionType._id) ?? {};
   const generator = useSpec('generators', questionType.generatorId);
   const [searchParams, setSearchParams] = useSearchParams();

   if (!questionType || !generator) return null;

   // We either preview the question or the generated values.
   // expecting '?preview=question' or '?preview=values'
   const previewMode = searchParams.get('preview') ?? 'question';

   // Fallback to empty arrays for the expected specs.
   const {
      views = [],
      controls = [],
      typeRenderers = [],
      generators = [],
      valueTypes = [],
      valueFunctions = [],
   } = questionTypeData;

   // Add extra values to the generator spec for nested generators and question steps.
   const snapshotGenerator = hydrateNestedGenerators(
      questionTypeGenerator(questionType, generator),
      generators,
   );

   // Snapshots expects objects but we have arrays.
   // There are some core value specs needed to get things going.
   const valueTypeSpecs = Object.fromEntries(
     (valueTypes.concat(...coreValueTypes)).map((v) => [v._id, v])
   );
   const genFunctionSpecs = Object.fromEntries(
     (valueFunctions.concat(...coreValueFunctions)).map((v) => [v._id, v])
   );

   return (
      <Suspense fallback={null}>
         <div className="tutor-free-practice-wrapper">
            <h3>
               <Select value={previewMode} onChange={(e) => setSearchParams({preview: e.target.value})}>
                  <option value="question">Question Preview</option>
                  <option value="values">Generated Values</option>
               </Select>
            </h3>

            <Snapshots
               spec={snapshotGenerator}
               valueTypeSpecs={valueTypeSpecs}
               genFunctionSpecs={genFunctionSpecs}
            >
               <ErrorBoundary fallback={<p>There was a problem generating the preview</p>}>
                  {previewMode === 'question' ? (
                    <ShareStateProps>
                       <FreePracticeInner
                         views={views}
                         controls={controls}
                         typeRenderers={typeRenderers}
                       />
                    </ShareStateProps>
                  ) : (
                    <GeneratedValues />
                  )}
                  <SnapshotsButtonRefresh/>
               </ErrorBoundary>
            </Snapshots>

            <div>
               <Link
                  to={`${goalUrlBase}/goals/${questionType.goalIds[0]}`}
                  title="Visit the Goal Page on TUTOR"
               >
                  Preview on TUTOR
               </Link>
            </div>
         </div>
      </Suspense>
   );
};

export default TutorFreePracticePreview;
