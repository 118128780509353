import {getObjectProperty} from "@lasalle/rich-content-utils/objects/object-properties";

const getViewPropsFromConfig = (specViewProps = [], item, state) =>
  specViewProps.reduce((soFar, viewProp) => {

    // First try the item's viewPropConfig object.
    const itemPropConfig = item.viewPropConfig?.[viewProp.id];

    if (itemPropConfig) {
      const configValue = itemPropConfig.type === 'literal'
        ? itemPropConfig.value
        : getObjectProperty(state, itemPropConfig.value);

      return {
        ...soFar,
        [viewProp.name]: configValue ?? viewProp.defaultValue,
      };
    }

    // Next, fall back to the item's viewPropValues object.
    // We'll try to phase out this method.
    const propValue = item.viewPropValues?.[viewProp.id];

    if (propValue === undefined) return soFar;

    // propValue either specifies the generator value name that holds the prop,
    // or the literal value to use.
    return {...soFar, [viewProp.name]: getObjectProperty(state, propValue) ?? propValue};
  }, {});

export default getViewPropsFromConfig;
