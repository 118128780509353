import {useState} from "react";
import {removeWhiteSpace} from "@lasalle/rich-content-utils/text/text";
import TextBox from "components/shared/text-box/text-box";

const RuleExpressionList = ({title, ruleName, ruleValue, updateValue}) => {
   const [text, setText] = useState(ruleValue);

   const handleBlur = () => {
      const valueArray = removeWhiteSpace(text).split(',');
      updateValue(ruleName, valueArray);
   };

   return (
      <tr>
         <th>{title}</th>
         <td>
            <TextBox
               name={ruleName}
               value={text}
               onChange={(e) => setText(e.target.value)}
               onBlur={handleBlur}
            />
         </td>
      </tr>
   );
};

export default RuleExpressionList;
