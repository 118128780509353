import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Select from "components/shared/select/select";
import {AddButton} from "components/shared/buttons/buttons";
import TextStep from "./text-step";
import InputViewStep from "./input-view-step";
import {Rule, RuleControls, RuleDetails, RuleHeader} from "../rule/rule";
import OutputViewStep from "./output-view-step";
import ManipulativeStep from "./manipulative-step";

const RuleQuestionSteps = ({ title, ruleName, ruleValue, views, updateValue }) => {
   const [steps, setSteps] = useState(ruleValue || []);
   const [stepType, setStepType] = useState('string');

   const handleChange = (i, value) => {
      steps[i] = value;
      setSteps([...steps]);
      updateValue(ruleName, steps);
   };

   const handleAddStep = () => {
      steps.push({ type: stepType });
      setSteps([...steps]);
      updateValue(ruleName, steps);
   };

   const handleRemoveStep = (i) => {
      steps.splice(i, 1);
      setSteps([...steps]);
      updateValue(ruleName, steps);
   };

   const handleMoveStep = (fromIndex, toIndex) => {
      const spliced = steps.splice(fromIndex, 1);
      console.log({fromIndex, toIndex, spliced});
      steps.splice(toIndex, 0, ...spliced);
      setSteps([...steps]);
      updateValue(ruleName, steps);
      console.log({steps});
   };

   return (
      <tr>
         <td colSpan={2}>
            <Rule>
               <RuleHeader>{title}</RuleHeader>
               <RuleDetails>
                  <div className="question-steps">
                     {steps ? (
                        <ol>
                           {steps.map((step, i) => {
                              switch(step.type) {
                                 case 'input-view':
                                 case 'view':
                                    return (
                                       <li key={i}>
                                          <InputViewStep
                                             index={i + 1}
                                             setStepValue={(value) => handleChange(i, value)}
                                             removeStep={() => handleRemoveStep(i)}
                                             moveStep={(toIndex) => handleMoveStep(i, toIndex)}
                                             step={step}
                                             numSteps={steps.length}
                                             views={views}
                                             key={i}
                                          />
                                       </li>
                                    );
                                 case 'manipulative':
                                    return (
                                       <li key={i}>
                                          <ManipulativeStep
                                             index={i + 1}
                                             setStepValue={(value) => handleChange(i, value)}
                                             removeStep={() => handleRemoveStep(i)}
                                             moveStep={(toIndex) => handleMoveStep(i, toIndex)}
                                             step={step}
                                             numSteps={steps.length}
                                             views={views}
                                             key={i}
                                          />
                                       </li>
                                    );
                                 case 'output-view':
                                    return (
                                       <li key={i}>
                                          <OutputViewStep
                                             index={i + 1}
                                             setStepValue={(value) => handleChange(i, value)}
                                             removeStep={() => handleRemoveStep(i)}
                                             moveStep={(toIndex) => handleMoveStep(i, toIndex)}
                                             step={step}
                                             numSteps={steps.length}
                                             views={views}
                                             key={i}
                                          />
                                       </li>
                                    );
                                 default:
                                    return (
                                       <li key={i}>
                                          <TextStep
                                             index={i + 1}
                                             setStepValue={(value) => handleChange(i, value)}
                                             removeStep={() => handleRemoveStep(i)}
                                             moveStep={(toIndex) => handleMoveStep(i, toIndex)}
                                             step={step}
                                             numSteps={steps.length}
                                             key={i}
                                          />
                                       </li>
                                    );
                              }
                           })}
                        </ol>
                     ) : null}
                  </div>
               </RuleDetails>
               <RuleControls>
                  <Select value={stepType} onChange={(e) => setStepType(e.target.value)}>
                     <option value="input-view">Input View</option>
                     <option value="manipulative">Manipulative</option>
                     <option value="output-view">Output View</option>
                     <option value="string">Text</option>
                  </Select>{' '}
                  <AddButton onClick={handleAddStep}>
                     <FontAwesomeIcon icon={faPlus} /> Add Step
                  </AddButton>
               </RuleControls>
            </Rule>
         </td>
      </tr>
   );
};

export default RuleQuestionSteps;
