import {useSpec, useSpecDepCounts} from "api";
import SectionNavBasic from "./section-nav-basic";

const SectionNavLoader = ({ specType, specId }) => {
  const { _status, goalIds, tags } = useSpec(specType, specId) ?? {};
  const numDeps = useSpecDepCounts(specType, specId);

  return (
    <SectionNavBasic
      specType={specType}
      specId={specId}
      status={_status}
      numGoals={goalIds?.length ?? 0}
      numTags={tags?.length ?? 0}
      numDeps={numDeps.count}
    />
  );
};

export default SectionNavLoader;
