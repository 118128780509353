import {Suspense, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {upperFirst} from "@lasalle/rich-content-utils/text/text";
import {useGetGoalById, useUpdateSpec} from "api";
import {AddButton, DeleteButton} from "components/shared/buttons/buttons";
import TextBox from "components/shared/text-box/text-box";
import Table from "components/shared/table/table";

// const goalUrlBase = 'https://tutor.completemaths.com';
const goalUrlBase = 'https://tutor-preview-664.completemaths.com';

const GoalAddButton = ({ goalId, linkedGoals = {}, handleAdd }) => {
   const goals = useGetGoalById(goalId);
   const goal = goals[goalId];

   if (goalId && !goal.name) return (
     <p>No Goal was found for the specified id.</p>
   );

   if (linkedGoals[goalId]) return (
     <p>The Goal for the specified id is already linked to this spec.</p>
   );

   return goal?.name ? (
     <>
        <p>{goal.name}</p>
        <AddButton onClick={handleAdd} withIcon>
           {' '}Link to Goal
        </AddButton>
     </>
   ) : null;
};

const CurriculumInfo = ({ curriculumLinks, canEdit }) => {
   const {specType, specId} = useParams();
   const {addGoalId, removeGoalId} = useUpdateSpec(specType, specId);
   const [newGoalId, setNewGoalId] = useState('');

   const handleGoalText = (e) => {
      const { value } = e.target;
      const goalText = value.trim();
      setNewGoalId(goalText);
   };

   const handleAdd = () => {
      addGoalId(newGoalId);
      setNewGoalId('');
   };

   return (
      <div className="spec-tags">
         <h3>Curriculum Links</h3>
         <Table>
            <thead>
            <tr>
               <th>Name</th>
               <th>Type</th>
               {canEdit && <th>Delete</th>}
            </tr>
            </thead>
            <tbody>
            {Object.entries(curriculumLinks).map(([linkType, links]) => Object.keys(links).length > 0 && Object.values(links).map((goal) => (
                <tr key={goal._id}>
                   <td>
                      <Link
                        to={`${goalUrlBase}/goals/${goal._id}`}
                        title="Visit the Goal Page on TUTOR"
                      >
                         {goal?.name ?? goal._id}
                      </Link>
                   </td>
                   <td>{upperFirst(linkType).slice(0, -1)}</td>
                   {canEdit && (
                     <td>
                        <DeleteButton onClick={() => removeGoalId(goal._id)} withIcon/>
                     </td>
                   )}
                </tr>
            )))}
            </tbody>
         </Table>

         {canEdit && (
            <div className="curriculum-controls">
               <h4>Link Spec</h4>
               <p>Enter a goal id to link this spec to that goal.</p>
               <p>
                  <TextBox
                     variant="longer"
                     value={newGoalId}
                     onChange={handleGoalText}
                  />
                  <Suspense fallback={<p>Checking goal details...</p>}>
                     <GoalAddButton
                       goalId={newGoalId}
                       linkedGoals={curriculumLinks.goals}
                       handleAdd={handleAdd}
                     />
                  </Suspense>
               </p>
            </div>

         )}
      </div>
   );
};

export default CurriculumInfo;
