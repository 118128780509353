import {useSnapshotDispatch} from "../../snapshots/snapshots";
import {Button} from "../../shared/buttons/buttons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faObjectUngroup} from "@fortawesome/free-solid-svg-icons";

const Split = ({ itemsField, optionsField, filterField, _snapshotId, ...state }) => {
   const dispatch = useSnapshotDispatch();

   const [prop1, prop2] = itemsField.split('.');
   const items = (state[prop1]?.[prop2] || state[prop1]) ?? [];

   const [prop3, prop4] = optionsField.split('.');
   const options = (state[prop3]?.[prop4] || state[prop3]) ?? [];

   const filter2 = filterField.split('.')[1];
   const selected = items.filter((s) => s[filter2 ?? filterField]);

   const handleExchange = () => {
      let itemsToUpdate = [...items];
      for (const item of selected) {
         const index = itemsToUpdate.findIndex((s) => s === item);
         const exchangeMap = options.find((option) => option.name === item.type);

         if (exchangeMap) {
            const exchangeTarget = prop4 ? state[prop3][exchangeMap.value] : state[exchangeMap.value];
            if (exchangeTarget) {
               itemsToUpdate.splice(index, 1);
               itemsToUpdate = itemsToUpdate.concat(exchangeTarget.map((v) => ({
                  ...v,
                  x: v.x + item.x,
                  y: v.y + item.y,
                  group: prop2 ? `${prop1}.${v.group}` : v.group,
               })));

               if (prop2) {
                  dispatch('update', _snapshotId, {
                     [prop1]: {
                        ...state[prop1],
                        [prop2]: itemsToUpdate,
                     },
                  }, true);
               } else {
                  dispatch('update', _snapshotId, {
                     [prop1]: itemsToUpdate,
                  }, true);
               }
            }
         }
      }
   }

   return (
      <div className="exchange">
         <Button disabled={!selected?.length} onClick={handleExchange}>
            <FontAwesomeIcon icon={faObjectUngroup} />&nbsp;Split
         </Button>
      </div>
   );
};

export default Split;
