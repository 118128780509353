import {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import {upperFirst} from '@lasalle/rich-content-utils/text/text';
import {useSnapshotDispatch, useSnapshotInfo} from 'components/snapshots/snapshots';

export const ShareStateContext = createContext();

const ShareState = ({ children, _snapshotId }) => {
  const [state, setState] = useState({});
  const sharedState = useMemo(
    () => [{ ...state, _snapshotId }, setState],
    [state, _snapshotId, setState]
  );

  return (
    <ShareStateContext.Provider value={sharedState}>
      {children}
    </ShareStateContext.Provider>
  );
};

export default ShareState;

export const useSharedState = () => {
  return useContext(ShareStateContext);
};

// State could be in useState, spec-info state context, or snapshot context.
export const useSharableState = (initial, id = 'shared') => {
  const [initialState] = useState(initial);
  // Standalone components not nested in ShareState use useState
  const localStateAndSetter = useState({ [id]: initialState });

  // Components in a ShareState component use the context.
  // If there's no context, they are standalone.
  const [sharedState, setSharedState] =
    useContext(ShareStateContext) ?? localStateAndSetter;

  // If there's spec-info snapshot state, that takes precedence.
  const { snapshot, snapshots = [] } = useSnapshotInfo() ?? {};
  const dispatch = useSnapshotDispatch();

  // 1. inside a repeater and _snapshotId is set in state
  // 2. snapshot exists but not in repeater
  // 3. no snapshots but in spec-info state
  // 4. standalone component

  const stateId = sharedState._snapshotId ?? snapshot?._snapshotId ?? id;
  const state =
    snapshots.find((ss) => ss._snapshotId === stateId) ?? sharedState[stateId];

  const stateKeys = Object.keys(state ?? {});
  const initialKeys = Object.keys(initialState ?? {});

  const setState = useCallback(
    (newState) => {
      if (state?._snapshotId) {
        dispatch('update', state?._snapshotId, {
          ...newState,
        });
      } else {
        setSharedState((s) => ({ ...s, [id]: newState }));
      }
    },
    [setSharedState, id, dispatch, state?._snapshotId]
  );

  useEffect(() => {
    if (state) {
      if (initialKeys.some((k) => !stateKeys.includes(k))) {
        setState({ ...initialState });
      }
    }
  }, [id, setState, stateKeys, initialState, initialKeys, state]);

  const setters = initialKeys.concat(stateKeys).reduce((soFar, key) => {
    // eslint-disable-next-line
    soFar[`set${upperFirst(key)}`] = (value) => {
      setState({ ...state, [key]: value });
    };

    return soFar;
  }, {});

  return {
    state,
    setState,
    ...state,
    ...setters,
  };
};
