import {Link} from "react-router-dom";
import {useSpecDeps} from "api";
import getSpecTitle from "lib/specs/get-spec-title";

const sortTypes = (a, b) => a[0].localeCompare(b[0]);
const sortSpecs = (a, b) => a.name.localeCompare(b.name);

const SpecUsageList = ({ specType, specId }) => {
   const { specs } = useSpecDeps(specType, specId) ?? { specs: {} };
   return (
      <ul className="spec-usage-list">
         {Object.entries(specs).sort(sortTypes).map(([specDepType, specsInType]) => {
            return (
               <li key={specDepType} className="spec-usage-list-group">
                  <h4>{getSpecTitle(specDepType)}</h4>
                  <ul className="spec-usage-list-item">
                     {specsInType.sort(sortSpecs).map(({ _id, name }) => (
                        <li key={_id}>
                           <Link to={`/specs/${specDepType}/${_id}`}>{name}</Link>
                        </li>
                     ))}
                  </ul>
               </li>
            )
         })}
      </ul>
   );
};

export default SpecUsageList;
