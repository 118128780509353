import {useEffect, useRef, useState} from "react";
import {typeIds} from "app-constants/specs";
import TextBox from "components/shared/text-box/text-box";
import Select from "components/shared/select/select";
import {useUser} from "store";
import TypePicker from "./type-picker";

const nameSort = (a, b) => a.name.localeCompare(b.name);
const debounceDelay = 800;
const {FOLDER_TYPE_ID} = typeIds;

const NameAndType = ({
   valueName,
   handleUpdateValueName,
   handleUpdateList,
   handleSetValueType,
   valueTypes,
   typesInUse,
   type,
   list,
   isLocked,
}) => {
   const {isAdmin} = useUser();
   const [newName, setNewName] = useState(valueName ?? '');
   const [showTypes, setShowTypes] = useState(false);
   const typeDetails = typeof type === 'string'
      ? valueTypes.find((vt) => vt._id === type)
      : type;
   const timeoutRef = useRef(0);

   const handleToggleList = () => {
      if (!list?.length) handleUpdateList({length: '1'});
      else handleUpdateList();
   };

   const handleSelectType = (typeId) => {
      if (typeId === 'Type Picker') setShowTypes((v) => !v);
      else {
         handleSetValueType(typeId);
         setShowTypes(false);
      }
   };

   useEffect(() => {
      const trimmed = newName.trim();
      if (trimmed && trimmed !== valueName) {
         timeoutRef.current = setTimeout(() => {
            handleUpdateValueName(trimmed);
         }, debounceDelay);
      }
      return () => {
         if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = 0;
         }
      };
   }, [newName, timeoutRef, handleUpdateValueName, valueName]);

   const typeGroups = valueTypes.reduce((soFar, type) => {
      const { tags = [], _status } = type;
         for (const tag of tags) {
            if ((isAdmin || _status === 'published') && tag !== 'Hidden') {
               soFar[tag] ??= [];
               soFar[tag].push(type);
            }
         }
      return soFar;
   }, {});

   const commonTypes = [
      ...new Set(typeGroups.Common.concat(valueTypes.filter((vt) => typesInUse.includes(vt._id))))
   ];

   const classNames = ['value-name-and-type'];
   if (type === FOLDER_TYPE_ID) classNames.push('type-folder');

   return typeDetails ? (
      <>
         <table className={classNames.join(' ')}>
            <tbody>
            <tr className="value-details-name">
               <th>Name</th>
               <td colSpan={2}>
                  <TextBox
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    disabled={isLocked}
                  />
               </td>
            </tr>
            <tr>
               <th>Type</th>
               {type === FOLDER_TYPE_ID ? (
                  <td colSpan={2}>Folder</td>
               ) : (
                  <>
                     <td>
                        <Select
                          value={typeDetails._id}
                          onChange={(e) => handleSelectType(e.target.value)}
                          disabled={isLocked}
                        >
                           {commonTypes.sort(nameSort).map((valueType) => (
                              <option key={valueType._id} value={valueType._id}>{valueType.name}</option>
                           ))}
                           <hr />
                           <option value="Type Picker">
                              {showTypes ? 'Close ' : 'Open '}
                              Type Picker
                           </option>
                        </Select>
                     </td>
                     <td>
                        <label>
                           <input
                              type="checkbox"
                              onChange={handleToggleList}
                              checked={!!list?.length}
                              disabled={isLocked}
                           />{' '}
                           List
                        </label>
                     </td>
                  </>
               )}
            </tr>
            </tbody>
         </table>
         {showTypes && (
            <TypePicker
               typeGroups={typeGroups}
               selectedType={typeDetails._id}
               onPick={handleSelectType}
            />
         )}
      </>
   ) : null;
};

export default NameAndType;
