export const Rule = ({ children }) => {
   return (
      <div className="value-rule">
         {children}
      </div>
   );
};

export const RuleHeader = ({ children }) => {
   return (
      <div className="value-rule-header">
         {children}
      </div>
   );
};

export const RuleDetails = ({ children }) => {
   return (
      <div className="value-rule-details">
         {children}
      </div>
   );
};

export const RuleControls = ({ children }) => {
   return (
      <div className="value-rule-controls">
         {children}
      </div>
   );
};
