import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faInfoCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import {upperFirst} from "@lasalle/rich-content-utils/text/text";
import {useSpecs} from "api";
import useUrlSearchParams from "lib/hooks/use-url-search-params";
import getSpecTitle from "lib/specs/get-spec-title";
import Table from "components/shared/table/table";
import Paginator from "./paginator";
import usePaginator from "./use-paginator";
import "./specs-list-table.css";

const SpecsListTable = ({specType, isLoadingData, startLoadingData}) => {
   const {queryObject} = useUrlSearchParams();
   const {data: specs, count = 0} = useSpecs(specType, {page: 1, perPage: 20, ...queryObject}) ?? {data: []};
   const {pagination} = usePaginator(specs, count);

   return (
      <div className="spec-list-wrapper">
         {specs.length ? (
            <>
               <Paginator
                  {...pagination}
                  isLoadingData={isLoadingData}
                  startLoadingData={startLoadingData}
               />
               <Table isLoadingData={isLoadingData}>
                  <thead>
                  <tr>
                     <th>{getSpecTitle(specType)}</th>
                     <th>Description</th>
                     <th>Status</th>
                     <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  {specs.map(({_id: specId, name, description, _status, _createdBy, _updatedBy, _updatedAt}) => {
                     return(
                       <tr key={specId}>
                          <td>
                            <Link
                              to={`${specId}`}
                              className="spec-name"
                              title={`Go to editor for ${name}`}
                            >
                               {name}
                            </Link>
                          </td>
                          <td>
                             {description}
                          </td>
                          <td>
                             {upperFirst(_status)}
                          </td>
                          <td className="specs-list-actions">
                             <p>
                                <span>
                                   <Link
                                     to={`${specId}/info`}
                                     title={`See spec info for ${name}`}
                                   >
                                      <FontAwesomeIcon icon={faInfoCircle}/>
                                   </Link>
                                </span>
                                <span>
                                   <Link
                                     to={`${specId}/info/copy`}
                                     title={`Copy the ${name} spec`}
                                   >
                                      <FontAwesomeIcon icon={faCopy}/>
                                   </Link>
                                </span>
                                <span>
                                   <Link
                                     to={`${specId}/info/delete`}
                                     title={`Delete the ${name} spec`}
                                   >
                                      <FontAwesomeIcon icon={faTrash}/>
                                   </Link>
                                </span>
                             </p>
                          </td>
                       </tr>
                     )
                  })}
                  </tbody>
               </Table>
            </>
         ) : (
           <p className="no-items">There are no {specType} to show.</p>
         )}
      </div>
   );
};

export default SpecsListTable;
