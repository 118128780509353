import {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAddQuestionType} from "api/question-types";
import {useGetGoalById} from "api";
import Label from "components/shared/label/label";
import TextBox from "components/shared/text-box/text-box";
import TextArea from "components/shared/text-area/text-area";
import {AddButton} from "components/shared/buttons/buttons";

const CreateQuestionType = () => {
  const {goalId} = useParams();
  const navigate = useNavigate();
  const goals = useGetGoalById(goalId);
  const addQuestionType = useAddQuestionType();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [navToQT, setNavToQT] = useState(true);

  const handleAdd = async () => {
    const trimmedName = name.trim();
    const trimmedDescription = description.trim();
    if (trimmedName) {
      const response = await addQuestionType(trimmedName, trimmedDescription, goalId, true);
      navigate(navToQT ? `/specs/question-types/${response.questionTypeResult.insertedId}` : `/assignments/${goalId}`);
    }
  };

  return (
    <>
      <div>
        <h3>New Question Type</h3>
        <p>Create a new Question Type with an associated Generator, linked to the selected Goal.</p>

        <p><Label>Name</Label></p>
        <p><TextBox variant="longest" value={name} onChange={(e) => setName(e.target.value)}/></p>

        <p><Label>Description</Label></p>
        <p><TextArea value={description} onChange={(e) => setDescription(e.target.value)}/></p>

        <p><Label>Goal</Label></p>
        <p>{goals[goalId]?.name}</p>

        <p>
          <Label>
            <input
              type="checkbox"
              checked={navToQT}
              onChange={() => setNavToQT((v) => !v)}
            />{' '}
            Navigate to new Question Type
          </Label>
        </p>

        <p>
          <AddButton withIcon onClick={handleAdd}>
            {' '}Create Question Type
          </AddButton>
        </p>
      </div>
      <div></div>
    </>
  );
};

export default CreateQuestionType;
