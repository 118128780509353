import SignOutButton from "../shared/sign-out-button/sign-out-button";

const SignOutMenu = () => {
  return (
    <ul className="icon-menu">
      <li>
        <SignOutButton />
      </li>
    </ul>
  );
};

export default SignOutMenu;
