import {useParams} from "react-router-dom";
import {useUsersByRole} from "api";

const EditorDetails = () => {
  const editors = useUsersByRole('rich_content_editor');
  const { editorId } = useParams();
  const editor = editors.find((ed) => ed._id === editorId);
  return (
    <p>{editor.firstName} {editor.lastName} ({editor.username})</p>
  );
};

export default EditorDetails;
