const RuleBoolean = ({ title, ruleName, ruleValue, updateValue }) => {
    const handleToggle = (e) => {
        const { checked } = e.target;
        updateValue(ruleName, checked);
    };

    return (
        <tr>
            <th>{title}</th>
            <td>
                <input
                    type="checkbox"
                    name={ruleName}
                    checked={ruleValue}
                    onChange={handleToggle}
                />
            </td>
        </tr>
    );
};

export default RuleBoolean;
