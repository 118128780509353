import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import icons from "app-constants/icons";

const HomeMenu = () => {
  return (
    <ul className="icon-menu">
      <li>
        <NavLink to="/" title="Home">
          <FontAwesomeIcon icon={icons.home}/>
        </NavLink>
      </li>
    </ul>
  );
};

export default HomeMenu;
