import {useRef, useEffect} from "react";

const DialogModal = ({children, onClose, className}) => {
   const ref = useRef();

   const handleClose = () => {
      const dialog = ref.current;
      onClose(dialog.returnValue);
   };

   useEffect(() => {
      const dialog = ref.current;
      dialog.showModal();
      return () => dialog.close();
   }, [onClose]);

   const classes = ['lsl-dialog-modal'];
   if (className) classes.push(className);

   return (
      <dialog ref={ref} onClose={handleClose} className={classes.join(' ')}>
         {children}
      </dialog>
   );
};

export default DialogModal;
