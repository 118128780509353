export const EDITOR_ROLES = [
  'rich_content_editor',
   'rich_content_admin',
];

export const ADMIN_ROLES = [
   'rich_content_admin',
];

export const AUTH_CONFIG = {
  AUTH_URL: process.env.REACT_APP_AUTH_APP_URL,
  SUBSCRIBE_AUTH_STATE: 'SUBSCRIBE_AUTH_STATE',
  AUTH_STATE_UPDATED: 'AUTH_STATE_UPDATED',
};
