import {useSnapshotDispatch, useSnapshotInfo} from "./snapshots";
import {useRef} from "react";

export const SnapshotsButton = ({ type, children }) => {
  const dispatch = useSnapshotDispatch();
  const { snapshotId } = useSnapshotInfo();
  return (
    <button
      className={`snapshot-btn snapshot-btn-${type}`}
      onClick={() => dispatch(type, snapshotId)}
    >
      {children}
    </button>
  );
};

export const SnapshotsButtonCopy = ({ children }) => (
  <SnapshotsButton type="copy">
    {children || "Copy"}
  </SnapshotsButton>
);

export const SnapshotsButtonDelete = ({ children }) => (
    <SnapshotsButton type="delete">
      {children || "Delete"}
    </SnapshotsButton>
);

export const SnapshotsButtonNew = ({ children }) => (
    <SnapshotsButton type="new">
      {children || "New"}
    </SnapshotsButton>
);

export const SnapshotsButtonNewSet = ({ hasNumber, children }) => {
  const dispatch = useSnapshotDispatch();
  const {snapshots} = useSnapshotInfo();
  const numRef = useRef(snapshots.length);

  const handleClick = () => {
    dispatch("newSet", numRef.current.value ?? snapshots.length);
  };

  return (
    <div className="snapshot-btn-wrapper">
      <button
          className="snapshot-btn snapshot-btn-newSet"
          onClick={handleClick}
      >
        {children || "New Set"}
      </button>
       {hasNumber && (
          <input
             type="number"
             defaultValue={snapshots.length}
             min={1}
             max={12}
             maxLength={2}
             ref={numRef}
          />
       )}
    </div>
  );
};

export const SnapshotsButtonNext = ({ children }) => (
    <SnapshotsButton type="next">
      {children || "Next"}
    </SnapshotsButton>
);

export const SnapshotsButtonPrev = ({ children }) => (
  <SnapshotsButton type="prev">
    {children || "Prev"}
  </SnapshotsButton>
);

export const SnapshotsButtonRefresh = ({ children }) => (
  <SnapshotsButton type="refresh">
    {children || "Refresh"}
  </SnapshotsButton>
);
