import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";

const GeneratorDetails = ({generator}) => {
   return generator._id ? (
      <div className="generator-details selected">
         <h3>
            {generator.name}
            <span>
               <a
                  href={`/generators/${generator._id}`}
                  target="_blank"
                  rel="noreferrer"
                  title="Open the Generator Spec in a new tab"
               >
                  Spec{' '}
                  <FontAwesomeIcon icon={faUpRightFromSquare} />
               </a>
            </span>
         </h3>
         {generator.description && <p>{generator.description}</p>}
      </div>
   ) : null;
};

export default GeneratorDetails;
