import TextItem from './text-item';
import InputItem from './input-item';
import OutputItem from './output-item';
import ControlsItem from "./controls-item";

const getKey = (i) => i;

const FreePracticeSteps = (props) => {
  const { steps, state, className, revealed, checked } = props;
  const { results = {}, views, typeRenderers, controls, disabled } = props;

  const itemLookups = {
    text: { Item: TextItem, specs: typeRenderers ?? [] },
    input: { Item: InputItem, specs: views ?? [] },
    output: { Item: OutputItem, specs: views ?? [] },
    controls: { Item: ControlsItem, specs: controls ?? [] },
  };

  return Array.isArray(steps) ? (
    <ol className={className}>
      {steps.map((step, stepNumber) => {
        return (
          <div className="question-viewer-step" key={getKey(stepNumber)}>
            {step.items.map((item, itemNumber) => {
              const { Item, specs } = itemLookups[item.type];
              const itemResult = {
                ...(results[`step_${stepNumber + 1}_item_${itemNumber + 1}`] ?? {})
              }
              itemResult.revealed = revealed;
              itemResult.checked = checked;
              return (
                <div className="question-viewer-item" key={getKey(itemNumber)}>
                  <Item
                    item={item}
                    state={state}
                    result={itemResult}
                    stepNumber={stepNumber}
                    itemNumber={itemNumber}
                    specs={specs}
                    disabled={disabled}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </ol>
  ) : null;
};

export default FreePracticeSteps;
