import {getObjectProperty, setObjectProperty} from "@lasalle/rich-content-utils/objects/object-properties";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoltLightning} from "@fortawesome/free-solid-svg-icons";
import {AddButton} from "../../shared/buttons/buttons";
import {useSnapshotDispatch} from "../../snapshots/snapshots";
import {useSharableState} from "../../share-state/share-state";


const TriggerButton = ({ label, itemField, triggerField, triggerValue, _snapshotId }) => {
   const state = useSharableState({}, _snapshotId);
   const dispatch = useSnapshotDispatch();

   const handleAnimate = () => {
    if (itemField && triggerField) {
      const currentItem = getObjectProperty(state, itemField);
      currentItem.triggerCount = (currentItem.triggerCount ?? 0) + 1;
      const updatedState = { [itemField]: setObjectProperty(currentItem, triggerField, triggerValue) };
      dispatch('update', _snapshotId, updatedState, true);
    }
   };

   return (
    <div className="animate-param">
      <label>
        <span>{label}</span>
        <AddButton onClick={handleAnimate}>
          <FontAwesomeIcon icon={faBoltLightning} />
        </AddButton>
      </label>
    </div>
   );
};

export default TriggerButton;
