import RuleBoolean from "./rule-boolean";
import RuleExpression from "./rule-expression";
import RuleExpressionList from "./rule-expression-list";
import RuleGenerator from "./rule-generator";
import RuleNameValueList from "./rule-name-value-list";
import RuleQuestionSteps from "./rule-question-steps/rule-question-steps";
import RuleSelect from "./rule-select";
import RuleTable from "./rule-table";
import RuleTextWithPlaceholders from "./rule-text-with-placeholders";
import RuleTextWithPlaceholdersList from "./rule-text-with-placeholders-list";
import RuleValueNamesList from "./rule-value-names-list";
import RuleLatexWithPlaceholders from "./rule-latex-with-placeholders/rule-latex-with-placeholders";

export const ruleTypesToComponents = {
    boolean: RuleBoolean,
    expressionList: RuleExpressionList,
    generator: RuleGenerator,
    integerExpression: RuleExpression,
    latexWithPlaceholders: RuleLatexWithPlaceholders,
    nameValueList: RuleNameValueList,
    questionSteps: RuleQuestionSteps,
    select: RuleSelect,
    table: RuleTable,
    textWithPlaceholders: RuleTextWithPlaceholders,
    textWithPlaceholdersArray: RuleTextWithPlaceholdersList,
    textWithPlaceholdersTable: RuleTable,
    tokenArray: RuleTextWithPlaceholders,
    valueNamesList: RuleValueNamesList,
};

export const ruleTypes = Object.keys(ruleTypesToComponents).sort();
