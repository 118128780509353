import {useEffect, useRef, useState} from "react";

const DEFAULT_DELAY = 600;
const DEFAULT_VALUE = undefined;

const useDebounce = (setterFn, initialValue = DEFAULT_VALUE, delay = DEFAULT_DELAY) => {
   const statePair = useState(initialValue);
   const [value] = statePair;
   const ref = useRef(JSON.stringify(value));

   useEffect(() => {
      let timeoutId;
      if (JSON.stringify(value) !== ref.current) {
         ref.current = JSON.stringify(value);
         timeoutId = setTimeout(() => setterFn(value), delay);
      }
      return () => clearTimeout(timeoutId);
   }, [value, delay, setterFn]);

   return statePair;
};

export default useDebounce;
