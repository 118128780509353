import {useState} from "react";
import Select from "../../shared/select/select";
import {AddButton} from "../../shared/buttons/buttons";
import {useSnapshotDispatch} from "../../snapshots/snapshots";

const SelectAndAdd = (selectAndAddProps) => {
   const { itemsField, optionsField, _snapshotId, ...state } = selectAndAddProps;
   const dispatch = useSnapshotDispatch();

   const [prop1, prop2] = optionsField.split('.');
   let options = state[prop1] ?? [];
   if (prop2) options = state[prop1]?.[prop2] ?? [];
   options.sort((a, b) => a.name.localeCompare(b.name));
   const [selectedOption, setSelectedOption] = useState(options[0]?.value ?? '');

   const handleAdd = (e) => {
      e.stopPropagation();
      const itemType = prop2 ? state[prop1][selectedOption] : state[selectedOption];
      const item = { ...itemType, group: itemsField };
      const [itemsField1, itemsField2] = itemsField.split('.');

      if (item) {
         if (itemsField2) {
            dispatch('update', _snapshotId, {
               [itemsField1]: {
                  ...state[itemsField1],
                  [itemsField2]: [...(state[itemsField1][itemsField2] ?? []), item],
               },
            }, true);

         } else {
            dispatch('update', _snapshotId, {
               [itemsField]: [...(state[itemsField] ?? []), item],
            }, true);
         }
      }
   };

   return (
      <div className="select-and-add">
         <Select value={selectedOption.value} onChange={(e) => setSelectedOption(e.target.value)}>
            {options.map(({name, value}) => (
               <option value={value} key={value}>{name}</option>
            ))}
         </Select>&nbsp;
         <AddButton onClick={handleAdd} withIcon />
      </div>
   );
};

export default SelectAndAdd;
