import {upperFirst} from "@lasalle/rich-content-utils/text/text";
import getSpecTitle from "lib/specs/get-spec-title";

const getDateString = (dateStamp) => {
  const [date, time] = new Date(dateStamp).toLocaleString().split(', ');
  return `on ${date} at ${time.slice(0, -3)}`;
};

const Overview = ({ spec, specType }) => {
   const {name, description, _status, _createdBy, _createdAt, _updatedBy, _updatedAt} = spec;
   return (
      <div className="info-section">
         <div>
            <h3>Name</h3>
            <p>{name}</p>
         </div>
         {description && (
            <div>
               <h3>Description</h3>
               <pre>{description}</pre>
            </div>
         )}
         <div>
            <h3>Type</h3>
            <p>{getSpecTitle(specType).slice(0, -1)}</p>
         </div>
         <div>
            <h3>Status</h3>
            <p>{upperFirst(_status)}</p>
         </div>
         <div>
            <h3>Created By</h3>
            <p>{`${_createdBy.firstName} ${_createdBy.lastName}`}, {getDateString(_createdAt)}.</p>
         </div>
         <div>
            <h3>Updated By</h3>
            <p>{`${_updatedBy.firstName} ${_updatedBy.lastName}`}, {getDateString(_updatedAt)}.</p>
         </div>
      </div>
   );
};

export default Overview;
