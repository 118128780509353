const getCoef = (coef, power, hideSign) => {
   const c = hideSign ? Math.abs(coef) : coef;
   if (power === 0) return c;
   return String(c)
      .replace(/^1$/, '')
      .replace(/^-1$/, '-');
};

const PolynomialTerm = ({ value: { coefficient, symbol, power }, hideSign = false}) => {
   if (coefficient === 0) return <span className="polynomial-term">0</span>;
   if (power === 0) return (
      <span className="polynomial-term">
         <span className="polynomial-coefficient">
            {getCoef(coefficient, power, hideSign)}
         </span>
      </span>
   );
   return (
      <span className="polynomial-term">
         <span className="polynomial-coefficient">
            {getCoef(coefficient, power, hideSign)}
         </span>
         <span className="polynomial-symbol">{symbol}</span>
         <span className="polynomial-power"><sup>{String(power).replace(/^1$/, '')}</sup></span>
      </span>
   );
};

export default PolynomialTerm;
