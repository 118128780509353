const JsonViewer = ({ spec }) => {
   return (
      <div className="info-section">
         <h3>Spec JSON</h3>
         <div className="code">
            <code>
               <pre>{JSON.stringify(spec, null, 2)}</pre>
            </code>
         </div>
      </div>
   );
};

export default JsonViewer;
