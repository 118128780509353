import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import getSpecTitle from "lib/specs/get-spec-title";
import icons from "app-constants/icons";
import NavItem from "components/nav/nav-item";
import NavSection from "components/nav/nav-section";

const SpecsNavSection = ({ specTypes }) => {
  return (
    <NavSection>
      {specTypes.map((specType) => (
        <NavItem to={`/specs/${specType}`} key={specType}>
          <span>
            <FontAwesomeIcon icon={icons[specType] ?? icons.default} />
            {getSpecTitle(specType)}
          </span>
        </NavItem>
      ))}
    </NavSection>
  );
};

export default SpecsNavSection;
