import {NavLink} from "react-router-dom";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "components/shared/buttons/buttons";

const SortableItem = (props) => {
   const {id, icon, itemTitle, stepType, isLocked} = props;
   const {specId, stepIndex, itemIndex} = props;
   const {
      attributes,
      listeners,
      setNodeRef,
      transform,
   } = useSortable({
      id,
      data: { stepType },
      disabled: isLocked,
   });

   const style = {
      transform: CSS.Transform.toString({
         x: 0,
         y: transform?.y,
         scaleX: 1,
         scaleY: 1
      }),
   };

   return (
     <li ref={setNodeRef} style={style} className="value-item sortable-nav-item">
        <div
          className="sortable-nav-item-wrapper"
          style={{'--folder-depth': 1}}
        >
           <span>
              <Button title="Drag to Move Item" {...attributes} {...listeners}>
                 <FontAwesomeIcon icon={icon}/>
              </Button>
              <NavLink
                to={`/specs/question-types/${specId}/question/${stepIndex + 1}/${itemIndex + 1}`}
                replace
              >
                 {itemTitle}
              </NavLink>
           </span>
        </div>
     </li>
);
};

export default SortableItem;
