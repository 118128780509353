import { useEffect, useRef, useCallback } from 'react';
import {AUTH_CONFIG} from '../app-constants';
import useStore, {useUser} from '../store';

const { setIframe, setJwt } = useStore.getState();
const { AUTH_URL, AUTH_STATE_UPDATED, SUBSCRIBE_AUTH_STATE } = AUTH_CONFIG;

const AuthIframe = () => {
  const iframeRef = useRef();
  const jwtRef = useRef(null);
  const {user, isEditor} = useUser();

  useEffect(() => {
    const iframeEl = iframeRef.current;
    if (iframeEl) {
      iframeEl.onload = () => {
        iframeEl.contentWindow.postMessage(
          { type: SUBSCRIBE_AUTH_STATE },
          AUTH_URL
        );
      };
      setIframe(iframeEl);
    }
  }, [iframeRef]);

  useEffect(() => {
    const onMessage = (e) => {
      if (e.source === window || e.origin !== AUTH_URL) return;
      if (e.data.type !== AUTH_STATE_UPDATED) return;

      const { jwt } = e.data;
      setJwt(jwt);
      jwtRef.current = jwt;

      if (!jwt || (user && !isEditor)) redirectToAuth();
    };
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, [user, isEditor]);

  return (
    <iframe
      ref={iframeRef}
      src={AUTH_URL}
      style={{ display: 'none' }}
      title="Complete Mathematics Auth"
      aria-hidden="true"
    />
  );
};

export default AuthIframe;

const redirectToAuth = () => {
  window.open(`${AUTH_URL}/login?fromProduct=rich-content-authoring`, '_self');
};

export function useLogOut() {
  const iframe = useStore((state) => state.iframe);

  return useCallback(() => {
    if (iframe) {
      iframe.contentWindow.postMessage({ type: 'LOG_OUT' }, AUTH_URL);
    }
  }, [iframe]);
}
