import {useState} from "react";
import {useSpec} from "api";
import Select from "components/shared/select/select";
import "./answer-value-editor.css";

const fields = {
   inputField: 'User Answer Value',
   answerCheckField: 'Answer Check Value',
};

const AnswerValueEditor = ({ item, generatorId, updateItem}) => {
   const generator = useSpec('generators', generatorId) ?? {values: []};
   const [hasCheck, setHasCheck] =
     useState(item.answerCheckField !== undefined);

   const values = generator.values.map((v) => {
      const parent = generator.values.find((val) => val.id === v.parentId);
      v.namePath = parent ? `${parent.namePath}.${v.name}` : v.name;
      return v;
   });

   const handleChange = (e) => {
      const { name, value } = e.target;
      updateItem({ [name]: value })
   };

   const handleHasCheckSwitch = () => {
      if (hasCheck) {
         updateItem({ answerCheckField: undefined, inputField: undefined});
      }
      setHasCheck((v) => !v);
   };

   return (
      <div className="answer-value-editor">
         <h4>Input Answer Values</h4>
         <p>
            The Question Type needs to know which Generator Value to use as the correct answer
            for this input and how to decide if the user's answer is correct.
         </p>
         <div>
            <p>
               Specify the Value that holds the correct answer,
               or an example of a correct answer if there are multiple
               possible answers.
            </p>
            <table className="editor-table">
               <tbody>
               <tr>
                  <td>Correct Answer Value</td>
                  <td>
                     <Select
                       name="answerField"
                       value={item.answerField ?? ''}
                       onChange={handleChange}
                     >
                        <option value="">----</option>
                        {values.map((v) => (
                          <option key={v.namePath} value={v.namePath}>{v.namePath}</option>
                        ))}
                     </Select>
                  </td>
               </tr>
               </tbody>
            </table>
         </div>
         <div>
            <label>
               <input
                 type="checkbox"
                 checked={hasCheck}
                 onChange={handleHasCheckSwitch}
               />{' '}
               Optional Manual Answer Check:
            </label>
            <fieldset disabled={!hasCheck}>
               <p>
                  Sometimes the Generator needs to do some work to
                  determine if the user's answer is correct.
               </p>
               <p>
                  Specify the Value where the user's answer will be saved
                  and the true/false Value that reports if they are correct.
               </p>
               <table className="editor-table">
                  <tbody>
                  {Object.entries(fields).map(([field, title]) => (
                    <tr key={field}>
                       <td>{title}</td>
                       <td>
                          <Select
                            name={field}
                            value={item[field] ?? ''}
                            onChange={handleChange}
                          >
                             <option value="">----</option>
                             {values.map((v) => (
                               <option key={v.namePath} value={v.namePath}>{v.namePath}</option>
                             ))}
                          </Select>
                       </td>
                    </tr>
                  ))}
                  </tbody>
               </table>
            </fieldset>
         </div>
      </div>
   );
};

export default AnswerValueEditor;
