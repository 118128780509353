import {useSearchParams} from "react-router-dom";

const UseUrlSearchParams = () => {
   const [searchParams, setRouterSearchParams] = useSearchParams();

   const setSearchParams = (params) => {
      const paramsToSet = Object.fromEntries([...searchParams]);
      for (const [key, value] of Object.entries(params)) {
         if (value === undefined) {
            delete paramsToSet[key];
         } else {
            paramsToSet[key] = value;
         }
      }
      setRouterSearchParams(paramsToSet);
   };

   const queryString = searchParams.size
       ? '?' + [...searchParams].map(([key, value]) => `${key}=${value}`).join('&')
       : ''

   const queryObject = [...searchParams].reduce((soFar, [key, value]) => {
      soFar[key] = value;
      return soFar;
   }, {});

   return {searchParams, queryString, queryObject, setSearchParams};
};

export default UseUrlSearchParams;
