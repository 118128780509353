import {useState} from "react";
import DialogModal from "components/shared/dialog-modal/dialog-modal";
import {Button} from "components/shared/buttons/buttons";
import Select from "components/shared/select/select";

const nameSort = (a, b) => a.name.localeCompare(b.name);

const ManipulativeEditor = ({item, stepIndex, itemIndex, manipulatives, onClose}) => {
   const manipulativesToShow = Object.values(manipulatives).sort(nameSort);
   const [manipulativeId, setManipulativeId] = useState(item.manipulativeId ?? '');

   const value = JSON.stringify({manipulativeId});

   return (
      <DialogModal onClose={onClose}>
         <h3>Step {stepIndex}, Item {itemIndex}: Input Editor</h3>
         <form method="dialog">
            <h4>Manipulative</h4>
            <Select value={manipulativeId} onChange={(e) => setManipulativeId(e.target.value)}>
               {manipulativeId === '' && <option value="">-- SELECT --</option>}
               {manipulativesToShow.map((manipulative) => (
                  <option key={manipulative.id} value={manipulative.id}>{manipulative.name}</option>
               ))}
            </Select>
            <p className="step-editor-controls">
               <Button value="cancel">Cancel</Button>
               <Button value={value}>Save Changes</Button>
            </p>
         </form>
      </DialogModal>
   );
};

export default ManipulativeEditor;
