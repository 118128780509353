import {Suspense} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useUser} from "store";
import icons from "app-constants/icons";
import NavItem from "components/nav/nav-item";
import NavSection from "components/nav/nav-section";

const AssignmentsAdminNavSection = () => {
  const {isAdmin} = useUser();
  return isAdmin ? (
    <NavItem to="/assignments/admin">
      <span>
        <FontAwesomeIcon icon={icons.assignments}/>
        Assignments Admin
      </span>
    </NavItem>
  ) : null;
};

const AssignmentsNavSection = () => {
  return (
    <NavSection>
      <NavItem to="/assignments" end>
        <span>
          <FontAwesomeIcon icon={icons.assignments}/>
          Assignments
        </span>
      </NavItem>
      <Suspense fallback={null}>
        <AssignmentsAdminNavSection />
      </Suspense>
    </NavSection>
  );
};

export default AssignmentsNavSection;
