import {Route, Routes} from "react-router-dom";
import AssignmentsAdminHome from "./assignments-admin-home";
import AssignmentsAdminEditor from "./assignments-admin-editor";

const AssignmentsAdmin = () => {
  return (
    <Routes>
      <Route path="/" element={<AssignmentsAdminHome/>}/>
      <Route path="/:editorId/*" element={<AssignmentsAdminEditor/>}/>
    </Routes>
  );
};

export default AssignmentsAdmin;
