import { useState } from 'react';
import valueGenerator from '@lasalle/value-generator';

const { snapshotManager } = valueGenerator;

const useSnapshots = (
   generatorSpecs = [],
   presets = {},
   transforms = {},
   valueTypeSpecs,
   genFunctionSpecs,
   generatorConfigs
) => {

  const [snapshots] = useState(() => snapshotManager(
     generatorSpecs,
     presets,
     transforms,
     valueTypeSpecs,
     genFunctionSpecs,
     generatorConfigs
  ));

  const [state, setState] = useState(() => snapshots.getSnapshot());

  const dispatch = (action, ...args) => {
    const snapshot = snapshots[action](...args);
    setState(snapshot);
  };

  return {
    ...state,
    dispatch,
  };
};

export default useSnapshots;
