import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import "./buttons.css";

export const Button = ({ icon, className = '', children, ...restProps }) => {
   const classes = ['btn'].concat(className.split(' '));
   return (
      <button className={classes.join(' ')} {...restProps}>
         {icon && <span className="btn-icon">{icon}</span>}
         {children}
      </button>
   );
};

export const AddButton = ({ withIcon, className = '', children, ...restProps}) => {
   const classes = ['btn-add'].concat(className.split(' '));
   return (
      <Button
         className={classes.join(' ')}
         icon={withIcon && <FontAwesomeIcon icon={faPlus} />}
         {...restProps}
      >
         {children}
      </Button>
   );
};

export const DeleteButton = ({ withIcon, className = '', children, ...restProps}) => {
   const classes = ['btn-delete'].concat(className.split(' '));
   return (
      <Button
         className={classes.join(' ')}
         icon={withIcon && <FontAwesomeIcon icon={faTrash} />}
         {...restProps}
      >
         {children}
      </Button>
   );
};
