import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay} from "@fortawesome/free-solid-svg-icons";
import {useSharableState} from "../../share-state/share-state";
import useSnapshotAnimation from "../../snapshots/use-snapshot-animation";
import {Button} from "../../shared/buttons/buttons";

const Play = ({ animationField, _snapshotId }) => {
   const {state} = useSharableState({}, _snapshotId);
   const animations = state[animationField] ?? {};
   const {play} = useSnapshotAnimation(animations, _snapshotId);

   return (
      <div className="animate-play">
         <Button onClick={play}>
            <FontAwesomeIcon icon={faPlay} />
         </Button>
      </div>
   );
};

export default Play;
