import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAddSpec} from "api";
import TextBox from "components/shared/text-box/text-box";
import {AddButton} from "components/shared/buttons/buttons";

const CreateQuestionType = ({ spec }) => {
   const navigate = useNavigate();
   const addQuestionType = useAddSpec('question-types');
   const {name, description, tags, goalIds, _id: generatorId} = spec;
   const [copyName, setCopyName] = useState(name ?? '');

   const handleCreate = async () => {
      const trimmedName = copyName.trim();
      if (trimmedName) {
         const resp = await addQuestionType(trimmedName, {
            description,
            tags,
            goalIds,
            generatorId,
         });
         const {_id} = JSON.parse(resp) ?? {};
         if (_id) navigate(`/specs/question-types/${_id}`);
      }
   };

   return (
      <div className="info-section">
         <h3>Create Question Type from Generator</h3>
         <p>Create a new Question Type that uses this Generator and shares its metadata.</p>
         <label>
            Name: {' '}
            <TextBox
               variant="longer"
               value={copyName}
               onChange={(e) => setCopyName(e.target.value)}
            />
         </label>
         <div className="controls">
            <AddButton onClick={handleCreate} withIcon>{' '}Create</AddButton>
         </div>
      </div>
   );
};

export default CreateQuestionType;
