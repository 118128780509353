import {Suspense, useState} from "react";
import {ErrorBoundary} from "react-error-boundary";
import Nav from "components/nav/nav";
import LogoBox from "components/shared/logo-box/logo-box";
import EditorMenu from "components/icon-menus/editor-menu";
import ValuesList from "./values-list/values-list";
import ValueDetails from "./value-details/value-details";
import GeneratorPreview from "./preview/generator-preview";
import "./generator-editor.css";
import SpecHeader from "../../spec-header/spec-header";

const GeneratorEditor = () => {
   const [selectedValueId, setSelectedValueId] = useState();

   return (
     <>
       <EditorMenu />
       <Nav>
         <LogoBox />
         <div className="values-list generator-values-list">
           <Suspense fallback={null}>
             <ValuesList
               selectedValueId={selectedValueId}
               setSelectedValueId={setSelectedValueId}
             />
           </Suspense>
         </div>
       </Nav>
       <main>
         <SpecHeader hasCreateLink />
         <div>
           <div className="details-panel generator-details-panel">
             <Suspense fallback={null}>
               <ValueDetails
                 valueId={selectedValueId}
                 handleSelectValueId={setSelectedValueId}
                 key={selectedValueId}
               />
             </Suspense>
           </div>
           <div className="preview-panel generator-preview-panel">
             <ErrorBoundary fallback={<p>There was a problem generating the preview</p>}>
               <Suspense fallback={null}>
                 <h3>Generated Values</h3>
                 <GeneratorPreview/>
               </Suspense>
             </ErrorBoundary>
           </div>
         </div>
       </main>
     </>
   );
};

export default GeneratorEditor;
