const NavSection = ({ className, children }) => {
  const classes = ['nav-section'];
  if (className && typeof className === 'string') classes.push(className);
  return(
    <div className={classes.join(' ')}>
      <ul>
        {children}
      </ul>
    </div>
  );
};

export default NavSection;
