const GENERATOR_VALUE_TYPE_ID = '64c95e262dad26a3e7074a51';

// Replace any values in the generator that are generators themselves with the nested values.
const hydrateNestedGenerators = (generator, generators) => {
   const values = generator.values.reduce((valuesSoFar, value) => {
      if (value.type !== GENERATOR_VALUE_TYPE_ID) return [...valuesSoFar, value];

      // Assume there's only one generator id and it's at level 0.
      const rule = value.rules?.[0] ?? {};
      const nestedGeneratorId = rule.generator;
      const nestedGenerator = generators.find((g) => g._id === nestedGeneratorId) ?? {};

      if (rule.merge) {
         valuesSoFar = valuesSoFar.concat(nestedGenerator.values);
      } else {
         valuesSoFar.push({
            ...value,
            rules: [{ generator: nestedGenerator }],
         });
      }

      return valuesSoFar;
   }, []);

   return {
      ...generator,
      values,
   };
};

export default hydrateNestedGenerators;
