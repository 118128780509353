import './table.css';

const Table = ({ isLoadingData, className, children }) => {
  const classes = ['rich-authoring-table'];
  if (className && typeof className === 'string') classes.push(className);
  if (isLoadingData) classes.push('is-previous-data');

  return (
    <table className={classes.join(' ')}>
      {children}
    </table>
  );
};

export default Table;
