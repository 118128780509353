import {useSpec, useSpecs} from "api";
import useDebounce from "lib/hooks/use-debounce";
import TextBox from "components/shared/text-box/text-box";
import Select from "components/shared/select/select";

const ViewPropsEditor = ({item, generatorId, updateItem}) => {
   const {data: views} = useSpecs('views') ?? {data: []};
   const viewProps = views.find((v) => v._id === item.viewId)?.viewProps ?? [];
   const generator = useSpec('generators', generatorId) ?? {values: []};

   const values = generator.values.map((v) => {
      const parent = generator.values.find((val) => val.id === v.parentId);
      v.namePath = parent ? `${parent.namePath}.${v.name}` : v.name;
      return v;
   })

   const [propValues, setPropValues] = useDebounce(updateItem, {
      viewPropValues: { ...(item.viewPropValues ?? {}) },
      viewPropConfig: { ...(item.viewPropConfig ?? {}) },
   });

   const handleChangeType = (viewProp, value) => {
      propValues.viewPropConfig[viewProp.id] = {
         type: value,
      };
      setPropValues({ ...propValues });
   };

   const handleMakeChoice = (viewProp, value) => {
      propValues.viewPropValues[viewProp.id] = value;
      propValues.viewPropConfig[viewProp.id] = {
         type: 'choice',
         value,
      };
      setPropValues({ ...propValues });
   };

   const handleChange = (viewProp, value) => {
      propValues.viewPropValues[viewProp.id] = value;
      propValues.viewPropConfig[viewProp.id] = {
         ...(propValues.viewPropConfig[viewProp.id] ?? {}),
         value,
      };
      setPropValues({ ...propValues });
   };

   return viewProps.length > 0 && (
      <div>
         <table className="editor-table view-props-table">
            <thead>
            <tr>
               <th>Prop</th>
               <th>Source</th>
               <th>Value</th>
            </tr>
            </thead>
            <tbody>
            {viewProps.map((viewProp) => {
               const propConfig = (item.viewPropConfig ?? {})[viewProp.id] ?? {};
               const propType = propConfig.type ?? 'choice'

               return (
                  <tr key={viewProp.id}>
                     <td>{viewProp.name}</td>
                     <td>
                        <Select
                          value={propType}
                          onChange={(e) => handleChangeType(viewProp, e.target.value)}
                        >
                           <option value="choice">Choice</option>
                           <option value="literal">Literal</option>
                           <option value="path">Path</option>
                        </Select>
                     </td>
                     <td>
                        {propType === 'choice' ? (
                          <Select
                            value={(propConfig.value ?? (item.viewPropValues ?? {})[viewProp.id]) || ''}
                            onChange={(e) => handleMakeChoice(viewProp, e.target.value)}
                          >
                             <option value="">----</option>
                             {values.map((v) => (
                               <option key={v.namePath} value={v.namePath}>{v.namePath}</option>
                             ))}
                          </Select>
                        ) : (
                          <TextBox
                            value={propValues.viewPropValues[viewProp.id] ?? ''}
                            onChange={(e) => handleChange(viewProp, e.target.value)}
                          />
                        )}
                     </td>
                  </tr>
               )
            })}
            </tbody>
         </table>
      </div>
   );
};

export default ViewPropsEditor;
