import {useState} from "react";
import useUrlSearchParams from "lib/hooks/use-url-search-params";
import {Button} from "components/shared/buttons/buttons";
import TextBox from "components/shared/text-box/text-box";
import Select from "components/shared/select/select";
import "./spec-list-nav.css";

const SpecListNav = ({ startLoadingData }) => {
  const {searchParams, setSearchParams} = useUrlSearchParams();
  const [searchText, setSearchText] = useState(searchParams.get('search'));

  const handleSearch = () => {
    startLoadingData(() => {
      setSearchParams({ search: searchText ? searchText : undefined });
    });
  };

  const handleSetFilter = (filter, value) => {
    startLoadingData(() => {
      setSearchParams({ [filter]: value === 'All' ? undefined : value });
    });
  };

  const today = new Date();
  today.setHours(0);
  const week = new Date(today);
  week.setDate(week.getDate() - 6);
  const month = new Date(today);
  month.setDate(month.getDate() - 29);
  const year = new Date(today);
  year.setFullYear(year.getFullYear() - 1);

  return (
    <>
      <div className="spec-search nav-section">
        <TextBox
          type="search"
          value={searchText ?? ''}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <p className="controls">
          <Button onClick={handleSearch}>Search</Button>
        </p>
      </div>

      <div className="status-filters nav-section">
        <div>
          <h4>Status</h4>
          <Select
            value={searchParams.get('status') ?? 'All'}
            onChange={(e) => handleSetFilter('status', e.target.value)}
          >
            <option value="All">All</option>
            <option value="draft">Draft</option>
            <option value="pending">Pending</option>
            <option value="published">Published</option>
          </Select>
        </div>
        <div>
          <h4>Owner</h4>
          <Select
            value={searchParams.get('createdBy') ?? 'All'}
            onChange={(e) => handleSetFilter('createdBy', e.target.value)}
          >
            <option value="All">All</option>
            <option value="me">Me</option>
          </Select>
        </div>
      </div>

      <div className="nav-section">
        <h4>Created</h4>
        <Select
          value={searchParams.get('startDate') ?? 'All'}
          onChange={(e) => handleSetFilter('startDate', e.target.value)}
        >
          <option value="All">All</option>
          <option value={today.toISOString().split('T')[0]}>Today</option>
          <option value={week.toISOString().split('T')[0]}>Last 7 Days</option>
          <option value={month.toISOString().split('T')[0]}>Last 30 Days</option>
          <option value={year.toISOString().split('T')[0]}>Last 365 Days</option>
        </Select>
      </div>

      <div className="sort-options nav-section">
        <div>
          <h4>Sort By</h4>
          <Select
            value={searchParams.get('sortBy') ?? 'name'}
            onChange={(e) => handleSetFilter('sortBy', e.target.value)}
          >
            <option value="name">Name</option>
            <option value="created">Created</option>
            <option value="updated">Updated</option>
          </Select>
        </div>
        <div>
          <h4>Direction</h4>
          <Select
            value={searchParams.get('sortDir') ?? 'asc'}
            onChange={(e) => handleSetFilter('sortDir', e.target.value)}
          >
            <option value="asc">Ascending</option>
            <option value="desc">Descending</option>
          </Select>
        </div>
      </div>
    </>
  );
};

export default SpecListNav;
