import {useParams} from "react-router-dom";
import {useSpec, useSpecs} from "api";
import SnapshotControls from "components/snapshots/snapshot-controls";
import {SnapshotsButtonRefresh} from "components/snapshots/snapshot-buttons";
import Snapshots from "components/snapshots/snapshots";
import hydrateNestedGenerators from "lib/specs/hydrate-nested-generators";
import {GeneratedValues} from "./generated-values";

const GeneratorPreview = () => {
   const {specId} = useParams();
   const generator = useSpec('generators', specId);
   const {data: valueTypes} = useSpecs('value-types') ?? {data: []};
   const {data: valueFunctions} = useSpecs('value-functions') ?? {data: []};
   const {data: generators} = useSpecs('generators') ?? {data: []};

   const hydratedGenerator =  generator && generators.length > 0 && hydrateNestedGenerators(generator, generators);

   return hydratedGenerator && valueTypes.length > 0 && valueFunctions.length > 0 && (
      <div className="builder generator-preview">
         <Snapshots
            spec={hydratedGenerator}
            valueTypeSpecs={Object.fromEntries(valueTypes.map((v) => [v._id, v]))}
            genFunctionSpecs={Object.fromEntries(valueFunctions.map((v) => [v._id, v]))}
            key={JSON.stringify(generator.values)}
         >
            <GeneratedValues />
            <SnapshotControls>
               <SnapshotsButtonRefresh />
            </SnapshotControls>
         </Snapshots>
      </div>
   );
};

export default GeneratorPreview;
