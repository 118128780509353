import {useParams} from "react-router-dom";
import {upperFirst} from "@lasalle/rich-content-utils/text/text";
import {useSetSpecStatus} from "api";
import {Button} from "components/shared/buttons/buttons";

const DraftStatus = ({ isAdmin, specType, hasGoalIds, setStatus }) => {
   const handleSubmit = () => {
      if (specType !== 'question-types' || hasGoalIds) setStatus('pending');
      else window.alert('Please specify at least one goal id.');
   };

   return (
      <div>
         <h3>Submit for Review</h3>
         <p>
            Submitting the spec will change its status to <em>Pending</em>.
            {isAdmin ? (
               ' You will then be able to publish the spec.'
            ) : (
               ' A Rich Content Administrator will then publish the spec or suggest changes.'
            )}
         </p>
         {specType === 'question-types' && (
            <p>The status of the linked Generator spec for this Question Type will also be changed to <em>Pending</em>.</p>
         )}
         <p className="controls">
            <Button onClick={handleSubmit}>Submit</Button>
         </p>
      </div>
   );
};

const PendingStatus = ({ isAdmin, specType, setStatus }) => {
   const handleUnsubmit = () => {
      setStatus('draft');
   };

   const handlePublish = () => {
      setStatus('published');
   };

   return (
      <>
         <h3>Return to Draft</h3>
         <p>Unsubmit the spec to return it to <em>Draft</em> status and enable editing.</p>
         <p>
            <Button onClick={handleUnsubmit}>Unsubmit</Button>{' '}
         </p>

         {isAdmin ? (
            <>
               <h3>Go Live!</h3>
               <p>
                  {specType === 'question-types' ? (
                     'Publish this Question Type to make it appear on the live TUTOR site.'
                  ) : (
                     'Publish the spec to make it available to editors in other parts of the authoring system.'
                  )}
               </p>
               {specType === 'question-types' && (
                  <p>The linked Generator spec will also be published.</p>
               )}
               <p>
                  <Button onClick={handlePublish}>Publish</Button>
               </p>
            </>
         ) : (
            <>
               <h3>Awaiting Review</h3>
               <p>This spec is awaiting review by an administrator.</p>
            </>
         )}
      </>
   );
};

const PublishedStatus = ({ name, specType, numDeps, isAdmin, setStatus }) => {
   const handleUnpublish = () => {
      let msg = `Are you sure you want to revert '${name}' to 'draft' status?`;
      if (numDeps) {
         msg += `\nIt is being used by ${numDeps} other spec${numDeps === 1 ? '' : 's'}.`;
      }
      if (window.confirm(msg)) {
         setStatus('draft');
      }
   };

   return isAdmin ? (
      <>
         <h3>Return to Draft</h3>
         <p>Unpublish the spec to return it to <em>Draft</em> status and enable editing.
            {specType === 'question-types' ? (
               ' This Question Type will no longer appear on the live TUTOR site.'
            ) : (
               ' This will remove the spec from use in other parts of the authoring system.'
            )}
         </p>
         {specType === 'generators' && (
            <p>
               Any Question Types that use this Generator will also have their status changed to <em>Draft</em>
               {' '}and be removed from the live TUTOR site.
            </p>
         )}
         <p className="controls">
            <Button onClick={handleUnpublish}>Unpublish</Button>
         </p>
      </>
   ) : (
      <>
         <h3>Copy to Edit</h3>
         <p>
            Only an administrator can unpublish this spec.
            However, you can make a copy and edit that if you want to make a new version.
         </p>
      </>
   );
};

const StatusLocked = () => (
   <>
      <h3>Copy to Edit</h3>
      <p>
         Only the spec's owner or an administrator can edit this spec.
         However, you can make a copy and edit that if you want to make a new version.
      </p>
   </>
);

const StatusEditor = ({ spec, isAdmin, numDeps }) => {
   const {specType, specId} = useParams();
   const setStatus = useSetSpecStatus(specType, specId);
   const {_status, name, goalIds} = spec;

   if (_status === 'draft') return (
      <DraftStatus
         specType={specType}
         isAdmin={isAdmin}
         hasGoalIds={goalIds.length > 0}
         setStatus={setStatus}
      />
   );

   if (_status === 'pending') return (
      <PendingStatus
         specType={specType}
         isAdmin={isAdmin}
         setStatus={setStatus}
      />
   );

   return (
      <PublishedStatus
         name={name}
         numDeps={numDeps}
         specType={specType}
         isAdmin={isAdmin}
         setStatus={setStatus}
      />
   );
};

const StatusInfo = ({ spec, isAdmin, isOwner, numDeps }) => {
   return (
      <div className="info-section">
         <h3>Status</h3>
         <p>{upperFirst(spec._status)}</p>
         {(isAdmin || isOwner) ? (
            <StatusEditor
               spec={spec}
               isAdmin={isAdmin}
               numDeps={numDeps}
            />
         ) : (
            <StatusLocked />
         )}
      </div>
   );
};

export default StatusInfo;
