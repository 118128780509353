import { useState } from "react";
import TextBox from "components/shared/text-box/text-box";
import {getVariant} from "components/shared/text-box/text-box";

const RuleExpression = ({ title, ruleName, ruleValue, updateValue }) => {
    const [text, setText] = useState(ruleValue ?? '');

    const handleBlur = () => {
        updateValue(ruleName, text);
    };

    return (
        <tr>
            <th>{title}</th>
            <td>
                <TextBox
                    variant={getVariant(text.length)}
                    name={ruleName}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    onBlur={handleBlur}
                />
            </td>
          <td>fn</td>
        </tr>
    );
};

export default RuleExpression;
