import PolynomialTerm from "./polynomial-term";

const getOperator = (coef1, coef2, termsSoFar) => {
   if (termsSoFar.every((t) => t.coefficient === 0) || !coef2) return '';
   return coef2 > 0 ? ' + ' : ' - '
};

const Polynomial = ({ value: { terms } }) => {
   const nonZero = terms.filter((t) => t.coefficient !== 0);
   return (
      <span className="polynomial">
         {
            nonZero.length === 0 ? '0' : terms.map((term, i) => (
               <span key={i}>
                  {term.coefficient === 0 ? '' : <PolynomialTerm value={term} hideSign={nonZero.length > 1 && term !== nonZero[0]} />}
                  {getOperator(term.coefficient, terms[i + 1]?.coefficient, terms.slice(0, i + 1))}
               </span>
            ))
         }
      </span>
   );
};

export default Polynomial;
