import {useSnapshotDispatch, useSnapshotInfo, useSnapshotState} from "../../snapshots/snapshots";

const MultiSelect = ({ optionsField, maxNumSelectedField }) => {
   const [state] = useSnapshotState();
   const dispatch = useSnapshotDispatch();
   const {snapshotId} = useSnapshotInfo();

   const options = state[optionsField] ?? [];
   const selected = options.filter((v) => v.isSelected.isTrue);

   const handleSelect = (e) => {
      const { value: index } = e.target;
      const newOptions = [...options];
      const clickedOption = newOptions[index];

      newOptions[index] = {
         ...clickedOption,
         isSelected: { isTrue: !clickedOption.isSelected.isTrue },
      };

      dispatch('update', snapshotId, {
         [optionsField]: newOptions,
      }, true);
   };

   return (
      <div className="multi-select">
         <ul className="multi-select-list">
            {options.map((option, i) => (
               <li className="multi-select-option" key={`${option.text}_${i}`}>
                  <label>
                     <input
                        type="checkbox"
                        value={i}
                        checked={!!option.isSelected?.isTrue}
                        onChange={handleSelect}
                        disabled={selected.length === maxNumSelectedField && !option.isSelected?.isTrue}
                     />
                     <span>{option.text}</span>
                  </label>
               </li>
            ))}
         </ul>
      </div>
   );
};

export default MultiSelect;
