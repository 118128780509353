const FractionSimple = ({value: {numerator, denominator}}) => (
   <math display="inline">
     <mfrac>
       <mn>{numerator}</mn>
       <mn>{denominator}</mn>
     </mfrac>
   </math>
);

export default FractionSimple;
