import {useState} from "react";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {useAddSpec} from "api";
import {useAddQuestionType} from "api/question-types";
import {mainSpecs} from "app-constants/specs";
import getSpecTitle from "lib/specs/get-spec-title";
import Label from "components/shared/label/label";
import TextBox from "components/shared/text-box/text-box";
import TextArea from "components/shared/text-area/text-area";
import {AddButton} from "components/shared/buttons/buttons";
import EditorMenu from "components/icon-menus/editor-menu";
import Nav from "components/nav/nav";
import LogoBox from "components/shared/logo-box/logo-box";
import {useUser} from "store";
import SpecsNavSection from "../specs-nav/specs-nav-section";
import SpecHeader from "../spec-header/spec-header";

const SpecNew = () => {
  const {specType} = useParams();
  const navigate = useNavigate();
  const { isAdmin } = useUser();
  const canView = isAdmin || specType === 'question-types';
  const addQuestionType = useAddQuestionType();
  const addSpec = useAddSpec(specType);
  const title = getSpecTitle(specType).slice(0, -1);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const isQuestionType = specType === 'question-types';
  const [isLinked, setIsLinked] = useState(isQuestionType);
  const [hasContent, setHasContent] = useState(false);

  const handleCreate = async () => {
    const trimmedName = name.trim();
    const trimmedDesc = description.trim();
    let spec;
    if (trimmedName) {
      if (isQuestionType) {
        if (isLinked) {
          const result =
            await addQuestionType(trimmedName, trimmedDesc, undefined, true, hasContent);
          spec = { _id: result?.questionTypeResult?.insertedId };
        } else {
          const result = await addQuestionType(trimmedName, trimmedDesc);
          spec = { _id: result?.questionTypeResult?.insertedId };
        }
      } else {
        spec = await addSpec(trimmedName, { description: trimmedDesc });
      }
      if (spec._id) {
        navigate(`/specs/${specType}/${spec._id}`);
      }
    }
  };

  return canView ? (
    <>
      <EditorMenu />
      <Nav>
        <LogoBox />
        <SpecsNavSection specTypes={mainSpecs} />
      </Nav>
      <main>
        <SpecHeader />
        <div>
          <div>
            <h2>New {title}</h2>

            <p><Label>Name</Label></p>
            <p>
              <TextBox
                variant="longest"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </p>

            <p><Label>Description</Label></p>
            <p>
              <TextArea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </p>

            {isQuestionType && (
              <>
                <p>
                  <Label>
                    <input
                      type="checkbox"
                      checked={isLinked}
                      onChange={() => setIsLinked((v) => !v)}
                      disabled={!isAdmin}
                    />
                    Also create a linked Generator
                  </Label>
                </p>
                <p>
                  <Label>
                    <input
                      type="checkbox"
                      checked={hasContent}
                      onChange={() => setHasContent((v) => !v)}
                      disabled={!isLinked}
                    />
                    Include example content
                  </Label>
                </p>
              </>
            )}

            <p className="controls">
              <AddButton withIcon onClick={handleCreate}>
                Create
              </AddButton>
            </p>
          </div>
          <div></div>
        </div>
      </main>
    </>
  ) : <Navigate to="/" replace={true}/>;
};

export default SpecNew;
