import {Link} from "react-router-dom";
import {useAssignmentsForEditor, useDeleteAssignment} from "api/assignments";
import Table from "components/shared/table/table";
import {DeleteButton} from "components/shared/buttons/buttons";

const AssignmentsForEditor = ({ editorId }) => {
  const assignments = useAssignmentsForEditor(editorId);
  const deleteAssignment = useDeleteAssignment();

  return assignments.length ? (
    <Table>
      <thead>
      <tr>
        <th>Goal</th>
        <th>Assigner</th>
        <th>Date Assigned</th>
        <th>Status</th>
        <th>Delete</th>
      </tr>
      </thead>
      <tbody>
      {assignments.map(({_id, goalId, goalName, assignedAt, assignerNames, status}) => (
        <tr key={`${goalId}-${editorId}`}>
          <td>
            <Link to={goalId}>{goalName}</Link>
          </td>
          <td>{assignerNames.firstName} {assignerNames.lastName}</td>
          <td>{new Date(assignedAt).toLocaleString()}</td>
          <td>{status}</td>
          <td>
            <DeleteButton withIcon onClick={() => deleteAssignment(_id)} />
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
  ) : (
    <p>There are no assignments for this editor.</p>
  );
};

export default AssignmentsForEditor;
