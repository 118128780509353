const themes = ['theme1', 'theme2', 'theme3', 'theme4'];

const LogoBox = () => {
  const switchTheme = () => {
    const root = document.getElementById('root');
    const currentTheme = root.className;
    const themeIndex = themes.indexOf(currentTheme);
    root.className = themes[(themeIndex + 1) % themes.length];
  };

  return (
    <div className="logo-box" onClick={switchTheme}>
      <img src="/images/logo.svg" alt="Rich Content Authoring Logo"/>
    </div>
  );
};

export default LogoBox;
