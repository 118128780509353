import { useState } from "react";
import TextBox from "components/shared/text-box/text-box";

const Cell = ({ cellValue, setCellValue }) => {
  const [cellText, setCellText] = useState(cellValue ?? '');

  const handleChange = (e) => {
    setCellText(e.target.value);
  };

  const handleBlur = () => {
    setCellValue(cellText);
  };

  return (
    <td>
      <TextBox
        value={cellText}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </td>
  )
}

const RuleTable = ({ title, ruleName, ruleValue, updateValue }) => {
  const rule = ruleValue || { title: '', rows: [['']] };
  const [rows, setRows] = useState(rule.rows);

  console.log({ruleName, ruleValue})

  const handleChange = (i, j, value) => {
    rows[i][j] = value;
    setRows(rows);
    updateValue(ruleName, { title: rule.title, rows });
  };

  const handleSetRows = (e) => {
    const { value } = e.target;
    let newRows = [];
    for (let i = 0; i < value; i++) {
      newRows.push(rows[i] ?? Array(rows[0].length).fill(0, 0).map((c) => ''));
    }
    setRows(newRows);
  };

  const handleSetColumns = (e) => {
    const { value } = e.target;
    let newRows = [];
    for (let i = 0; i < rows.length; i++) {
      let newRow = [];
      for (let j = 0; j < value; j++) {
        newRow.push(rows[i][j] ?? '');
      }
      newRows.push(newRow);
    }
    setRows(newRows);
  };

  return (
    <tr>
      <th>{title}</th>
      <td>
        <p className="controls">
          <label>Rows:{' '}
            <input
               type="number"
               size="2"
               min="1"
               max="20"
               value={rows.length}
               onChange={handleSetRows}
            />
          </label>{' '}
          <label>Cols:{' '}
            <input
               type="number"
               size="2"
               min="1"
               max="20"
               value={rows[0].length}
               onChange={handleSetColumns}
            />
          </label>
        </p>
        <table className="values-table">
          <tbody>
          {rows.map((row, i) => (
             <tr key={i}>
               {row.map((cell, j) => (
                  <Cell
                     key={`${i}_${j}`}
                     cellValue={cell}
                     setCellValue={(value) => handleChange(i, j, value)}
                  />
               ))}
             </tr>
          ))}
          </tbody>

        </table>
      </td>
    </tr>
  )
};

export default RuleTable;
