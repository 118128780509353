import {useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faXmark} from "@fortawesome/free-solid-svg-icons";
import {useRemoveSpec} from "api";
import {DeleteButton} from "components/shared/buttons/buttons";

const DeleteSpec = ({ spec, canDelete, isOwner, isAdmin, numDeps }) => {
   const {specType, specId} = useParams();
   const navigate = useNavigate();
   const removeSpec = useRemoveSpec(specType, specId);
   const {name, _status} = spec;

   const handleDelete = () => {
      if (window.confirm(`Are you sure you want to delete the '${name}' spec?`)) {
         removeSpec();
         navigate(`/specs/${specType}`);
      }
   };

   return (
      <div className="info-section">
         <h3>Delete Spec</h3>
         <p>Deleting the spec will completely remove it from the system.</p>
         <p>You can only delete Draft specs. You must own them or be an admin
            {' '}and they must not be in use by any other specs.</p>

         <h3>Checks</h3>
         <ul className="info-delete-checks">
            <li>
               <FontAwesomeIcon icon={(isOwner || isAdmin) ? faCheck : faXmark} />{' '}
               Owner or Admin
            </li>
            <li>
               <FontAwesomeIcon icon={_status === 'draft' ? faCheck : faXmark} />{' '}
               Draft status
            </li>
            <li>
               <FontAwesomeIcon icon={numDeps === 0 ? faCheck : faXmark} />{' '}
               Not in use
            </li>
         </ul>

        {specType === 'question-types' && spec.hasLinkedGenerator && (
          <p>This Question Type has a linked Generator that will also be deleted.</p>
        )}

        {canDelete ? (
          <p>This spec is safe to delete.</p>
         ) : (
            <p><strong>This spec cannot be deleted.</strong></p>
         )}
         <div className="controls">
            <DeleteButton
               onClick={handleDelete}
               withIcon
               disabled={!canDelete}
            >
               {' '}Delete
            </DeleteButton>
         </div>
      </div>
)
   ;
};

export default DeleteSpec;
