import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faFileCircleQuestion, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useUser} from "store";

const ActionNavBasic = ({ specType }) => {
  const { isAdmin } = useUser();
  return (
    <ul>
      <li>
        <NavLink to="./copy">
          <span>
             <FontAwesomeIcon icon={faCopy}/>{' '}Copy this Spec
          </span>
        </NavLink>
      </li>
      {specType === 'generators' && (
        <li>
          <NavLink to="./create-question-type">
             <span>
                <FontAwesomeIcon icon={faFileCircleQuestion}/>{' '}
               Create Question Type
             </span>
          </NavLink>
        </li>
      )}
      {specType === 'question-types' && isAdmin && (
        <li>
          <NavLink to="./create-question-set">
             <span>
                <FontAwesomeIcon icon={faFileCircleQuestion}/>{' '}
               Create Question Set
             </span>
          </NavLink>
        </li>
      )}
      <li>
        <NavLink to="./delete">
          <span>
             <FontAwesomeIcon icon={faTrash}/>{' '}
            Delete this Spec
          </span>
        </NavLink>
      </li>
    </ul>
  );
};

export default ActionNavBasic;
