import Nav from "components/nav/nav";
import LogoBox from "components/shared/logo-box/logo-box";
import EditorMenu from "components/icon-menus/editor-menu";
import HomeNav from "./home-nav";

const Home = () => {
  return (
    <>
      <EditorMenu />
      <Nav>
        <LogoBox />
        <HomeNav/>
      </Nav>
      <main>
        <header className="spec-header">
          <div className="icon-title">
            <div>
              <h2>Rich Content Authoring</h2>
              <h3>Create dynamic content for the investigation and practice of mathematical ideas.</h3>
            </div>
          </div>
        </header>
        <div>
          <div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;
