import {useSnapshotDispatch} from "./snapshots";
import animate from "@lasalle/animation";

const useSnapshotAnimation = (animationGroup, _snapshotId) => {
   const dispatch = useSnapshotDispatch();

   return {
      play: animate(
         animationGroup,
         (v) => dispatch('update', _snapshotId, v, true)
      )
   };
};

export default useSnapshotAnimation;
