import {useState} from "react";
import {useSpecs} from "api";
import useStore from 'store';
import TextBox from "components/shared/text-box/text-box";
import "./generator-picker.css";

const GeneratorPicker = ({spec, handleSetGeneratorId}) => {
   const {data: generators} = useSpecs('generators') ?? {data: []};
   const user = useStore((s) => s.user);
   const [onlyMy, setOnlyMy] = useState(true);
   const [searchText, setSearchText] = useState('');

   const generatorsToShow = generators
      .filter((g) => (onlyMy ? g._createdBy === user._id : true) && g._id !== spec.generatorId)
      .filter((g) => {
         const regex = new RegExp(searchText, 'i');
         return regex.test(g.name) || regex.test(g.tags.join(';')) || regex.test(g.goalIds.join(';'));
      })
      .sort((gen1, gen2) => gen1.name.localeCompare(gen2.name));

   return (
      <div className="generator-picker">
         <p className="generator-picker-filter">
            <label>
               <input type="checkbox" checked={onlyMy} onChange={() => setOnlyMy((v) => !v)} />
               Only my generators
            </label>
            <span>
               Search:{' '}
               <TextBox variant="longer" onChange={(e) => setSearchText(e.target.value)} />
            </span>
         </p>
         {generatorsToShow.length > 0 ? (
            <ol className="generator-picker-list">
               {generatorsToShow.map((g) => {
                  return (
                     <li className="generator-picker-item" key={g._id}>
                        <button onClick={() => handleSetGeneratorId(g._id)}>
                           <h3>{g.name}</h3>
                           {g.description && <p>{g.description}</p>}
                        </button>
                     </li>
                  )
               })}
            </ol>
         ) : (
            <p className="generator-picker-empty">There are no generators to show.</p>
         )}
      </div>
   );
};

export default GeneratorPicker;
