import {useState, createContext, useContext, forwardRef} from "react";

const ToggleBoxContext = createContext();

export const ToggleBox = forwardRef((props, ref) => {
   const { children, isOpen, className = '', ...restProps } = props;
   const [showContents, setShowContents] = useState(isOpen);
   const classes = ['toggle-box'].concat(className.split(' '));
   if (!showContents) classes.push('collapsed');

   const toggleContents = (e) => {
      e.stopPropagation();
      setShowContents((b) => !b);
   };

   return (
      <ToggleBoxContext.Provider value={{ showContents, toggleContents }}>
         <div className={classes.join(' ')} {...restProps} ref={ref}>
            {children}
         </div>
      </ToggleBoxContext.Provider>
   );
});

export const ToggleBoxHeader = ({ children }) => {
   const { toggleContents } = useContext(ToggleBoxContext);
   return (
      <div className="toggle-box-header" onClick={toggleContents}>
         {children}
      </div>
   );
};

export const ToggleBoxHeaderOpen = ({ children }) => {
   const { showContents } = useContext(ToggleBoxContext);
   return showContents && <ToggleBoxHeader>{children}</ToggleBoxHeader>;
};

export const ToggleBoxHeaderClosed = ({ children }) => {
   const { showContents } = useContext(ToggleBoxContext);
   return !showContents && <ToggleBoxHeader>{children}</ToggleBoxHeader>;
};

export const ToggleBoxContents = ({ children }) => {
   return (
      <div className="toggle-box-contents">
         {children}
      </div>
   );
};
