const bucket = 'manipulatives-backend-prod-generalbucket-kt60za4r9slf';
const s3 = 's3.eu-west-1.amazonaws.com';

const Image = ({value: {folder, fileName, altText, ...typeProps}}) => {
  return <img
    src={`https://${bucket}.${s3}/${folder}/${fileName}`}
    alt={altText}
    {...typeProps}
  />;
};

export default Image;
