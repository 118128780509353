import React from "react";
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import AppRoutes from "./app-routes";
import AuthIframe from "./components/auth-iframe";
import './App.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
      <AuthIframe />
      <BrowserRouter>
          <AppRoutes />
      </BrowserRouter>
    </>
);
