import {useState} from "react";
import ValueRulesTable from "./value-rules-table";
import "./value-details-rules.css";

const ValueDetailsRules = ({
   // removeRuleLevel,
   valueId,
   valueTypes,
   type,
   rules = [],
   list,
   exclusions,
   isLocked,
   handleUpdateRule,
   // handleAddRuleLevel,
   handleUpdateList,
   handleSetExclusions,
}) => {
   const [level, /* setLevel */] = useState(0);
   const [localExclusions, setLocalExclusions] = useState(false);
   const showExclusions = !!exclusions || localExclusions;

   // const handleRemoveRuleLevel = (e) => {
   //    e.stopPropagation();
   //    const levelToRemove = level;
   //    setLevel(Math.max(level - 1, 0));
   //    removeRuleLevel(levelToRemove);
   // };
   //
   // const handleSetLevel = (e) => {
   //    setLevel(parseInt(e.target.value, 10));
   // };
   //
   // const addRuleLevel = (e) => {
   //    e.stopPropagation();
   //    handleAddRuleLevel();
   // };
   //
   // const stopPropagation = (e) => {
   //    e.stopPropagation();
   // };

   const handleToggleExclusions = () => {
      if (showExclusions) {
         handleSetExclusions('');
         setLocalExclusions(false);
      } else {
         setLocalExclusions(true);
      }
   };

   return (
     <div className="value-rules">
       {/*<h3>
           <span className="value-rules-controls" onClick={stopPropagation}>
               <Select value={level} onChange={handleSetLevel}>
                  {rules.map((rule, i) => (
                     <option value={i} key={i}>Level {i + 1}</option>
                  ))}
               </Select>
               <AddButton onClick={addRuleLevel}>
                  <FontAwesomeIcon icon={faPlus}/>
               </AddButton>
               {rules.length > 1 && (
                  <DeleteButton onClick={handleRemoveRuleLevel}>
                     <FontAwesomeIcon icon={faTrash}/>
                  </DeleteButton>
               )}
            </span>
        </h3>*/}
        <fieldset disabled={isLocked}>
           <ValueRulesTable
             key={`${valueId}${showExclusions}`}
             level={level}
             valueType={valueTypes.find((vt) => vt._id === type) ?? type}
             rules={rules}
             list={list}
             exclusions={exclusions}
             showExclusions={showExclusions}
             valueTypes={valueTypes}
             updateValue={(ruleName, ruleValue) => handleUpdateRule(ruleName, ruleValue, level)}
             updateList={(list) => handleUpdateList(list)}
             setExclusions={handleSetExclusions}
           />
          <p>
             <label>
                <input
                  type="checkbox"
                  checked={showExclusions}
                  onChange={handleToggleExclusions}
                  disabled={isLocked}
                />{' '}Exclusions
             </label>
          </p>
        </fieldset>
     </div>
   );
};

export default ValueDetailsRules;
