import {SnapshotsButtonCopy, SnapshotsButtonNew, SnapshotsButtonNext, SnapshotsButtonPrev} from "./snapshot-buttons";

const SaveStateControls = ({
   children
 }) => {
  return children ?? (
    <p>
      <SnapshotsButtonNew />{" "}
      <SnapshotsButtonCopy />{" "}
      <SnapshotsButtonPrev />{" "}
      <SnapshotsButtonNext />
    </p>
  );
};

export default SaveStateControls;
