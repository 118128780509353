import {Suspense} from "react";
import getSpecTitle from "lib/specs/get-spec-title";
import SpecUsageList from "./spec-usage-list";

const UsedByInfo = ({ numDeps, specType, specId }) => {
   return (
      <div>
         <h3>Used By {numDeps} Spec{numDeps === 1 ? '' : 's'}</h3>
         {numDeps === 0 && (
            <p>This {getSpecTitle(specType)} spec is not referenced by any other specs.</p>
         )}
         <Suspense fallback={<p>Loading related spec data...</p>}>
            <SpecUsageList specType={specType} specId={specId}/>
         </Suspense>
      </div>
   );
};

export default UsedByInfo;
