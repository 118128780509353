const finalDigitsPattern = new RegExp(/\d+$/);

const getNamePrefixAndSuffix = (name) => {
  const patternArray = finalDigitsPattern.exec(name);
  const prefix = patternArray ? name.slice(0, patternArray.index) : `${name}_`;
  const suffix = patternArray ? parseInt(name.slice(patternArray.index)) : 0;
  return {prefix, suffix};
};

const getNextName = (name, values) => {
  const {prefix} = getNamePrefixAndSuffix(name);
  const matchingValues = values
    .map((v) => getNamePrefixAndSuffix(v.name))
    .filter((v) => v.prefix === prefix)
    .sort((a, b) => a.suffix - b.suffix);
  const suffix = ((matchingValues.pop()?.suffix ?? 0) + 1);
  return `${prefix}${suffix}`;
};

export default getNextName;
