import {Suspense} from "react";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSpec} from "api";
import icons from "app-constants/icons";
import getSpecTitle from "lib/specs/get-spec-title";
import "./spec-header.css";

const SpecName = ({ specType, specId }) => {
  const spec = useSpec(specType, specId);
  return <h3>{spec.name}</h3>;
};

const SpecHeader = ({ hasCreateLink }) => {
  const {specType, specId} = useParams();
  return (
    <header className="spec-header">
      <div className="icon-title">
        <FontAwesomeIcon icon={icons[specType]} />
        <div>
          <h2>{getSpecTitle(specType)}</h2>
          {specId && (
            <Suspense fallback={<h3>&nbsp;</h3>}>
              <SpecName specType={specType} specId={specId} />
            </Suspense>
          )}
        </div>
      </div>
      {hasCreateLink && (
        <Link to={`/specs/${specType}/new`}>
          <FontAwesomeIcon icon={icons['new-spec']} />{' '}
          Create {getSpecTitle(specType).slice(0, -1)}
        </Link>
      )}
    </header>
  );
};

export default SpecHeader;
