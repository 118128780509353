import {Suspense} from "react";
import {Link, Route, Routes, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faInfoCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import {upperFirst} from "@lasalle/rich-content-utils/text/text";
import {useGetGoalById} from "api";
import {useAssignmentsForEditor, useGetQuestionTypes} from "api/assignments";
import {useUser} from "store";
import {mainSpecs} from "app-constants/specs";
import icons from "app-constants/icons";
import SpecsNavSection from "pages/specs/specs-nav/specs-nav-section";
import AssignmentsNavSection from "pages/assignments/assignments-nav-section";
import Nav from "components/nav/nav";
import Table from "components/shared/table/table";
import LogoBox from "components/shared/logo-box/logo-box";
import EditorMenu from "components/icon-menus/editor-menu";
import CreateQuestionType from "./create-question-type";
import AssignmentsHeader from "./assignments-header";

const AssignmentsList = () => {
  const {user} = useUser();
  const assignments = useAssignmentsForEditor(user?._id);
  return (
    <div>
      <Table>
        <thead>
        <tr>
          <th>Goal</th>
          <th>Assigner</th>
          <th>Status</th>
          <th>QT Count</th>
          <th>Create</th>
        </tr>
        </thead>
        <tbody>
        {assignments.map(({_id, goalName, goalId, assignerNames, status}) => (
          <tr key={_id}>
            <td>
              <Link to={goalId}>
                {goalName}
              </Link>
            </td>
            <td>{`${assignerNames.firstName} ${assignerNames.lastName}`}</td>
            <td>{status}</td>
            <td>?</td>
            <td>
              <Link to={`/assignments/${goalId}/new`} title="Create a new Question Type for this Goal">
                <FontAwesomeIcon icon={icons['new-spec']} />
              </Link>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </div>
  );
};

const GoalDetails = () => {
  const {goalId} = useParams();
  const goals = useGetGoalById(goalId);
  const {user} = useUser();
  const questionTypes = useGetQuestionTypes(user?._id, goalId);

  return (
    <div>
      <h3>Question Types for Goal</h3>
      <p>{goals[goalId].name}</p>
      {questionTypes.length > 0 ? (
        <Table>
          <thead>
          <tr>
            <th>Question Type</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          {questionTypes.map(({_id, name, _status}) => (
            <tr key={_id}>
              <td>
                <Link to={`/specs/question-types/${_id}`}>
                  {name}
                </Link>
              </td>
              <td>{upperFirst(_status)}</td>
              <td className="specs-list-actions">
                <p>
                  <span>
                     <Link
                       to={`/specs/question-types/${_id}/info`}
                       title={`See spec info for ${name}`}
                     >
                        <FontAwesomeIcon icon={faInfoCircle}/>
                     </Link>
                  </span>
                  <span>
                     <Link
                       to={`/specs/question-types/${_id}/info/copy`}
                       title={`Copy the ${name} spec`}
                     >
                        <FontAwesomeIcon icon={faCopy}/>
                     </Link>
                  </span>
                  <span>
                     <Link
                       to={`/specs/question-types/${_id}/info/delete`}
                       title={`Delete the ${name} spec`}
                     >
                        <FontAwesomeIcon icon={faTrash}/>
                     </Link>
                  </span>
                </p>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      ) : (
        <div>
          <p>You don't have any Question Types for this Goal.</p>
          <p>
            <Link to={`/assignments/${goalId}/new`}>
              <FontAwesomeIcon icon={icons['new-spec']}/>{' '}
              Create Question Type
            </Link>
          </p>
        </div>
      )}

    </div>
  );
};

const AssignmentsHome = () => {
  return (
    <>
      <EditorMenu/>
      <Nav>
        <LogoBox/>
        <AssignmentsNavSection/>
        <SpecsNavSection specTypes={mainSpecs}/>
      </Nav>
      <main>
        <Suspense fallback={null}>
          <Routes>
            <Route path="/" element={<AssignmentsHeader/>}/>
            <Route path="/:goalId" element={<AssignmentsHeader hasCreateLink/>}/>
            <Route path="/:goalId/new" element={<AssignmentsHeader/>}/>
          </Routes>
        </Suspense>
        <div>
          <Suspense fallback={null}>
            <Routes>
              <Route path="/" element={<AssignmentsList/>}/>
              <Route path="/:goalId" element={<GoalDetails/>}/>
              <Route path="/:goalId/new" element={<CreateQuestionType/>}/>
            </Routes>
          </Suspense>
        </div>
      </main>
    </>
  );
};

const Assignments = () => {
  return (
    <Routes>
      <Route path="/*" element={<AssignmentsHome />}/>
    </Routes>
  );
};

export default Assignments;
