import {Link, useParams} from "react-router-dom";
import {useGetGoalById} from "api";
import {useGetQuestionTypes} from "api/assignments";
import Table from "components/shared/table/table";

const QuestionTypesForGoal = () => {
  const {editorId, goalId} = useParams();
  const goals = useGetGoalById(goalId);
  const goal = goals[goalId];
  const questionTypes = useGetQuestionTypes(editorId, goalId);
  return (
    <>
      <p>{goal.name}</p>
      {questionTypes.length > 0 ? (
        <Table>
          <thead>
          <tr>
            <th>Question Type</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          {questionTypes.map((qt) => (
            <tr key={qt._id}>
              <td>
                <Link to={`/specs/question-types/${qt._id}`}>
                  {qt.name}
                </Link>
              </td>
              <td>{qt._status}</td>
            </tr>
          ))}
          </tbody>
        </Table>
      ) : (
        <p>The editor has not yet created any Question Types for this Goal.</p>
      )}
    </>
  );
};

export default QuestionTypesForGoal;
