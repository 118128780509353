import {useSpecs} from "api";
import {useUser} from "store";
import Select from "components/shared/select/select";

const ViewPicker = ({viewId, viewType, title = 'View', setViewId}) => {
   const {isAdmin} = useUser();
   const {data: views} = useSpecs('views') ?? {data: []};
   let viewsToShow = views.filter((v) => (v.viewTypes ?? []).includes(viewType));
   if (!isAdmin) {
     viewsToShow = viewsToShow.filter(
       (v) => v._status === 'published' || v._id === viewId
     );
   }

   return (
      <div>
         <h4>{title}</h4>
         <Select value={viewId} onChange={(e) => setViewId(e.target.value)}>
            <option value="No View Selected">-- SELECT --</option>
            {viewsToShow.sort((a, b) => a.name.localeCompare(b.name)).map((view) => (
               <option key={view._id} value={view._id}>{view.name}</option>
            ))}
         </Select>
      </div>
   );
};

export default ViewPicker;
