import { useState } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import TextBox from "components/shared/text-box/text-box";
import {AddButton, DeleteButton} from "components/shared/buttons/buttons";

const RuleNameValueList = ({ title, ruleName, ruleValue, updateValue }) => {
    const [list, setList] = useState(ruleValue || []);

    const handleBlur = () => {
        updateValue(ruleName, list);
    };

    const handleAddStep = () => {
       list.push({ name: '', value: '' });
       setList([...list]);
    };

    const handleRemoveStep = (i) => {
       list.splice(i, 1);
       setList([...list]);
    };

    const handleUpdateStep = (i, e) => {
       const { name, value } = e.target;
       list[i] = {
          ...list[i],
          [name]: value,
       };
       setList([...list]);
    };

    return (
        <tr>
            <th>{title}</th>
            <td>
               <ol>
                  {list.map(({ name, value }, i) => (
                     <li key={i}>
                        <TextBox
                           value={name}
                           name="name"
                           onChange={(e) => handleUpdateStep(i, e)}
                           onBlur={handleBlur}
                        />
                        <TextBox
                           value={value}
                           name="value"
                           onChange={(e) => handleUpdateStep(i, e)}
                           onBlur={handleBlur}
                        />
                        <DeleteButton onClick={() => handleRemoveStep(i)}>
                           <FontAwesomeIcon icon={faTrash} />
                        </DeleteButton>
                     </li>
                  ))}
               </ol>
               <AddButton onClick={handleAddStep}>
                  <FontAwesomeIcon icon={faPlus} />&nbsp;New Item
               </AddButton>
            </td>
        </tr>
    );
};

export default RuleNameValueList;
