import Select from "components/shared/select/select";

const RuleSelect = ({ title, ruleName, ruleValue, options = [], defaultValue, updateValue }) => {
    const handleSelect = (e) => {
        const { value } = e.target;
        updateValue(ruleName, value);
    };

    const selectValue = ruleValue ?? defaultValue;

    return options.length > 0 ? (
        <tr>
            <th>{title}</th>
            <td>
                <Select value={selectValue} onChange={handleSelect}>
                    {!selectValue && (
                        <option>-- Select --</option>
                    )}
                    {options.map((opt) => (
                        <option value={opt} key={opt}>{opt}</option>
                    ))}
                </Select>
            </td>
        </tr>
    ) : null;
};

export default RuleSelect;
