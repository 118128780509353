import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useSpecs} from "api";
import {ToggleBox, ToggleBoxContents, ToggleBoxHeader} from "components/shared/toggle-box/toggle-box";
import {AddButton, DeleteButton} from "components/shared/buttons/buttons";
import Label from "components/shared/label/label";
import Select from "components/shared/select/select";
import Card from "components/shared/card/card";
import TextBox from "components/shared/text-box/text-box";
import Controls from "components/shared/controls/controls";
import StepIndexPicker from "./step-index-picker";

const nameSort = (a, b) => a.name.localeCompare(b.name);

const ManipulativeStep = ({ index, step, numSteps, views, setStepValue, moveStep, removeStep }) => {
   const sortedViews = Object.values(views).sort(nameSort);
   const {data: controls} = useSpecs('controlSpecs') ?? {data: {}};
   const [controlToAdd, setControlToAdd] = useState(Object.values(controls)[0]?.name);
   const [transformKey, setTransformKey] = useState('');
   const [transformValue, setTransformValue] = useState('');
   const [localTitle, setLocalTitle] = useState(step.title || '');
   const [localIsClosed, setLocalIsClosed] = useState(step.isClosed ?? false);

   const handleInputViewChange = (e) => {
      const { value } = e.target;
      setStepValue({
         ...step,
         inputView: value,
      });
   };

   const handleTitleChange = (e) => {
      const { value: title } = e.target;
      setLocalTitle(title);
      setStepValue({ ...step, title });
   };

   const handleToggleChange = (e) => {
      const { checked: isClosed } = e.target;
      setLocalIsClosed(isClosed);
      setStepValue({ ...step, isClosed });
   };

   const handleAddControl = () => {
      if (controlToAdd !== '-- SELECT --') {
         const control = controls[controlToAdd];
         if (control) {
            setStepValue({
               ...step,
               controls: [
                  ...(step.controls ?? []),
                  {
                     id: crypto.randomUUID(),
                     controlSpecId: controlToAdd,
                     fields: Object.keys(control.fields).map((field) => ({
                        name: field,
                        value: '',
                     })),
                  },
               ],
            });
         }
      }
   };

   const handleRemoveControl = (e, controlId) => {
      e.stopPropagation();
      setStepValue({
         ...step,
         controls: step.controls.filter((c) => c.id !== controlId),
      });
   };

   const handleUpdateField = (e, controlId) => {
      const { name, value } = e.target;
      const controlIndex = step.controls.findIndex((c) => c.id === controlId);
      const fieldIndex = step.controls[controlIndex].fields.findIndex((f) => f.name === name);
      step.controls[controlIndex].fields[fieldIndex] = { name, value };
      setStepValue({ ...step });
   };

   const handleAddTransform = () => {
      if (transformKey.trim() && transformValue.trim()) {
         const transforms = [...(step.transforms ?? [])];
         transforms.push({ [transformKey]: transformValue });
         setStepValue({ ...step, transforms });
      }
   };

   const handleTransformFieldChange = (i, from, to) => {
      const transform = { [from]: to };
      step.transforms.splice(i, 1, transform);
      setStepValue({
         ...step,
         transforms: [...step.transforms],
      });
   };

   return views ? (
      <ToggleBox className="question-step view-step">
         <ToggleBoxHeader>
            <span>
               <FontAwesomeIcon icon={faList} />&nbsp;
               Step&nbsp;
               <StepIndexPicker
                  numSteps={numSteps}
                  stepIndex={index - 1}
                  handleMoveStep={moveStep}
               />&nbsp;
               - Manipulative
            </span>
            <DeleteButton onClick={removeStep}>
               <FontAwesomeIcon icon={faTrash} />
            </DeleteButton>
         </ToggleBoxHeader>
         <ToggleBoxContents>
            <ToggleBox>
               <ToggleBoxHeader>Manipulative View</ToggleBoxHeader>
               <ToggleBoxContents>
                  <Label>Title:</Label>
                  <TextBox
                     name="title"
                     value={localTitle}
                     onChange={handleTitleChange}
                  />
                  <Label>View:</Label>
                  <Select value={step.inputView} onChange={handleInputViewChange}>
                     {Object.values(sortedViews).map((view) => (
                        <option key={view.id} value={view.id}>{view.name}</option>
                     ))}
                  </Select>
                  <Label>
                     Is Closed:
                     <input
                        name="isClosed"
                        type="checkbox"
                        checked={localIsClosed}
                        onChange={handleToggleChange}
                     />
                  </Label>
               </ToggleBoxContents>
            </ToggleBox>

            <ToggleBox>
               <ToggleBoxHeader>Manipulative Controls</ToggleBoxHeader>
               <ToggleBoxContents>
                  {(step.controls ?? []).map((control) => {
                     const controlSpec = controls[control.controlSpecId] ?? {};
                     return(
                        <ToggleBox key={control.id}>
                           <ToggleBoxHeader>
                              <span>{controlSpec.name}</span>
                              <DeleteButton onClick={(e) => handleRemoveControl(e, control.id)}>
                                 <FontAwesomeIcon icon={faTrash} />
                              </DeleteButton>
                           </ToggleBoxHeader>
                           <ToggleBoxContents>
                              {(control.fields ?? []).map((field) => (
                                 <Card key={field.name}>
                                    <Label>{field.name}</Label>
                                    <TextBox
                                       name={field.name}
                                       value={field.value}
                                       onChange={(e) => handleUpdateField(e, control.id)}
                                    />
                                    <p><i>{controlSpec?.fields?.[field.name] ?? ''}</i></p>
                                 </Card>
                              ))}
                           </ToggleBoxContents>
                        </ToggleBox>
                     );
                  })}
                  <Select value={controlToAdd} onChange={(e) => setControlToAdd(e.target.value)}>
                     <option value="-- SELECT --">-- SELECT --</option>
                     {Object.values(controls).sort(nameSort).map((control) => (
                        <option value={control.id} key={control.id}>{control.name}</option>
                     ))}
                  </Select>
                  <AddButton onClick={handleAddControl}>Add Control</AddButton>
               </ToggleBoxContents>
            </ToggleBox>

            <ToggleBox>
               <ToggleBoxHeader>Transforms</ToggleBoxHeader>
               <ToggleBoxContents>
                  {(step.transforms ?? []).map((transform, i) => {
                     const [from, to] = Object.entries(transform)[0];
                     return (
                        <Card key={from}>
                           <Label>From</Label>
                           <TextBox
                              value={from}
                              onChange={(e) => handleTransformFieldChange(i, e.target.value, to)}
                           />
                           <Label>To</Label>
                           <TextBox
                              value={to}
                              onChange={(e) => handleTransformFieldChange(i, from, e.target.value)}
                           />
                           <DeleteButton>
                              <FontAwesomeIcon icon={faTrash} />
                           </DeleteButton>
                        </Card>
                     );
                  })}
                  <Controls>
                     <label>From:</label>&nbsp;
                     <TextBox value={transformKey} onChange={(e) => setTransformKey(e.target.value)} />
                     <label>To:</label>&nbsp;
                     <TextBox value={transformValue} onChange={(e) => setTransformValue(e.target.value)} />
                     <AddButton onClick={handleAddTransform}>
                        <FontAwesomeIcon icon={faPlus} />&nbsp;Add Transform
                     </AddButton>
                  </Controls>
               </ToggleBoxContents>
            </ToggleBox>
         </ToggleBoxContents>
      </ToggleBox>
   ) : null;
};

export default ManipulativeStep;
