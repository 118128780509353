import {Suspense, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useGetGoalById} from "api";
import {useAssignGoalToEditor} from "api/assignments";
import Label from "components/shared/label/label";
import TextBox from "components/shared/text-box/text-box";
import {AddButton} from "components/shared/buttons/buttons";

const AssignGoal = ({ editorId }) => {
  const [goalId, setGoalId] = useState('');

  return (
    <>
      <Label>Goal ID</Label>{' '}
      <TextBox
        variant="longer"
        value={goalId}
        onChange={(e) => setGoalId(e.target.value)}
      />
      <p className="controls">
        <Suspense fallback={<AddButton disabled>Assign</AddButton>}>
          <AssignGoalButton goalId={goalId} editorId={editorId} />
        </Suspense>
      </p>
    </>
  );
};

export default AssignGoal;

const AssignGoalButton = ({ editorId, goalId }) => {
  const navigate = useNavigate();
  const goals = useGetGoalById(goalId);
  const assign = useAssignGoalToEditor(editorId);
  const goal = goals[goalId];

  const handleAssign = async () => {
    if (goal) {
      await assign(goalId, goal.name, editorId);
      navigate(`/assignments/admin/${editorId}`);
    }
  };

  return (
    <>
      {goal && <p>{goal.name}</p>}
      <AddButton onClick={handleAssign} disabled={!goal?.name}>Assign</AddButton>
    </>
  );
};
