import {useMutation, useSuspenseQuery, useQueryClient} from "@tanstack/react-query";
import useStore, {useUser} from "../store";

const baseUrl = process.env.REACT_APP_MANIPULATIVES_AWS_APIGATEWAY_URL;
const specsBase = new URL('specs', baseUrl).href;
const specDepsBase = new URL('spec-deps', baseUrl).href;
const curriculumBase = new URL('curriculum', baseUrl).href;
const usersBase = new URL('users', baseUrl).href;

// CRUD functions

const getCurriculum = () => () => {
   return doFetch(curriculumBase);
};

const getGoalById = (goalId) => () => {
   return goalId
     ? doFetch(`${curriculumBase}/goals/${goalId}`)
     : Promise.resolve({});
};

const getUsersByRole = (role) => () => {
   return doFetch(`${usersBase}/${role}`);
};

const getSpecs = (specType, query = {}) => () => {
   // query.linked = '1';
   const queryString = Object.entries(query)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
   return doFetch(`${specsBase}/${specType}${queryString ? '?' + queryString : ''}`);
};

const getSpec = (specType, id, query = {}) => () => {
   if (!specType || !id) return Promise.resolve({ });
   const queryString = Object.entries(query)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
   return doFetch(`${specsBase}/${specType}/${id}${queryString ? '?' + queryString : ''}`);
}

const getSpecDeps = (specType, id) => () => doFetch(`${specDepsBase}/${specType}/${id}`);
const getSpecDepCounts = (specType, id) => () => doFetch(`${specDepsBase}/${specType}/${id}/count`);

export const createSpec = (specType) => ({newSpec}) =>
   doFetch(`${specsBase}/${specType}`, 'POST', newSpec);

export const updateSpec = (specType) => ({specId, patch}) =>
   doFetch(`${specsBase}/${specType}/${specId}`, 'PATCH', patch);

const deleteSpec = (specType) => ({specId}) => doFetch(`${specsBase}/${specType}/${specId}`, 'DELETE');

const setSpecStatus = (specType) => ({specId, status}) =>
   doFetch(`${specsBase}/${specType}/${specId}/status`, 'PATCH', {status});


// Hooks

export const useCurriculum = () => {
   return useSuspenseQuery({
      queryKey: ['curriculum'],
      queryFn: getCurriculum(),
      staleTime: 60 * 60 * 1000, // 1 hour
   }).data;
};

export const useGetGoalById = (goalId) => {
   return useSuspenseQuery({
      queryKey: ['goals', goalId],
      queryFn: getGoalById(goalId),
   }).data;
};

export const useUsersByRole = (role) => {
   return useSuspenseQuery({
      queryKey: ['users'],
      queryFn: getUsersByRole(role),
      staleTime: 60 * 60 * 1000, // 1 hour
   }).data;
};

export const useSpecs = (specType, query = {}) => {
   return useSuspenseQuery({
      queryKey: ['specs', specType, query],
      queryFn: getSpecs(specType, query),
   }).data;
};

export const useSpec = (specType, specId) => {
   return useSuspenseQuery({
      queryKey: ['specs', specType, specId ?? 'undefined'],
      queryFn: getSpec(specType, specId)
   }).data;
};

export const useSpecDeps = (specType, specId) => {
   return useSuspenseQuery({
      queryKey: ['spec-deps', specType, specId],
      queryFn: getSpecDeps(specType, specId),
   }).data;
};
export const useSpecDepCounts = (specType, specId) => {
   return useSuspenseQuery({
      queryKey: ['spec-deps-count', specType, specId],
      queryFn: getSpecDepCounts(specType, specId),
   }).data;
};

export const useSetSpecStatus = (specType, specId) => {
   const queryClient = useQueryClient();
   const {mutate: update} = useMutation({
      mutationFn: setSpecStatus(specType),
      onSettled: () => {
         queryClient.invalidateQueries(['specs', specType]);
      }
   });
   return (status) => update({specId, status});
};

export const useAddSpec = (specType) => {
   const {user} = useUser();
   const queryClient = useQueryClient();
   const {mutateAsync: create} = useMutation({
      mutationFn: createSpec(specType),
      onSettled: () => {
         queryClient.invalidateQueries(['specs', specType]);
      },
   });

   return (name, spec = {}) => {
      const newSpec = {
         ...spec,
         name,
         _createdBy: user._id,
         _updatedBy: user._id
      };

      return create({newSpec});
   };
};

export const useUpdateSpec = (specType, specId) => {
   const queryClient = useQueryClient();
   const updateOptions = {
      mutationFn: updateSpec(specType),
      onSettled: () => {
         queryClient.invalidateQueries({queryKey: ['specs', specType]});
         queryClient.invalidateQueries({queryKey: ['specs', specType, specId]});
      },
      onMutate: async ({patch}) => {
         queryClient.setQueryData(['specs', specType, specId], (old) => ({...old, ...patch}));
         await queryClient.cancelQueries({ queryKey: ['specs', specType] });
      }
   };
   const {mutateAsync: update} = useMutation(updateOptions);

   const getSpec = () => queryClient.getQueryData(['specs', specType, specId]) ??
      (queryClient.getQueryData([specType]) ?? {})[specId];

   const setField = (field) => (value) => update({specId, patch: {[field]: value}});

   const patch = (patch) => update({specId, patch});

   const addObjectProperty = (field) => (key, value) => {
      const spec = getSpec();
      const obj = {...(spec[field] ?? {})};
      obj[key] = value;
      return update({specId, patch: {[field]: obj}});
   };

   const removeObjectProperty = (field) => (key) => {
      const spec = getSpec();
      const obj = {...(spec[field] ?? {})};
      delete obj[key];
      return update({specId, patch: {[field]: obj}});
   };

   const pushArrayItemId = (field) => (itemId) => {
      const spec = getSpec();
      const array = [...(spec[field] ?? [])];
      if (!array.includes(itemId)) array.push(itemId);
      return update({specId, patch: {[field]: array}});
   }

   const moveArrayItemId = (field) => (itemId, toIndex) => {
      const spec = getSpec();
      const array = [...(spec[field] ?? [])];
      const fromIndex = array.findIndex(itemId);
      if (fromIndex === -1) return;
      return update({
         specId,
         patch: {
            [field]: array.toSpliced(fromIndex, 1).toSpliced(toIndex, 0, itemId)
         }
      });
   };

   const removeArrayItem = (field, isIdArray) => (itemId) => {
      const spec = getSpec();
      const array = [...spec[field] ?? []].filter((v) => (isIdArray ? v : v.id) !== itemId);
      return update({specId, patch: {[field]: array}});
   };

   const patchArrayItem = (field, allowUpserts) => (itemId, itemPatch = {}) => {
      const spec = getSpec();
      const array = [...(spec[field] ?? [])];
      const itemIndex = array.findIndex((v) => v.id === itemId);

      if (itemIndex === -1) {
         if (allowUpserts) {
            array.push({id: crypto.randomUUID(), ...itemPatch})
         } else return;
      } else {
         array[itemIndex] = {...(array[itemIndex]), ...itemPatch};
      }

      return update({specId, patch: {[field]: array}});
   };

   const moveArrayValues = (field) => (values, toIndex, valuePatch = {}) => {
      const spec = getSpec();
      const array = [...(spec[field] ?? [])];
      const fromIndex = array.findIndex((v) => v.id === values[0]?.id);

      if (fromIndex === -1) return;

      const items = array.splice(fromIndex, values.length);
      array.splice(
         toIndex - (toIndex > fromIndex ? values.length : 0),
         0,
         ...items.map((item) => ({...item, ...valuePatch}))
      );
      return update({specId, patch: {[field]: array}});
   };

   const setStatus = setField('_status');
   const addTag = pushArrayItemId('tags');
   const removeTag = removeArrayItem('tags', true);
   const addGoalId = pushArrayItemId('goalIds');
   const removeGoalId = removeArrayItem('goalIds', true);

   return {
      patch,
      getSpec,
      setField,
      addObjectProperty,
      removeObjectProperty,
      pushArrayItemId,
      moveArrayItemId,
      removeArrayItem,
      patchArrayItem,
      moveArrayValues,
      setStatus,
      addTag,
      removeTag,
      addGoalId,
      removeGoalId,
   };
};

export const useRemoveSpec = (specType, specId) => {
   const {mutate: remove} = useMutation({
      mutationFn: deleteSpec(specType),
   });
   return () => remove({specId});
};

export const doFetch = (url, method = 'GET', data, sendJwt = true) => {
   const requestOptions = {
      method,
      body: data ? JSON.stringify(data) : undefined,
      headers: {
         "Content-Type": "application/json",
      },
   };
   if (sendJwt) {
      const { jwt } = useStore.getState();
      if (!jwt) throw new Error('jwt required');
      requestOptions.headers = { ...requestOptions.headers, authorization: `Bearer ${jwt}` };
   }
   return fetch(url, requestOptions)
      .then((resp) => {
         if (!resp.ok) throw new Error(resp.message);
         return resp.json();
      });
};
