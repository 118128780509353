import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import icons from "app-constants/icons";
import {mainSpecs, otherSpecs} from "app-constants/specs";
import SpecsNavSection from "pages/specs/specs-nav/specs-nav-section";
import AssignmentsNavSection from "pages/assignments/assignments-nav-section";
import Nav from "components/nav/nav";
import LogoBox from "components/shared/logo-box/logo-box";
import EditorMenu from "components/icon-menus/editor-menu";

const HomeAdmin = () => {
  return (
    <>
      <EditorMenu />
      <Nav>
        <LogoBox />
        <AssignmentsNavSection withAdmin />
        <SpecsNavSection specTypes={mainSpecs} />
        <SpecsNavSection specTypes={otherSpecs} />
      </Nav>
      <main>
        <header className="spec-header">
          <div className="icon-title">
            <FontAwesomeIcon icon={icons.manipulatives}/>
            <div>
              <h2>Rich Content Authoring</h2>
              <p>Create dynamic content for the investigation and practice of mathematical ideas</p>
            </div>
          </div>
        </header>
        <div>
          <div>
            <h2>Admin</h2>
            <p>Create dynamic content for the investigation and practice of mathematical ideas.</p>
            <p>Assign goals to editors and monitor their progress.</p>
          </div>
        </div>
      </main>
    </>
  );
};

export default HomeAdmin;
