import Select from "components/shared/select/select";

const RuleGenerator = ({ title, ruleName, ruleValue, generators, updateValue }) => {
   const handleSelect = (e) => {
      const { value } = e.target;
      updateValue(ruleName, value);
   };

   return generators.length > 0 ? (
      <tr>
         <th>{title}</th>
         <td>
            <Select value={ruleValue} onChange={handleSelect}>
               {!ruleValue && (
                  <option>-- Select --</option>
               )}
               {generators.map((g) => (
                  <option value={g._id} key={g._id}>{g.name}</option>
               ))}
            </Select>
         </td>
      </tr>
   ) : null;
};

export default RuleGenerator;
