import {useParams} from "react-router-dom";
import HomeMenu from "./home-menu";
import AssignmentsMenu from "./assignments-menu";
import SpecsMenu from "./specs-menu";
import SignOutMenu from "./sign-out-menu";
import SpecMenu from "./spec-menu";
import SpecsMenuAdmin from "./specs-menu-admin";

const EditorMenu = () => {
  const {specId} = useParams();
  return (
    <div className="icon-menu-bar">
      <HomeMenu/>
      <AssignmentsMenu/>
      {specId && <SpecMenu />}
      <SpecsMenu/>
      <SpecsMenuAdmin />
      <SignOutMenu/>
    </div>
  );
};

export default EditorMenu;
