import useUrlSearchParams from "lib/hooks/use-url-search-params";

const DEFAULT_PER_PAGE = 20;

const getPerPage = (perPageParam) => {
   const perPageNum = parseInt(perPageParam, 10);
   return isNaN(perPageNum) ? DEFAULT_PER_PAGE : Math.max(1, perPageNum);
};

const getPageNum = (pageParam, numPerPage, numItems) => {
   const pageNum = parseInt(pageParam, 10);
   return isNaN(pageNum) ? 1 : Math.min(Math.ceil(numItems / numPerPage), pageNum);
};

const usePaginator = (items = [], count) => {
   const {searchParams} = useUrlSearchParams();
   const perPage = getPerPage(searchParams.get('perPage'));
   const pageNumber = getPageNum(searchParams.get('page'), perPage, count);
   const firstIndex = (pageNumber - 1) * perPage + 1;
   const lastIndex = Math.min(count, pageNumber * perPage);
   return {
      paginatedItems: items.slice(firstIndex - 1, lastIndex),
      pagination: {
         perPage,
         pageNumber,
         firstIndex,
         lastIndex,
         numItems: count,
      },
   };
};

export default usePaginator;
