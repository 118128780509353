import { create } from 'zustand';
import { EDITOR_ROLES, ADMIN_ROLES } from '../app-constants';

const useStore = create((set) => ({
  jwt: null,
  user: null,
  role: null,
  iframe: null,
  username: null,
  setJwt: (jwt) => {
    const { user = null } = jwt ? JSON.parse(atob(jwt.split('.')[1])) : {};
    set({ jwt, user });
  },
  setIframe: (iframeEl) => set({ iframe: iframeEl }),
}));

export default useStore;

const hasRole = (user, roles) => (user?.roles ?? []).some((role) => roles.includes(role));

export const useUser = () => {
  const user = useStore((s) => s.user);
  return {
    user,
    isEditor: hasRole(user, EDITOR_ROLES),
    isAdmin: hasRole(user, ADMIN_ROLES),
  };
};

export const useCanEdit = (spec) => {
  const {isAdmin, user} = useUser();
  const isDraft = spec._status === 'draft';
  const isOwner = spec._createdBy.userId === user._id;
  const canEdit = isDraft && (isAdmin || isOwner);
  return {
    canEdit,
    isLocked: !canEdit,
  };
};
