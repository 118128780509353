import Decimal from "./decimals/decimal";
import FractionSimple from "./fractions/fraction-simple";
import FractionSimpleLatex from "./fractions/fraction-simple-latex";
import GenericType from "./generic-type";
import Image from "./images/image";
import MathematicalText from "./mathematical-text";
import PolynomialTerm from "./polynomials/polynomial-term";
import Polynomial from "./polynomials/polynomial";
import StyledText from "./text/styled-text";

const typeRenderers = {
   Decimal,
   FractionSimple,
   FractionSimpleLatex,
   GenericType,
   Image,
   MathematicalText,
   Polynomial,
   PolynomialTerm,
   StyledText,
};

export default typeRenderers;
