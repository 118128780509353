import {getObjectProperty, setObjectProperty} from "@lasalle/rich-content-utils/objects/object-properties";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faObjectGroup} from "@fortawesome/free-solid-svg-icons";
import {Button} from "../../shared/buttons/buttons";
import {useSnapshotDispatch} from "../../snapshots/snapshots";

const getBiggest = (valueLeft) => (v) => valueLeft > 0 ? v.value <= valueLeft : v.value >= valueLeft;

const Merge = ({ itemsField, itemValuesField, filterField, prefix = '', _snapshotId, ...state }) => {
   const dispatch = useSnapshotDispatch();
   const items = getObjectProperty(state, itemsField) ?? [];
   const selected = items.filter(
      (s) => getObjectProperty(s, filterField) ?? getObjectProperty(s, filterField.substring(prefix.length + 1))
   );
   const itemValues = getObjectProperty(state, itemValuesField) ?? [];

   const values = itemValues
      .map((v) => ({ ...v, value: parseInt(v.value, 10)}))
      .sort((a, b) => b.value - a.value);

   const handleMerge = () => {
      const selectedValueSum = selected.reduce((sum, item) => {
         const itemType = values.find((v) => v.name === item.type);
         sum += itemType?.value ?? 0;
         return sum;
      }, 0);

      const mergedItems = [];
      let valueLeft = selectedValueSum;
      let itemsToUpdate = [...items];
      values.sort((a, b) => valueLeft > 0 ? b.value - a.value : a.value - b.value);

      while (valueLeft !== 0) {
         const findBiggest = getBiggest(valueLeft);
         const biggest = values.find(findBiggest);
         const propertyToGet = prefix ? `${prefix}.${biggest.name}` : biggest.name;
         const mergedShape = getObjectProperty(state, propertyToGet);
         mergedItems.push({
            ...mergedShape,
            x: Math.min(...selected.map((v) => v.x)) + (mergedItems.length % 10) * 18,
            y: Math.min(...selected.map((v) => v.y)) + mergedItems.length * 3,
            group: selected[0].group,
         });
         valueLeft -= biggest.value;
      }

      const itemsLeft = itemsToUpdate.filter((s) => {
         return !(getObjectProperty(s, filterField)
            ?? getObjectProperty(s, filterField.substring(prefix.length + 1)));
      });
      dispatch('update', _snapshotId, setObjectProperty(state, itemsField, itemsLeft.concat(mergedItems)), true);
   };

   return (
      <div className="exchange-merge">
         <Button onClick={handleMerge}>
            <FontAwesomeIcon icon={faObjectGroup} />&nbsp;Merge
         </Button>
      </div>
   );
};

export default Merge;
