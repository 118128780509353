import {lazy} from "react";

const AutographInput = lazy(() => import('@lasalle/rich-content-views/dist/inputs/autograph-input/autograph-input'));
const CalendarOutput = lazy(() => import('@lasalle/rich-content-views/dist/outputs/calendar-output/calendar-output'));
const CanvasAnalogClockOutput = lazy(() => import('@lasalle/rich-content-views/dist/outputs/canvas-analog-clock-output/canvas-analog-clock-output'));
const CanvasClockOutput = lazy(() => import('@lasalle/rich-content-views/dist/outputs/canvas-clock-output/canvas-clock-output'));
const CanvasDigitalClockOutput = lazy(() => import('@lasalle/rich-content-views/dist/outputs/canvas-digital-clock-output/canvas-digital-clock-output'));
const CanvasOutput = lazy(() => import('@lasalle/rich-content-views/dist/outputs/canvas-output/canvas-output'));
const CubeElevationsInput = lazy(() => import('@lasalle/rich-content-views/dist/inputs/cube-elevations-input/cube-elevations-input'));
const CubesOutput = lazy(() => import('@lasalle/rich-content-views/dist/outputs/cubes-output/cubes-output'));
const ExpressionTextInput = lazy(() => import('@lasalle/rich-content-views/dist/inputs/expression-text-input/expression-text-input'));
const FractionInput = lazy(() => import('@lasalle/rich-content-views/dist/inputs/fraction-input/fraction-input'));
const ImageOutput = lazy(() => import('@lasalle/rich-content-views/dist/outputs/image-output/image-output'));
const ImageRowOutput = lazy(() => import('@lasalle/rich-content-views/dist/outputs/image-row-output/image-row-output'));
const MultiChoiceInput = lazy(() => import('@lasalle/rich-content-views/dist/inputs/multi-choice-input/multi-choice-input'));
const NumberTrackInput = lazy(() => import('@lasalle/rich-content-views/dist/inputs/number-track-input/number-track-input'));
const SliderInput = lazy(() => import('@lasalle/rich-content-views/dist/inputs/slider-input/slider-input'));
const SquaresOnGridInput = lazy(() => import('@lasalle/rich-content-views/dist/inputs/squares-on-grid-input/squares-on-grid-input'));
const SquaresOnGridOutput = lazy(() => import('@lasalle/rich-content-views/dist/outputs/squares-on-grid-output/squares-on-grid-output'));
const TenFramesInput = lazy(() => import('@lasalle/rich-content-views/dist/inputs/ten-frames-input/ten-frames-input'));
const TextBoxInput = lazy(() => import('@lasalle/rich-content-views/dist/inputs/text-box-input/text-box-input'));

const viewComponents = {
  Autograph: { types: ['input', 'output'], component: AutographInput },
  CalendarOutput: { types: ['output'], component: CalendarOutput },
  CanvasAnalogClockOutput: { types: ['output'], component: CanvasAnalogClockOutput },
  CanvasClockOutput: { types: ['output'], component: CanvasClockOutput },
  CanvasDigitalClockOutput: { types: ['output'], component: CanvasDigitalClockOutput },
  CanvasOutput: { types: ['output'], component: CanvasOutput },
  CubeElevationsInput: { types: ['input'], component: CubeElevationsInput },
  CubesOutput: { types: ['output'], component: CubesOutput },
  ExpressionTextInput: { types: ['input'], component: ExpressionTextInput },
  FractionInput: { types: ['input'], component: FractionInput },
  ImageOutput: { types: ['output'], component: ImageOutput },
  ImageRowOutput: { types: ['output'], component: ImageRowOutput },
  MultiChoiceInput: { types: ['input'], component: MultiChoiceInput },
  NumberTrackInput: { types: ['input'], component: NumberTrackInput },
  SliderInput: { types: ['input'], component: SliderInput },
  SquaresOnGridInput: { types: ['input'], component: SquaresOnGridInput },
  SquaresOnGridOutput: { types: ['output'], component: SquaresOnGridOutput },
  TenFramesInput: { types: ['input'], component: TenFramesInput },
  TextBoxInput: { types: ['input'], component: TextBoxInput },
};

export default viewComponents;
