import {useUpdateSpec, doFetch} from "./index";
import {useMutation, useQueryClient, useSuspenseQuery} from "@tanstack/react-query";

const questionTypesBase = new URL(
  'question-types',
  process.env.REACT_APP_MANIPULATIVES_AWS_APIGATEWAY_URL
).href;

export const useUpdateQuestionType = (specId) => {
   const api = useUpdateSpec('question-types', specId);

   const addStep = (stepType) => {
      const spec = api.getSpec();
      const stepKey = `${stepType}Steps`;
      const steps = [...(spec[stepKey] ?? [])].concat({id: crypto.randomUUID(), items: []});
      return api.patch({[stepKey]: steps});
   };

   const removeStep = (stepType, stepId) => {
      const spec = api.getSpec();
      const stepKey = `${stepType}Steps`;
      const steps = [...(spec[stepKey] ?? [])].filter((s) => s.id !== stepId);
      return api.patch({[stepKey]: steps});
   };

   const updateStep = (stepType, step) => {
      const spec = api.getSpec();
      const stepKey = `${stepType}Steps`;
      const steps = [...(spec[stepKey] ?? [])];
      const stepIndex = steps.findIndex((s) => s.id === step.id);
      if (stepIndex === -1) return;
      return api.patch({[stepKey]: steps.toSpliced(stepIndex, 1, step)});
   };

   const moveStep = (stepType, stepId, toIndex) => {
      const spec = api.getSpec();
      const stepKey = `${stepType}Steps`;
      const steps = [...(spec[stepKey] ?? [])];
      const stepIndex = steps.findIndex((s) => s.id === stepId);
      if (stepIndex === -1) return;
      const [step] = steps.splice(stepIndex, 1);
      return api.patch({[stepKey]: steps.toSpliced(toIndex, 0, step)});
   };

   return {
      setNameAndDescription: (name, description) => api.patch({name, description}),
      setGeneratorId: api.setField('generatorId'),
      addField: api.addObjectProperty('fields'),
      removeField: api.removeObjectProperty('fields'),
      addStep,
      removeStep,
      updateStep,
      moveStep,
   };
};

const getQuestionType = (specId) => () => {
   return doFetch(`${questionTypesBase}/${specId}`);
};

export const useQuestionType = (specId) => {
   return useSuspenseQuery({
      queryKey: ['question-types', specId],
      queryFn: getQuestionType(specId),
   }).data;
};

const addQuestionType = () => (config) => {
   const { name, description, goalId, withGenerator, withContent } = config;
   const queryStrings = [];
   if (goalId) queryStrings.push(`goalId=${goalId}`);
   if (withGenerator) {
      queryStrings.push('withGenerator=1');
      if (withContent) queryStrings.push('withContent=1');
   }
   const queryString = queryStrings.length ? `?${queryStrings.join('&')}` : '';

   return doFetch(
     `${questionTypesBase}${queryString}`,
     'POST',
     { name, description },
   );
}


export const useAddQuestionType = () => {
   const queryClient = useQueryClient();
   const { mutateAsync: add } = useMutation({
      mutationFn: addQuestionType(),
      onSettled: () => {
         queryClient.invalidateQueries(['question-types']);
      }
   });
   return (name, description, goalId, withGenerator, withContent) =>
     add({ name, description, goalId, withGenerator, withContent });
};
