import {Route, Routes, useParams} from "react-router-dom";
import {useSpec, useSpecDepCounts} from "api";
import {useUser} from "store";
import Overview from "./sections/overview";
import NameAndDescription from "./sections/name-and-description";
import StatusInfo from "./sections/status-info";
import CurriculumInfo from "./sections/curriculum-info";
import TagsInfo from "./sections/tags-info";
import UsedByInfo from "./sections/used-by-info";
import JsonViewer from "./sections/json-viewer";
import CopySpec from "./actions/copy-spec";
import CreateQuestionSet from "./actions/create-question-set";
import CreateQuestionType from "./actions/create-question-type";
import DeleteSpec from "./actions/delete-spec";

const SpecInfoRoutes = () => {
  const {specType, specId} = useParams();
  const {user, isAdmin} = useUser();

  const spec = useSpec(specType, specId) ?? {};
  const {count: numDeps} = useSpecDepCounts(specType, specId) ?? {};
  const {_status, _createdBy, tags} = spec;

  const isDraft = _status === 'draft';
  const isOwner = _createdBy.userId === user._id;
  const canEdit = (isAdmin || isOwner) && isDraft;
  const canDelete = canEdit && numDeps === 0;

  return (
    <Routes>
      <Route path="/" element={<Overview specType={specType} spec={spec} />} />
      <Route path="/name-and-description"  element={<NameAndDescription spec={spec} canEdit={canEdit} />} />
      <Route path="/status" element={<StatusInfo spec={spec} isAdmin={isAdmin} isOwner={isOwner} numDeps={numDeps} />} />
      <Route path="/curriculum" element={<CurriculumInfo canEdit={canEdit} curriculumLinks={spec.curriculumLinks} />} />
      <Route path="/tags" element={<TagsInfo tags={tags} canEdit={canEdit} />} />
      <Route path="/used-by" element={<UsedByInfo specId={specId} specType={specType} numDeps={numDeps} />} />
      <Route path="/json" element={<JsonViewer spec={spec} />} />

      <Route path="/copy" element={<CopySpec spec={spec} />} />
      <Route path="/create-question-set" element={<CreateQuestionSet spec={spec} />} />
      <Route path="/create-question-type" element={<CreateQuestionType spec={spec} />} />
      <Route path="/delete" element={<DeleteSpec spec={spec} isAdmin={isAdmin} isOwner={isOwner} numDeps={numDeps} canDelete={canDelete} />} />
    </Routes>
  );
};

export default SpecInfoRoutes;
