import ViewPicker from "./view-picker";
import ViewPropsEditor from "./view-props-editor";

const OutputEditor = ({item, questionType, setViewId, updateItem}) => {
   return (
     <>
        <ViewPicker viewId={item.viewId} viewType={item.type} setViewId={setViewId}/>
        <ViewPropsEditor
           item={item}
           generatorId={questionType.generatorId}
           updateItem={updateItem}
        />
     </>
   );
};

export default OutputEditor;
