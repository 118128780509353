import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPersonWalkingArrowRight} from "@fortawesome/free-solid-svg-icons";
import {Button} from "../buttons/buttons";
import "./sign-out-button.css";
import {useLogOut} from "../../auth-iframe";

const SignOutButton = () => {
  const logOut = useLogOut();
  return (
    <Button className="sign-out-button" title="Sign Out" onClick={logOut}>
      <FontAwesomeIcon icon={faPersonWalkingArrowRight} />
    </Button>
  );
};

export default SignOutButton;
