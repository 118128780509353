import {useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useUpdateSpec} from "api";
import {AddButton, DeleteButton} from "components/shared/buttons/buttons";
import TextBox from "components/shared/text-box/text-box";

const TagsInfo = ({ tags, canEdit }) => {
   const {specType, specId} = useParams();
   const {addTag, removeTag} = useUpdateSpec(specType, specId);
   const [newTag, setNewTag] = useState('');
   const ref = useRef();

   const handleAdd = () => {
      const trimmed = newTag.trim();
      if (!tags.includes(trimmed)) addTag(trimmed);
      setNewTag('');
      ref.current.focus();
   };

   return (
      <div className="spec-tags">
         <h3>Tags</h3>
         <ul>
            {Array.isArray(tags) && tags.map((tag) => (
               <li key={tag}>
                  {tag}{' '}
                  {canEdit && <DeleteButton onClick={() => removeTag(tag)} withIcon/>}
               </li>
            ))}
         </ul>
         {canEdit && (
            <>
               <p>Use tags to group specs and to make them easier to find in searches.</p>
               <p>
                  <TextBox
                     value={newTag}
                     onChange={(e) => setNewTag(e.target.value)}
                     ref={ref}
                  />{' '}
                  <AddButton
                     onClick={handleAdd}
                     withIcon disabled={newTag.trim() === ''}
                  >
                     Add New Tag
                  </AddButton>
               </p>
            </>
         )}
      </div>
   );
};

export default TagsInfo;
