import {useEffect, useState} from "react";
import TextArea from "components/shared/text-area/text-area";

const DEBOUNCE_DELAY = 600;

const TextEditor = ({item, setText, setIsKeyItem}) => {
   const [localText, setLocalText] = useState(item.text ?? '');

   useEffect(() => {
      let timeoutId;
      if (localText !== item.text) {
         timeoutId = setTimeout(() => setText(localText), DEBOUNCE_DELAY);
      }
      return () => clearTimeout(timeoutId);
   }, [localText, item.text, setText]);

   return (
      <>
          <TextArea
             cols={60}
             rows={5}
             value={localText}
             onChange={(e) => setLocalText(e.target.value)}
          />
          <p>
             <input
                type="checkbox"
                id="chkIsKeyItem"
                checked={item.isKeyItem}
                onChange={() => setIsKeyItem(!item.isKeyItem)}
             />{' '}
             <label htmlFor="chkIsKeyItem">Is Key Item</label>
          </p>
      </>
   );
};

export default TextEditor;
