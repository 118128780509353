import {Button} from "components/shared/buttons/buttons";

const isMode = (mode, modes) => modes.includes(mode);

const FreePracticeButtons = ({mode, handleShowAnswers, handleCheckAnswer, handleRefresh, handleTryAgain}) => {
  const feedbackMessage = {
    correct: 'Correct! Well done',
    incorrect: 'Incorrect! Try again or reveal the answer',
    revealed: 'The correct answer is now shown above.',
    revealed_checked: 'The correct answer is now shown above.',
  }[mode];

  const feedbackClasses = ['question-feedback'];
  if (mode === 'correct') {
    feedbackClasses.push('correct');
  } else if (mode === 'incorrect') {
    feedbackClasses.push('incorrect');
  }

  return (
    <div className="tutor-free-practice-controls">
      <p className={feedbackClasses.join(' ')}>{feedbackMessage ?? <span>&nbsp;</span>}</p>
      {isMode(mode, ['incomplete', 'complete']) && (
        <div className="tutor-free-practice-buttons">
          <Button className="outlined" onClick={handleShowAnswers}>
            Reveal Answer
          </Button>
          <Button
            className="contained"
            onClick={handleCheckAnswer}
            disabled={mode === 'incomplete'}
          >
            Check Answer
          </Button>
        </div>
      )}
      {isMode(mode, ['incorrect']) && (
        <div className="tutor-free-practice-buttons">
          <Button className="outlined" onClick={handleShowAnswers}>
            Reveal Answer
          </Button>
          <Button className="contained" onClick={handleTryAgain}>
            Try Again
          </Button>
        </div>
      )}
      {isMode(mode, ['revealed', 'revealed_checked', 'correct']) && (
        <div className="tutor-free-practice-buttons">
          <Button className="contained" onClick={handleRefresh}>
            Next Question
          </Button>
        </div>
      )}
    </div>
  );
};

export default FreePracticeButtons;
