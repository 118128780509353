import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faMessage, faRightFromBracket, faRightToBracket, faSliders} from "@fortawesome/free-solid-svg-icons";
import {DeleteButton} from "components/shared/buttons/buttons";

const icons = {
  Controls: faSliders,
  Input: faRightToBracket,
  Output: faRightFromBracket,
  Text: faMessage,
};

const ItemHeading = ({ itemType, stepIndex, itemIndex, isLocked, deleteItem }) => {
  const sIndex = parseInt(stepIndex, 10);
  const iIndex = parseInt(itemIndex, 10);
  return (
    <h3 className="value-details-heading">
       <span>
          <span className="step-number">Step {sIndex + 1}</span>
          <span className="item-number">Item {iIndex + 1}</span>
          <span className="item-type">
             <FontAwesomeIcon icon={icons[itemType]}/>
            {itemType} Editor
          </span>
       </span>
      {isLocked ? (
        <FontAwesomeIcon icon={faLock} />
      ) : (
        <span className="value-buttons">
          <DeleteButton withIcon onClick={deleteItem}> Delete Item</DeleteButton>
       </span>
      )}
    </h3>
  );
};

export default ItemHeading;
