import {useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faTrash, faChartSimple, faFolder, faLock} from "@fortawesome/free-solid-svg-icons";
import {useUpdateGenerator} from "api/generators";
import {useSpec, useSpecDeps, useSpecs} from "api";
import {typeIds} from "app-constants/specs";
import {useCanEdit} from "store";
import NameAndType from "./name-and-type";
import ValueDetailsRules from "./value-details-rules";
import "./value-details.css";

const getValueDetails = (id, values) => {
   const [parentId, childId] = id?.split('.') ?? [];
   for (const value of values) {
      if (value.id === parentId && !childId) return { valueDetailsId: id, valueDetails: value };
      if (typeof value.type !== 'string' && childId) {
         const details = value.type.fields.find((f) => f.id === childId);
         if (details) return { valueDetailsId: id, valueDetails: { ...details, rules: value.rules } };
      }
   }
   return undefined;
};

const getParentIds = (allValues, childId, soFar = []) => {
   const parentId = allValues.find((v) => v.id === childId)?.parentId;
   return parentId ? getParentIds(allValues, parentId, [parentId, ...soFar]) : soFar;
};

const getPath = (allValues, valueId, valueName) => {
   return getParentIds(allValues, valueId)
      .map((pId) => allValues.find((v) => v.id === pId).name)
      .concat(valueName)
      .join('.');
};

const ValueDetails = (props) => {
   const navigate = useNavigate();
   const {specType, specId, valueId} = useParams();
   const generatorId = props.generatorId ?? specId;
   const spec = useSpec('generators', generatorId);
   const {specs: specDeps} = useSpecDeps('generators', generatorId);
   const {copyValue, removeValue, updateValue, setRule, addRuleLevel, removeRuleLevel, updateValueName, updateValueType} = useUpdateGenerator(generatorId);
   const {data: valueTypes} = useSpecs('value-types') ?? {data: []};
   const {valueDetails, valueDetailsId} = getValueDetails(valueId, spec.values) ?? {valueDetails: {}};
   const {name: valueName, type, rules, list = '', exclusions} = valueDetails;
   const {canEdit} = useCanEdit(spec);
   const isLocked = !(canEdit && (specDeps['question-types'] ?? []).every((v) => v._status === 'draft'));

   const groupNames = new Set();
   for (const value of (spec.values ?? [])) {
      if (value.groupName) groupNames.add(value.groupName);
   }

   const typesInUse = [
     ...new Set(
       spec.values.map((v) => v.type).filter((v) => v !== typeIds.FOLDER_TYPE_ID)
     )
   ];

   const handleCopyValue = async () => {
      const copy = await copyValue(valueDetails);
      navigate(`/specs/${specType}/${specId}/value/${copy.id}`);
   };

   const handleRemoveValue = () => {
      if (window.confirm('Are you sure you want to delete the value?')) {
         removeValue(valueId);
         navigate(`/specs/${specType}/${specId}`, {replace: true});
      }
   };

   const handleUpdateRule = (ruleName, ruleValue, level) => {
      setRule(valueDetailsId, ruleName, ruleValue, level);
   };

   const handleAddRuleLevel = () => {
      addRuleLevel(valueId);
   };

   const handleRemoveRuleLevel = (level) => {
      removeRuleLevel(valueId, level);
   };

   const handleUpdateList = (listPatch) => {
      updateValue(valueId, {list: listPatch ? {...list, ...listPatch} : ''});
   };

   const handleUpdateValueName = (name) => {
      updateValueName(valueId, name);
   };

   const handleSetValueType = (typeId) => {
      updateValueType(valueId, typeId);
   };

   const handleSetExclusions = (newExclusions) => {
      updateValue(valueId, {exclusions: newExclusions});
   };

   const valueIcon = type === typeIds.FOLDER_TYPE_ID ? faFolder : faChartSimple;
   const valueNameBoxClasses = ['value-name-box'].concat(
     type === typeIds.FOLDER_TYPE_ID ? 'type-folder' : 'type-value'
   );

   return valueName && valueTypes.length > 0 ? (
      <div className="value-details">
         <h3 className="value-details-heading">
            <span className={valueNameBoxClasses.join(' ')}>
               <FontAwesomeIcon icon={valueIcon} />
               <span>
                  {getPath(spec.values, valueId, valueName)}
               </span>
            </span>
            {isLocked ? (
              <span>
                 <FontAwesomeIcon icon={faLock} />
              </span>
            ) : (
              <span className="value-buttons">
                  <button onClick={handleCopyValue} className="btn btn-add">
                     <FontAwesomeIcon icon={faCopy}/> Copy
                  </button>
                    {' '}
                    <button onClick={handleRemoveValue} className="btn btn-delete">
                     <FontAwesomeIcon icon={faTrash}/> Delete
                  </button>
               </span>
            )}
         </h3>
         <NameAndType
           valueName={valueName}
           valueTypes={valueTypes}
           type={type}
           list={list}
           typesInUse={typesInUse}
           isLocked={isLocked}
           key={valueId}
           handleUpdateValueName={handleUpdateValueName}
           handleUpdateList={handleUpdateList}
           handleSetValueType={handleSetValueType}
         />
         {type !== typeIds.FOLDER_TYPE_ID && (
            <ValueDetailsRules
               removeRuleLevel={handleRemoveRuleLevel}
               handleAddRuleLevel={handleAddRuleLevel}
               handleUpdateRule={handleUpdateRule}
               handleUpdateList={handleUpdateList}
               handleSetExclusions={handleSetExclusions}
               valueTypes={valueTypes}
               valueId={valueId}
               type={type}
               rules={rules}
               list={list}
               exclusions={exclusions}
               isLocked={isLocked}
            />
         )}
      </div>
   ) : null;
};

export default ValueDetails;
