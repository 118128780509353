import {NavLink} from "react-router-dom";
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import icons from "app-constants/icons";
import {Button} from 'components/shared/buttons/buttons';

const SortableNavItem = ({ value, to, isLocked, ...props }) => {
   const { id, depth = 0, name, isSelected } = value;
   const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      isDragging,
      over,
      active,
   } = useSortable({
      id,
      data: value,
      disabled: isLocked,
   });

   const style = {
      transform: CSS.Transform.toString({
         x: 0,
         y: transform?.y,
         scaleX: 1,
         scaleY: 1
      }),
   };
   const depthStyle = { '--folder-depth': depth };

   if (active && over && isDragging) {
      depthStyle['--folder-depth'] = active.newDepth ?? active.data.current.depth;
   }

   const classNames = ['value-item', 'sortable-nav-item'];
   if (isSelected) classNames.push('selected');

   return (
      <li
        ref={setNodeRef}
        style={style}
        className={classNames.join(' ')}
        {...props}
      >
         <div
            className="sortable-nav-item-wrapper"
            style={depthStyle}
         >
            <span>
               <Button
                 title="Drag to Move Value"
                 {...attributes}
                 {...listeners}
               >
                  <FontAwesomeIcon icon={icons["generator-value"]} />
               </Button>
               <NavLink to={to} replace>{name}</NavLink>
            </span>
         </div>
      </li>
   );
};

export default SortableNavItem;
