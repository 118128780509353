import ViewPicker from "./view-picker";
import ViewPropsEditor from "./view-props-editor";
import AnswerValueEditor from "./answer-value-editor/answer-value-editor";

const InputEditor = ({item, questionType, setViewId, setViewPropValue, updateItem }) => {
   return (
      <form>
         <ViewPicker
           viewId={item.viewId}
           viewType={item.type}
           setViewId={setViewId}
           title="Input Type"
         />
         <ViewPropsEditor
            item={item}
            generatorId={questionType.generatorId}
            setViewPropValue={setViewPropValue}
            updateItem={updateItem}
         />
         <AnswerValueEditor
            item={item}
            updateItem={updateItem}
            generatorId={questionType.generatorId}
         />
      </form>
   );
};

export default InputEditor;
