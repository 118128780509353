import { useContext, createContext } from 'react';
import useSnapshots from './use-snapshots';

const SnapshotInfoContext = createContext();
const SnapshotDispatchContext = createContext();
const SnapshotStateContext = createContext();

const Snapshots = ({ spec, presets = {}, transforms = {}, valueTypeSpecs, genFunctionSpecs, generatorConfigs, children, ...props }) => {
  const { state, snapshotInfo, dispatch } = useSnapshots(spec, presets, transforms, valueTypeSpecs, genFunctionSpecs, generatorConfigs);
  const value = { ...state, ...props };

  const setState = (v) => {
      dispatch('update', snapshotInfo.snapshotId, v instanceof Function ? v(state) : v);
  };

  return (
    <SnapshotDispatchContext.Provider value={dispatch}>
        <SnapshotInfoContext.Provider value={snapshotInfo}>
            <SnapshotStateContext.Provider value={[value, setState]}>
                {children}
            </SnapshotStateContext.Provider>
        </SnapshotInfoContext.Provider>
    </SnapshotDispatchContext.Provider>
  );
};

export default Snapshots;

export const useSnapshotDispatch = () => {
  return useContext(SnapshotDispatchContext);
};

export const useSnapshotInfo = () => {
  return useContext(SnapshotInfoContext);
};

export const useSnapshotState = () => {
    return useContext(SnapshotStateContext);
};
