import {useState} from "react";
import {useParams} from "react-router-dom";
import {useUpdateSpec} from "api";
import {Button} from "components/shared/buttons/buttons";
import TextBox from "components/shared/text-box/text-box";
import TextArea from "components/shared/text-area/text-area";

const NameAndDescription = ({ spec, canEdit }) => {
   const {specType, specId} = useParams();
   const {patch} = useUpdateSpec(specType, specId);
   const {name, description} = spec;
   const [isEditing, setIsEditing] = useState(false);
   const [localName, setLocalName] = useState(name);
   const [localDesc, setLocalDesc] = useState(description);

   const handleChange = () => {
      const updatedName = localName.trim().replace(/\s+/g, ' ');
      if (updatedName !== name || localDesc !== description) {
         patch({
            name: updatedName,
            description: localDesc,
         });
      }
      setIsEditing(false);
   };

   return isEditing ? (
      <div className="info-section">
         <h3>Name</h3>
         <p>
            <TextBox
               value={localName}
               variant="longer"
               onChange={(e) => setLocalName(e.target.value)}
            />
         </p>
         <h3>Description</h3>
         <p>
            <TextArea
               rows={6}
               onChange={(e) => setLocalDesc(e.target.value)}
            >
               {localDesc}
            </TextArea>
         </p>
         <p className="controls">
            <Button onClick={() => setIsEditing(false)}>Cancel</Button>{' '}
            <Button onClick={handleChange}>Save Changes</Button>
         </p>
      </div>
   ) : (
      <div className="info-section">
         <h3>Name</h3>
         <p>{name}</p>
         {description && (
            <>
               <h3>Description</h3>
               <pre>{description}</pre>
            </>
         )}
         {canEdit && (
            <p className="controls">
               <Button onClick={() => setIsEditing(true)}>Edit</Button>
            </p>
         )}
      </div>
   );
};

export default NameAndDescription;
