import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faSpinner} from "@fortawesome/free-solid-svg-icons";
import useUrlSearchParams from "lib/hooks/use-url-search-params";
import Select from "components/shared/select/select";
import {Button} from "components/shared/buttons/buttons";

const Paginator = ({perPage, pageNumber, firstIndex, lastIndex, numItems, isLoadingData, startLoadingData}) => {
   const {setSearchParams} = useUrlSearchParams();

   const setParams = (newPage, newPerPage = perPage) => {
      startLoadingData(() => {
         setSearchParams({
            page: newPage,
            perPage: newPerPage,
         });
      });
   };

   const handleSetPerPage = (e) => {
      const { value } = e.target;
      const newPerPage = parseInt(value, 10);
      setParams(Math.ceil(firstIndex / newPerPage), newPerPage);
   };

   const handleFirst = () => {
      setParams(1);
   };

   const handlePrev = () => {
      setParams(Math.max(1, pageNumber - 1));
   };

   const handleNext = () => {
      setParams(Math.min(Math.ceil(numItems / perPage), pageNumber + 1));
   };

   const handleLast = () => {
      setParams(Math.ceil(numItems / perPage));
   };

   return (
      <div className="pagination-controls">
         <span>
            {firstIndex} to {lastIndex} of {numItems}{' '}
            {isLoadingData && <FontAwesomeIcon icon={faSpinner} className="is-previous-data" />}
         </span>
         <span>
            <Select value={perPage} onChange={handleSetPerPage}>
               <option value="5">5</option>
               <option value="10">10</option>
               <option value="20">20</option>
               <option value="50">50</option>
               <option value="100">100</option>
               <option value="10000">All</option>
            </Select>
            <Button onClick={handleFirst} disabled={pageNumber === 0}>
               <FontAwesomeIcon icon={faAnglesLeft} />&nbsp;
               First
            </Button>
            <Button onClick={handlePrev} disabled={pageNumber === 0}>
               <FontAwesomeIcon icon={faAngleLeft} />&nbsp;
               Prev
            </Button>
            <Button onClick={handleNext} disabled={lastIndex === numItems}>
               Next&nbsp;
               <FontAwesomeIcon icon={faAngleRight} />
            </Button>
            <Button onClick={handleLast} disabled={lastIndex === numItems}>
               Last&nbsp;
               <FontAwesomeIcon icon={faAnglesRight} />
            </Button>
         </span>
      </div>
   );
};

export default Paginator;
