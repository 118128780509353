import {Suspense} from "react";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import icons from "app-constants/icons";
import {useUser} from "store";

const SpecsMenuInner = () => {
  const {isAdmin} = useUser();
  return isAdmin ? (
    <ul className="icon-menu">
      <li>
        <NavLink to="/specs/controls" end title="Controls Specs List">
          <FontAwesomeIcon icon={icons.controls}/>
        </NavLink>
      </li>
      <li>
        <NavLink to="/specs/generators" end title="Generators Specs List">
          <FontAwesomeIcon icon={icons.generators}/>
        </NavLink>
      </li>
      <li>
        <NavLink to="/specs/manipulatives" end title="Manipulatives Specs List">
          <FontAwesomeIcon icon={icons.manipulatives}/>
        </NavLink>
      </li>
      <li>
        <NavLink to="/specs/question-sets" end title="Question Sets Specs List">
          <FontAwesomeIcon icon={icons['question-sets']}/>
        </NavLink>
      </li>
      <li>
        <NavLink to="/specs/type-renderers" end title="Type Renderers Specs List">
          <FontAwesomeIcon icon={icons['type-renderers']}/>
        </NavLink>
      </li>
      <li>
        <NavLink to="/specs/value-functions" end title="Value Functions Specs List">
          <FontAwesomeIcon icon={icons['value-functions']}/>
        </NavLink>
      </li>
      <li>
        <NavLink to="/specs/value-types" end title="Value Types Specs List">
          <FontAwesomeIcon icon={icons['value-types']}/>
        </NavLink>
      </li>
      <li>
        <NavLink to="/specs/views" end title="Views Specs List">
          <FontAwesomeIcon icon={icons.views}/>
        </NavLink>
      </li>
    </ul>
  ) : null;
}

const SpecsMenuAdmin = () => (
  <Suspense fallback={null}>
    <SpecsMenuInner/>
  </Suspense>
);

export default SpecsMenuAdmin;
