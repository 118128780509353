import {useRef, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartSimple, faFolderClosed} from '@fortawesome/free-solid-svg-icons';
import {DndContext} from '@dnd-kit/core';
import {
   SortableContext,
   verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {useSpec, useSpecDeps} from "api";
import {useUpdateGenerator} from "api/generators";
import {useCanEdit} from "store";
import {typeIds} from "app-constants/specs";
import {Button} from 'components/shared/buttons/buttons';
import NavSection from "components/nav/nav-section";
import SortableNavItem from "./sortable-nav-item";
import SortableFolderItem from "./sortable-folder-item";
import getNextName from "../../../../../lib/utils/names";

const {FOLDER_TYPE_ID, DEFAULT_VALUE_TYPE_ID} = typeIds;
const DEPTH_SNAP = 20;

const getParentIds = (allValues, childId, soFar = []) => {
   const parentId = allValues.find((v) => v.id === childId)?.parentId;
   return parentId ? getParentIds(allValues, parentId, [parentId, ...soFar]) : soFar;
};

const getActiveDepth = (dragState) => {
   const { active, over, delta } = dragState;

   if (active && over) {
      const activeItem = active.data.current;
      const overItem = over.data.current;
      const anchorItem = activeItem.index >= overItem.index ? overItem.previousValue ?? activeItem : overItem;
      const depthOffset = Math.floor(delta.x / DEPTH_SNAP);

      const anchorDepth = (anchorItem?.depth ?? 0) + (anchorItem?.type === 'folder' && anchorItem.isOpen ? 1 : 0);
      const minDepth = overItem.index < activeItem.index ? overItem.depth : overItem.nextValue?.depth ?? 0;
      return !overItem.index ? 0 : Math.max(minDepth, Math.min(anchorDepth, activeItem.depth + depthOffset));
   }
   return active.data.current.depth;
}

const ValuesList = (props) => {
   const {specType, specId, valueId} = useParams();
   const [searchParams] = useSearchParams();
   const generatorId = props.generatorId ?? specId;
   const generator = useSpec('generators', generatorId);
   const {specs: specDeps} = useSpecDeps('generators', generatorId);
   const {addValue, moveValue, addNestedValue} = useUpdateGenerator(generatorId);
   const isDraggingRef = useRef(false);
   const [openFolders, setOpenFolders] = useState(new Set());
   const [activeFolder, setActiveFolder] = useState();
   const {canEdit} = useCanEdit(generator);
   const isLocked = !(canEdit && (specDeps['question-types'] ?? []).every((v) => v._status === 'draft'));
   const selectedValueId = valueId;

   const navigate = useNavigate();

   const handleAddItem = async (name, TYPE_ID) => {
      const resp = await addValue(getNextName(name, generator.values), TYPE_ID);
      navigate(`/specs/${specType}/${specId}/value/${resp.id}`);
   };

   const handleAddValue = () => {
      handleAddItem('Value_0', DEFAULT_VALUE_TYPE_ID);
   };

   const handleAddFolder = async () => {
      handleAddItem('Folder_0', FOLDER_TYPE_ID);
   };

   const toggleFolder = (id, shouldShow) => {
      if (shouldShow !== undefined) openFolders[shouldShow ? 'add' : 'delete'](id);
      else if (openFolders.has(id)) openFolders.delete(id)
      else openFolders.add(id);
      setOpenFolders(new Set([...openFolders]))
   };

   const handleClickFolder = (id) => {
     toggleFolder(id);
   };

   const handleAddNestedItem = async (e, value, name, TYPE_ID) => {
      e.stopPropagation();
      toggleFolder(value.id, true);
      const newName = getNextName(name, generator.values);
      const resp = await addNestedValue(value.id, newName, TYPE_ID);
      navigate(`/specs/${specType}/${specId}/value/${resp.id}`);
   };

   const handleAddNestedValue = (value, e) => {
      handleAddNestedItem(e, value, 'Value_0', DEFAULT_VALUE_TYPE_ID);
   };

   const handleAddNestedFolder = (value, e) => {
      handleAddNestedItem(e, value, 'Folder_0', FOLDER_TYPE_ID);
   };

   const activeValues = generator.values
      .filter((v) => {
         const parentIds = getParentIds(generator.values, v.id);
         return parentIds.every((pId) => openFolders.has(pId));
      });

   const getCoreDetails = (value) => {
      if (!value) return;
      const parentIds = getParentIds(generator.values, value.id);
      const depth = parentIds.length;
      const lastInFolderIndex = generator.values.findLastIndex((v) => v.parentId && v.parentId === value.parentId);
      const lastInFolderValue = generator.values[lastInFolderIndex];

      const valueDetails = {
         ...value,
         depth,
         type: value.type === FOLDER_TYPE_ID ? 'folder' : 'item',
         isOpen: openFolders.has(value.id),
      };

      valueDetails.lastInFolder = !valueDetails.isOpen && value.id === lastInFolderValue?.id;

      return valueDetails;
   };

   const flatValues = activeValues.reduce((soFar, value, valueIndex) => {
      const valueDetails = {
         ...getCoreDetails(value),
         valueIndex,
         previousValue: getCoreDetails(activeValues[valueIndex - 1]),
         nextValue: getCoreDetails(activeValues[valueIndex + 1]),
         isSelected: selectedValueId === value.id,
      };

      valueDetails.previousValue = {
         ...(valueDetails.previousValue ?? {}),
         nextValue: valueDetails,
      };

      soFar.push(valueDetails);
      return soFar;
   }, []).map((v, i) => ({ ...v, index: i }));

   const handleDragStart = (update) => {
      toggleFolder(update.active.id, false);
      isDraggingRef.current = true;
      update.active.newDepth = getActiveDepth(update);
   };

   const handleDragMove = (update) => {
      const {active, over} = update;
      const overIndex = over?.data.current.index;
      let hasActiveFolder = false;
      if (overIndex !== undefined) {
         active.newDepth = getActiveDepth(update);
         const activeIndex = active.data.current.index;
         for (const v of flatValues) {
            const isActiveFolder = v.type === 'folder'
              && v.depth === active.newDepth - 1
              && v.index <= overIndex - (activeIndex > overIndex ? 1 : 0)
              && v.id !== active.id;
            if (isActiveFolder) {
               setActiveFolder(v.id);
               hasActiveFolder = true;
            }
         }
      }
      if (!hasActiveFolder) setActiveFolder(undefined);
   };

   const handleDragEnd = ({ over, active }) => {
      isDraggingRef.current = false;
      setActiveFolder(undefined);
      if (over && (over.id !== active.id || active.newDepth !== undefined)) {
         moveValue(active.id, over?.id, activeFolder);
      }
   };

   const previewParam = searchParams.get('preview');
   const searchSuffix = previewParam ? `?preview=${previewParam}` : '';

   return (
     <DndContext
       onDragStart={handleDragStart}
       onDragEnd={handleDragEnd}
       onDragMove={handleDragMove}
     >
        <SortableContext items={flatValues} strategy={verticalListSortingStrategy}>
           <div>
              <h3 className="values-list-controls">
                 <span>Values</span>
                 <span>
               <Button onClick={handleAddValue} title="New Value" disabled={isLocked}>
                  <FontAwesomeIcon icon={faChartSimple}/>
               </Button>
               <Button onClick={handleAddFolder} title="New Folder" disabled={isLocked}>
                  <FontAwesomeIcon icon={faFolderClosed}/>
               </Button>
            </span>
              </h3>
              <NavSection className="generator-values">
                 {flatValues.map((flatValue) => flatValue.type === 'folder' ? (
                   <SortableFolderItem
                     key={flatValue.id}
                     to={`/specs/${specType}/${specId}/value/${flatValue.id}${searchSuffix}`}
                     value={flatValue}
                     items={flatValues}
                     className={flatValue.id === activeFolder ? 'drop-folder' : ''}
                     isLocked={isLocked}
                     handleAddNestedValue={handleAddNestedValue}
                     handleAddNestedFolder={handleAddNestedFolder}
                     handleClickFolder={handleClickFolder}
                   />
                 ) : (
                   <SortableNavItem
                     key={flatValue.id}
                     to={`/specs/${specType}/${specId}/value/${flatValue.id}${searchSuffix}`}
                     value={flatValue}
                     items={flatValues}
                     isLocked={isLocked}
                   />
                 ))}
              </NavSection>
           </div>
        </SortableContext>
     </DndContext>
   );
};

export default ValuesList;
