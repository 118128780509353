import {Suspense} from "react";
import {Link, Route, Routes, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import icons from "app-constants/icons";
import Nav from "components/nav/nav";
import LogoBox from "components/shared/logo-box/logo-box";
import EditorMenu from "components/icon-menus/editor-menu";
import AssignmentsNavSection from "pages/assignments/assignments-nav-section";
import ListOfEditors from "./list-of-editors";
import AssignmentsForEditor from "./assignments-for-editor";
import EditorDetails from "./editor-details";
import QuestionTypesForGoal from "./question-types-for-goal";
import AssignGoal from "./assign-goal";

const AssignmentsAdminEditor = () => {
  const {editorId} = useParams();
  return (
    <>
      <EditorMenu />
      <Nav>
        <LogoBox />
        <AssignmentsNavSection />
        <Suspense fallback={null}>
          <h3>Editors</h3>
          <ListOfEditors />
        </Suspense>
      </Nav>
      <main>
        <header className="spec-header">
          <div className="icon-title">
            <FontAwesomeIcon icon={icons['assignments-admin']} />
            <div>
              <h2>Assignments Admin</h2>
              <p>Assign goals to editors</p>
            </div>
          </div>
          <Routes>
            <Route path="" element={(
              <Link to={`/assignments/admin/${editorId}/new`}>
                <FontAwesomeIcon icon={icons['new-spec']} />{' '}
                Create New Assignment
              </Link>
            )} />
          </Routes>
        </header>
        <div>
          <div className="details-panel">
            <h2>Goals assigned to editor</h2>
            {editorId !== '' && (
              <Suspense fallback={<p>Loading assignments...</p>}>
                <EditorDetails />
                <Routes>
                  <Route path="" element={<AssignmentsForEditor editorId={editorId}/>} />
                  <Route path=":goalId" element={<QuestionTypesForGoal />} />
                  <Route path="/new" element={<AssignGoal editorId={editorId} />} />
                </Routes>
              </Suspense>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default AssignmentsAdminEditor;
