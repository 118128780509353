import viewComponents from 'components/views';
import getViewPropsFromConfig from "./get-view-props-from-config";

const OutputItem = ({ item, state, specs }) => {
  const viewSpec = specs.find((spec) => spec._id === item.viewId) ?? {};
  const Component = viewComponents[viewSpec.componentCodeKey]?.component;
  const viewPropObj = getViewPropsFromConfig(viewSpec.viewProps, item, state);
  return Component ? <Component {...viewPropObj} /> : null;
};

export default OutputItem;
