import {NavLink} from "react-router-dom";

const NavItem = ({ to, style, end, children }) => {

  return (
    <li className="nav-item" style={style}>
      <NavLink to={to} end={end}>
        {children}
      </NavLink>
    </li>
  );
};

export default NavItem;
