import {mainSpecs} from "app-constants/specs";
import AssignmentsNavSection from "pages/assignments/assignments-nav-section";
import SpecsNavSection from "pages/specs/specs-nav/specs-nav-section";

const HomeNav = () => {
  return (
    <>
      <AssignmentsNavSection />
      <SpecsNavSection specTypes={mainSpecs} />
    </>
  );
};

export default HomeNav;
